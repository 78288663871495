import AppsIcon from '@mui/icons-material/Apps';
import MenuIcon from '@mui/icons-material/Menu';
import { alpha } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useRouter } from '@pn/core/operations/router';
import { isEmbedded } from '@pn/core/utils/embedded';
import { isStaging } from '@pn/core/utils/env';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { StagingIcon } from '@pn/ui/icons/StagingIcon';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { Link } from 'react-router-dom';
import { getAppView } from 'src/web-ui/appViews';
import { SearchInfoPopup } from 'src/web-ui/layout/SearchInfoPopup';
import { SearchResults } from 'src/web-ui/layout/SearchResults';
import { Searchbar } from 'src/web-ui/layout/Searchbar';
import { zIndex } from 'src/web-ui/zIndex';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{
  showLogo: boolean;
}>()((theme, { showLogo }) => ({
  appBar: {
    gridArea: 'header',
    background: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    borderBottom: `1px solid ${theme.palette.divider}`,
    zIndex: zIndex(theme).appBar,
    '@media print': {
      display: 'none',
    },
  },
  toolbar: {
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  logo: {
    display: 'block',
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: showLogo ? 'block' : 'none',
      marginRight: theme.spacing(1),
    },
  },
  title: {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.secondary.main
        : theme.palette.secondary.light,
    '@media (max-width: 424.95px)': {
      display: 'none',
    },
    '@media (min-width: 425px) and (max-width: 599.95px)': {
      display: 'none',
    },
  },
  extraTitle: {
    color: theme.palette.text.secondary,
    '@media (max-width: 424.95px)': {
      display: 'none',
    },
    '@media (min-width: 425px) and (max-width: 599.95px)': {
      display: 'none',
    },
  },
  badgeIcon: {
    position: 'relative',
    left: 6,
    top: -1,
    width: 18,
    verticalAlign: 'text-bottom',
    color: theme.palette.secondary.light,
  },
}));

type Props = {
  showSearchbar: boolean;
  isWorkspaceOpen: boolean;
  extraTitle: string | undefined;
  toggleMenuDrawer: () => void;
  setWorkspaceOpen: (open: boolean) => void;
};

export const CustomAppBar = ({
  showSearchbar,
  isWorkspaceOpen,
  extraTitle,
  toggleMenuDrawer,
  setWorkspaceOpen,
}: Props) => {
  const { pathname } = useRouter();
  const { xsScreen, smScreen } = useScreenSize();

  const viewSelected = getAppView(pathname);

  const { classes } = useStyles({
    showLogo: viewSelected.title !== 'Maps',
  });

  const [isResultsMenuOpen, setIsResultsMenuOpen] = React.useState(false);
  const [isInfoPopupOpen, setIsInfoPopupOpen] = React.useState(false);

  return (
    <AppBar color="primary" position="static" className={classes.appBar}>
      <Toolbar disableGutters className={classes.toolbar}>
        {!isEmbedded() && (
          <IconButton
            aria-label="menu"
            edge="start"
            size="large"
            onClick={toggleMenuDrawer}
          >
            <MenuIcon />
          </IconButton>
        )}

        <PNTitle
          title={viewSelected.title}
          extraTitle={extraTitle}
          showLogo={viewSelected.title !== 'Maps'}
        />

        {showSearchbar && (
          <Box flexGrow={1} maxWidth={360} marginLeft="auto">
            <Searchbar
              isInfoPopupOpen={isInfoPopupOpen}
              setIsInfoPopupOpen={setIsInfoPopupOpen}
              setIsResultsMenuOpen={setIsResultsMenuOpen}
            />
            <SearchResults
              isOpen={isResultsMenuOpen}
              setIsOpen={setIsResultsMenuOpen}
            />
            <SearchInfoPopup
              isOpen={isInfoPopupOpen}
              setIsOpen={setIsInfoPopupOpen}
            />
          </Box>
        )}

        {smScreen && showSearchbar && (
          <Box marginLeft={!xsScreen && showSearchbar ? 'initial' : 'auto'}>
            <IconButton
              aria-label="workspace"
              edge="end"
              size="large"
              color="inherit"
              onClick={() => setWorkspaceOpen(!isWorkspaceOpen)}
            >
              <AppsIcon />
            </IconButton>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

type PNTitleProps = {
  title: string;
  extraTitle: string | undefined;
  showLogo: boolean;
};

function PNTitle({ title, extraTitle, showLogo }: PNTitleProps) {
  const { classes } = useStyles({ showLogo });

  if (isEmbedded()) {
    return (
      <Typography
        className={classes.logo}
        variant="h6"
        color="textPrimary"
        component="a"
        href="https://petroninja.com"
        target="_blank"
      >
        Petro Ninja <span className={classes.title}>Maps</span>
      </Typography>
    );
  }

  if (showLogo) {
    return (
      <Typography className={classes.logo} variant="h6" color="textPrimary">
        <Typography variant="h6" color="textPrimary" component={Link} to="/">
          Petro Ninja
        </Typography>{' '}
        {!isEmpty(extraTitle) && (
          <span>
            · <span className={classes.extraTitle}>{extraTitle}</span>
          </span>
        )}
        {isStaging() && <StagingIcon className={classes.badgeIcon} />}
      </Typography>
    );
  }

  return (
    <Typography className={classes.logo} variant="h6">
      Petro Ninja <span className={classes.title}>{title}</span>
      {isStaging() && <StagingIcon className={classes.badgeIcon} />}
    </Typography>
  );
}
