import type { User } from '@pn/core/domain/user';
import {
  createWorkspaceItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { workspaceActions } from '@pn/core/storage';
import { wells } from '@pn/core/storage/workspace/pnWorkspaceItems/wells';

export const createList = (params: {
  user: User;
  color: unknown;
}): WorkspaceItem => {
  const { user, color } = params;

  const newItem = createWorkspaceItem(
    {
      source: 'item',
      sourceItem: wells, // always default to Wells to prevent creating lists out of Base Map, custom layers, etc.
      extraStyle: { color },
      queryOptions: {
        requestedIds: [],
      },
    },
    user
  );

  workspaceActions().create(newItem);

  return newItem;
};
