import { dependencies } from '@pn/core/dependencies';
import type { Layer } from '@pn/core/domain/layer';
import {
  getPointsLayerId,
  isEmptyWorkspaceItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { useWorkspaceStorage } from '@pn/core/storage';
import React from 'react';

/**
 * Change map pointer to crosshair when hovering over wells, pipelines, etc.
 */
export function useAutoApplyCrosshair(
  tableOnly = false,
  hideCrosshair = false
) {
  const { map } = dependencies;

  const { workspaceItems } = useWorkspaceStorage();

  const crosshairLayerIds = React.useMemo(
    () =>
      workspaceItems
        .filter((item) => item.isRendered)
        .reduce<string[]>((acc, item) => {
          if (
            ['_background', 'grids'].includes(item.id) ||
            isEmptyWorkspaceItem(item)
          ) {
            return acc;
          }

          acc.push(...getItemCrosshairLayerIds(item));
          return acc;
        }, []),
    [workspaceItems]
  );

  const shouldSkip = tableOnly || hideCrosshair;

  React.useEffect(() => {
    if (shouldSkip) return;

    // TODO we should write a custom Map function for this
    const mapboxMap = map._native as mapboxgl.Map;

    function onMouseMove(e: mapboxgl.MapMouseEvent & mapboxgl.EventData) {
      const features = mapboxMap.queryRenderedFeatures(e.point, {
        layers: crosshairLayerIds,
      });
      mapboxMap.getCanvas().style.cursor =
        features.length === 0 ? '' : 'crosshair';
    }

    mapboxMap.on('mousemove', onMouseMove);

    return () => {
      mapboxMap.off('mousemove', onMouseMove);
    };
  }, [shouldSkip, map, crosshairLayerIds]);
}

/**
 * A list of layer IDs that will render the crosshair cursor when hovered over.
 */
function getItemCrosshairLayerIds(item: WorkspaceItem): Layer['id'][] {
  return item.map.layers.flatMap(({ id, renderAsPoints }) => {
    return renderAsPoints ? [id, getPointsLayerId(id)] : [id];
  });
}
