import { unselectDataItem } from '@pn/core/operations/dataSelection';
import { mapActions, useWorkspaceStorage } from '@pn/core/storage';
import {
  usePNThemeContext,
  type PNTheme,
} from '@pn/ui/theme/PetroNinjaThemeProvider';
import { themeToMapMode } from '@pn/ui/theme/muiThemes';
import { useLibrary } from '@pn/ui/workspace/LibraryStateProvider';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelStateProvider';
import { isNil } from 'lodash-es';
import { useHotkeys } from 'react-hotkeys-hook';

export function usePNMapsKeyboardHotkeys() {
  const { workspaceItemSelected } = useWorkspaceStorage();

  const { theme, setPNTheme } = usePNThemeContext();
  const { isWorkspaceItemPanelOpen, closeWorkspaceItemPanel } =
    useWorkspaceItemPanel();
  const { isLibraryOpen, closeLibrary } = useLibrary();

  /**
   * Toggle between the light and dark themes.
   * Extra themes can only be enabled via the UI.
   */
  useHotkeys(
    'ctrl+alt+l',
    () => {
      const newPNTheme: PNTheme =
        theme.palette.mode === 'light' ? 'Petro Night' : 'Default';
      setPNTheme(newPNTheme);
      mapActions().updateMode(themeToMapMode[newPNTheme]);
    },
    [theme.palette.mode]
  );

  /**
   * Bind all relevant actions to the escape key in order of priority.
   */
  useHotkeys(
    'esc',
    () => {
      if (isWorkspaceItemPanelOpen) return closeWorkspaceItemPanel();
      if (isLibraryOpen) return closeLibrary();
      if (isNil(workspaceItemSelected)) return;

      unselectDataItem(workspaceItemSelected);
    },
    [
      closeLibrary,
      closeWorkspaceItemPanel,
      isWorkspaceItemPanelOpen,
      isLibraryOpen,
      workspaceItemSelected,
    ]
  );
}
