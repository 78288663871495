import DownloadIcon from '@mui/icons-material/Download';
import { Box, Checkbox, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SvgIconProps } from '@mui/material/SvgIcon';
import {
  TreeItem,
  treeItemClasses,
  TreeItemContentProps,
  TreeItemProps,
  useTreeItem,
} from '@mui/x-tree-view/TreeItem';
import clsx from 'clsx';
import { noop } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string;
  }
}

const useStyles = makeStyles()((theme) => ({
  label: {
    fontWeight: 'inherit',
    flexGrow: 1,
    marginLeft: 8,
  },
  labelIconContainer: {
    color: 'var(--tree-view-color)',
    height: 18,
    width: 18,
  },
  checkbox: {
    marginLeft: theme.spacing(0.5),
    padding: 0,
  },
}));

interface CustomTreeItemContentProps extends TreeItemContentProps {
  indeterminate?: boolean;
  isChecked?: boolean;
  disableCheckbox?: boolean;
  onToggle?: (...args: any[]) => void;
  onDownload?: (...args: any[]) => void;
}

type StyledTreeItemProps = TreeItemProps & {
  color?: string;
  labelIcon?: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  labelColor?: string;
  indeterminate?: boolean;
  isChecked?: boolean;
  disableCheckbox?: boolean;
  onToggle?: (...args: any[]) => void;
  onSelect?: (...args: any[]) => void;
  onDownload?: (...args: any[]) => void;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  userSelect: 'text',
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(1.5),
    paddingRight: 0,
    fontWeight: theme.typography.fontWeightRegular,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.action.hover,
    },
    [`& .${treeItemClasses.label}`]: {
      paddingLeft: 0,
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
}));

const CustomContent = React.forwardRef(function CustomContent(
  props: CustomTreeItemContentProps,
  ref: React.Ref<HTMLLIElement>
) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    isChecked = false,
    indeterminate = false,
    disableCheckbox = false,
    onToggle,
    onDownload,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    preventSelection,
  } = useTreeItem(nodeId);

  const { classes: extraClasses } = useStyles();

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    preventSelection(event);
  };

  const handleExpansionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    handleExpansion(event);
  };

  return (
    <Box
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      {icon && (
        <Box
          component="div"
          onClick={handleExpansionClick}
          className={classes.iconContainer}
        >
          {icon}
        </Box>
      )}
      {onToggle && (
        <Checkbox
          indeterminate={indeterminate}
          size="small"
          color="secondary"
          className={extraClasses.checkbox}
          disableRipple
          disabled={disableCheckbox}
          checked={isChecked}
          inputProps={{ 'aria-label': 'Checkbox' }}
          onChange={(e) => (onToggle ? onToggle(e.target.checked) : noop)}
        />
      )}
      <Typography
        component="div"
        className={classes.label}
        onClick={handleExpansionClick}
      >
        {label}
      </Typography>
      {onDownload && (
        <IconButton size="small" color="primary" onClick={onDownload}>
          <DownloadIcon />
        </IconButton>
      )}
    </Box>
  );
});

export const StyledTreeItem = (props: StyledTreeItemProps) => {
  const {
    color,
    labelIcon: LabelIcon,
    labelText,
    labelColor,
    onSelect,
    /* Prevent passing down custom props */
    indeterminate: _indeterminate,
    isChecked: _isChecked,
    onDownload: _onDownload,
    ...rest
  } = props;

  const { classes } = useStyles();

  return (
    <StyledTreeItemRoot
      ContentComponent={CustomContent}
      ContentProps={props as CustomTreeItemContentProps}
      label={
        <Box display="flex" alignItems="center" py="6px" pl="2px" pr={0}>
          <Typography
            variant="body2"
            noWrap
            color={labelColor ? labelColor : 'textPrimary'}
            className={classes.label}
            onClick={onSelect}
          >
            {labelText}
          </Typography>
          {LabelIcon && (
            <Box
              component={LabelIcon}
              color="inherit"
              className={classes.labelIconContainer}
            />
          )}
        </Box>
      }
      style={{
        '--tree-view-color': color,
      }}
      {...rest}
      TransitionProps={{
        unmountOnExit: true,
        mountOnEnter: true,
      }}
    />
  );
};
