import CheckIcon from '@mui/icons-material/Check';
import { Box, InputAdornment, TextField } from '@mui/material';
import { DEFAULT_COLOR_HEX } from '@pn/services/styles';
import Color from 'color';
import { noop } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

export const LARGE_SQ_SIZE = 30;
export const LARGE_SQ_GAP = 5;

export const SMALL_SQ_SIZE = 15;
export const SMALL_SQ_GAP = 4;

const useStyles = makeStyles<{
  variant: 'standard' | 'compact';
  disabled: boolean;
}>()((theme, { variant, disabled }) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    gap: variant === 'standard' ? LARGE_SQ_GAP : SMALL_SQ_GAP,
  },
  colorBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: variant === 'standard' ? LARGE_SQ_SIZE : SMALL_SQ_SIZE,
    height: variant === 'standard' ? LARGE_SQ_SIZE : SMALL_SQ_SIZE,
    borderRadius:
      variant === 'standard'
        ? theme.shape.borderRadius
        : theme.shape.borderRadius / 2,
    cursor: disabled ? 'initial' : 'pointer',
    opacity: disabled ? 0.5 : 1,
  },
  withBorder: {
    border: `1px solid ${theme.borderColor}`,
  },
  indicator: {
    width: Math.ceil(SMALL_SQ_SIZE / 3),
    height: Math.ceil(SMALL_SQ_SIZE / 3),
    borderRadius: '50%',
  },
  inputContainer: {
    width:
      variant === 'standard'
        ? LARGE_SQ_SIZE * 4 + LARGE_SQ_GAP * 3
        : SMALL_SQ_SIZE * 5 + SMALL_SQ_GAP * 4,
    height: variant === 'standard' ? LARGE_SQ_SIZE : 26,
    '& .MuiInputBase-root': {
      height: '100%',
      paddingLeft: variant === 'standard' ? undefined : theme.spacing(1),
      borderRadius:
        variant === 'standard'
          ? theme.shape.borderRadius
          : theme.shape.borderRadius / 2,
    },
    '& .MuiInputBase-input': {
      fontFamily: 'monospace',
      paddingRight: variant === 'standard' ? undefined : 0,
      paddingBottom: 10,
    },
  },
}));

type Props = {
  variant: 'standard' | 'compact';
  disabled: boolean;
  colors: string[];
  color: string;
  onChange: (value: string) => void;
};

export function ColorSelector({
  variant,
  disabled,
  colors,
  color,
  onChange,
}: Props) {
  const { classes, cx, theme } = useStyles({ variant, disabled });

  const colorAlpha = Color(color).alpha();
  const colorHex = color.startsWith('#')
    ? color.toUpperCase()
    : Color(color).hex();

  const [value, setValue] = React.useState('');
  React.useLayoutEffect(() => {
    setValue(colorHex.replace('#', ''));
  }, [colorHex]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);

    const hexCandidate = `#${event.target.value}`;
    if (isValidHexColor(hexCandidate, false)) {
      onChange(
        colorAlpha < 1
          ? Color(hexCandidate).alpha(colorAlpha).rgb().string()
          : hexCandidate
      );
    }
  };

  return (
    <>
      <Box className={classes.container}>
        {colors.map((c) => (
          <Box
            key={c}
            className={cx(classes.colorBox, {
              [classes.withBorder]:
                c ===
                (theme.palette.mode === 'light'
                  ? '#FFFFFF'
                  : DEFAULT_COLOR_HEX),
            })}
            style={{ backgroundColor: c }}
            onClick={
              disabled
                ? noop
                : () =>
                    onChange(
                      colorAlpha < 1
                        ? Color(c).alpha(colorAlpha).rgb().string()
                        : c
                    )
            }
          >
            {colorHex === c.toUpperCase() &&
              (variant === 'standard' ? (
                <CheckIcon
                  style={{ color: theme.palette.getContrastText(c) }}
                />
              ) : (
                <Box
                  className={classes.indicator}
                  style={{ backgroundColor: theme.palette.getContrastText(c) }}
                />
              ))}
          </Box>
        ))}
        {variant === 'standard' && (
          <TextField
            size="small"
            className={classes.inputContainer}
            disabled={disabled}
            value={value}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">#</InputAdornment>
              ),
            }}
          />
        )}
      </Box>

      {variant === 'compact' && (
        <Box mt={1}>
          <TextField
            size="small"
            className={classes.inputContainer}
            disabled={disabled}
            value={value}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">#</InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    </>
  );
}

function isValidHexColor(hex: string, allowAlpha: boolean): boolean {
  // Regular expression for HEX color without alpha
  const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

  // Regular expression for HEX color with alpha
  const hexAlphaRegex = /^#(?:[0-9a-fA-F]{4}){1,2}$/;

  return allowAlpha
    ? hexRegex.test(hex) || hexAlphaRegex.test(hex)
    : hexRegex.test(hex);
}
