import {
  getBoundingBox,
  toGeoPoint,
  geometryToGeoShape,
} from '@pn/core/domain/geography';
import type { ISearchResultMapper } from '@pn/core/mappers/search/ports';
import { isNil } from 'lodash-es';
import type { ApiSearchResult } from './types';

export const apiSearchResultMapper: ISearchResultMapper<ApiSearchResult> = {
  toDomainSearchResult: (apiSearchResult) => {
    if (apiSearchResult.result_category === 'location') {
      return {
        resultType: 'location',
        geoShape: geometryToGeoShape(apiSearchResult.geometry),
        label: apiSearchResult.label,
        bbox: !isNil(apiSearchResult.bbox)
          ? getBoundingBox([
              toGeoPoint(apiSearchResult.bbox[1], apiSearchResult.bbox[0]),
              toGeoPoint(apiSearchResult.bbox[3], apiSearchResult.bbox[2]),
            ])
          : undefined,
      };
    } else {
      return {
        _id: apiSearchResult.internal_id,
        dataType: apiSearchResult.data_type,
        resultType: apiSearchResult.result_category,
        geoShape: apiSearchResult.geometry
          ? geometryToGeoShape(apiSearchResult.geometry)
          : undefined,
        province: apiSearchResult.province,
        label: apiSearchResult.label,
      };
    }
  },
};
