import { amber, blue, grey } from '@mui/material/colors';
import { isString } from 'lodash-es';

// definitely not the best place for these types
const annotationDrawModes = [
  'draw_annotation_line',
  'draw_annotation_polygon',
  'draw_annotation_circle',
  'draw_annotation_text',
  'draw_annotation_area',
] as const;
export type AnnotationDrawMode = (typeof annotationDrawModes)[number];
export type DrawMode = 'simple_select' | 'direct_select' | AnnotationDrawMode;

export function isAnnotationDrawMode(arg: unknown): arg is AnnotationDrawMode {
  return (
    isString(arg) && annotationDrawModes.includes(arg as AnnotationDrawMode)
  );
}

export const defaultLineProperties = {
  lineColor: blue[500],
  lineWidth: 3,
};

export const defaultPolygonProperties = {
  lineColor: blue[500],
  lineWidth: 2,
  fillColor: blue[500],
  fillOpacity: 0.2,
};

export const defaultAreaProperties = {
  lineColor: amber[700],
  lineWidth: 2,
  fillColor: amber[200],
  fillOpacity: 0.1,
};

export const defaultTextProperties = {
  textField: '',
  textSize: 16,
  textColor: '#000',
};
