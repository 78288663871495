import { toDomainValue, type MappingItem } from '@pn/core/domain/data';
import type { UnitSystem } from '@pn/core/domain/types';
import { convertUnit } from '@pn/core/domain/units';
import { ConfigurationError } from '@pn/core/errors';
import type {
  IDataItemMapper,
  IDataItemValueMapper,
} from '@pn/core/mappers/data/ports';
import { isNil } from 'lodash-es';
import { toCsvField } from './csvQueryFieldsMapper';
import type { CsvDataItem, CsvValue } from './types';

export const csvDataItemMapper = (
  mapping: MappingItem[],
  unitSystem: UnitSystem
): IDataItemMapper<CsvDataItem> => {
  return {
    toDomainDataItem: () => {
      throw new ConfigurationError('Not implemented');
    },
    toTargetDataItem: (dataItem) => {
      return mapping.reduce<CsvDataItem>((csvItem, mappingItem) => {
        const field = mappingItem.field;
        const csvField = toCsvField(mappingItem, unitSystem);

        const fromValue = dataItem[field];
        const toValue = csvDataItemValueMapper.toTargetValue(
          toDomainValue(fromValue, mappingItem),
          unitSystem
        );

        csvItem[csvField] = toValue;
        return csvItem;
      }, {});
    },
  };
};

const csvDataItemValueMapper: IDataItemValueMapper<CsvValue> = {
  toDomainValue: () => {
    throw new Error('Not implemented');
  },
  toTargetValue: ({ value, domainType }, unitSystem: UnitSystem) => {
    if (isNil(value)) return undefined;

    switch (domainType) {
      case 'string':
        return value;
      case 'number':
        return value;
      case 'boolean':
        return value.toString();
      case 'object':
        return JSON.stringify(value);
      case 'DateString':
        return value;
      case 'SIUnit':
        return convertUnit(value, unitSystem).value;
      case 'GeoShape':
      case 'WKT':
        return value.toString();
      default:
        return domainType satisfies never;
    }
  },
};
