import CopyAllIcon from '@mui/icons-material/CopyAll';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  lighten,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Slider,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import type { AnnotationFeature } from '@pn/core/domain/layer';
import { useQuickProjectActions } from '@pn/core/operations/project';
import { draw } from '@pn/services/map/mapbox-gl-draw/draw';
import type { DrawMode } from '@pn/services/styles/annotation';
import {
  useAnnotations,
  type DrawProperties,
} from '@pn/ui/annotations/AnnotationProvider';
import { ColorPicker } from '@pn/ui/custom-components/color-picker';
import { useMenu } from '@pn/ui/hooks/useMenu';
import {
  cloneDeep,
  isEmpty,
  isNil,
  isNumber,
  isString,
  keys,
  noop,
} from 'lodash-es';
import React from 'react';
import { zIndex } from 'src/web-ui/zIndex';
import { makeStyles } from 'tss-react/mui';

export const ANNOTATION_PANEL_WIDTH = 285;

const useStyles = makeStyles()((theme) => ({
  panel: {
    position: 'absolute',
    top: `calc(64px + ${theme.spacing(2)})`,
    left: theme.spacing(2),
    width: ANNOTATION_PANEL_WIDTH,
    zIndex: zIndex(theme).annotationControls, // above the bottom table
  },
  formControlLabel: {
    marginTop: theme.spacing(2),
    marginLeft: 0,
  },
  code: {
    padding: `2px 4px`,
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${lighten('rgba(0, 0, 0, 0.23)', 0.5)}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.grey[700],
  },
  link: {
    cursor: 'pointer',
  },
}));

export const AnnotationPropertiesPanel = React.memo(_AnnotationPropertiesPanel);
function _AnnotationPropertiesPanel() {
  const { classes } = useStyles();

  const {
    closeAnnotationInterface,

    isEditMode,
    isEditingProhibited,
    annotationToEdit,
    annotationForm,
    setAnnotationFormName,

    drawProperties,
    setDrawProperties,

    drawMode,
    selectedFeatures,
    setSelectedFeatures,
    numberOfDrawnFeatures,

    handleCreate,
    handleDuplicate,
    handleUpdate,
    handleDelete,
  } = useAnnotations();

  const { showSharingToggle, isItemShared, toggleShared } =
    useQuickProjectActions();

  const {
    anchorEl,
    handlers: { openMenu, closeMenu },
  } = useMenu();

  const isSubmitButtonDisabled =
    numberOfDrawnFeatures === 0 ||
    isEmpty(annotationForm.name) ||
    annotationForm.name.length > 100 ||
    (isEditingProhibited && isEditMode) ||
    (drawMode !== 'simple_select' && drawMode !== 'direct_select');

  const properties: any = React.useMemo(() => {
    let props: AnnotationFeature['properties'] = {
      ...drawProperties[drawMode],
    };

    selectedFeatures.forEach((feature) => {
      props = {
        ...props,
        ...feature.properties,
      };
    });

    return props;
  }, [drawMode, drawProperties, selectedFeatures]);

  const onChangeProperty = (
    property: keyof AnnotationFeature['properties'],
    value: string | number
  ) => {
    const selectedFeatures = draw.getSelected().features;
    // console.log('selectedFeatures', selectedFeatures);
    // console.log('all features', draw.getAll().features);

    /**
     * Update all selected feature with the new property value if applicable.
     */
    if (!isEmpty(selectedFeatures)) {
      selectedFeatures.forEach(({ id, properties }) => {
        if (!isString(id) || isNil(properties![property])) return;

        draw.setFeatureProperty(id, property, value);
        draw.add(draw.get(id)!); // redraw the feature to reflect the change
      });

      setSelectedFeatures(draw.getSelected().features as AnnotationFeature[]); // set updated selected features
    }

    /**
     * Update all matching draw properties.
     * E.g. `lineColor` will be applied to all annotation modes while
     * `fillColor` will only be applied to polygon and circle modes.
     */
    setDrawProperties((prev) =>
      updateMatchingDrawProperties(prev, property, value)
    );
  };

  return (
    <Paper variant="outlined" className={classes.panel}>
      <Box px={2} py={1}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6" noWrap>
            {isEditMode ? 'Edit Annotation' : 'New Annotation'}
          </Typography>
          {isEditMode && (
            <Box mr={-1}>
              <IconButton
                id="annotation-menu-button"
                aria-label="more"
                aria-controls="annotation-menu"
                aria-haspopup="true"
                onClick={openMenu}
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>

        {isEditingProhibited && isEditMode && (
          <Alert severity="warning">
            <Typography variant="body2">
              This annotation can only be edited by its author. You can{' '}
              <Link className={classes.link} onClick={handleDuplicate}>
                duplicate
              </Link>{' '}
              first to edit a copy.
            </Typography>
          </Alert>
        )}

        <Box mt={2}>
          <TextField
            fullWidth
            size="small"
            autoComplete="off"
            variant="outlined"
            label="Name"
            disabled={isEditingProhibited}
            value={annotationForm.name}
            error={annotationForm.name.length > 100}
            helperText={
              annotationForm.name.length > 100
                ? 'Max 100 characters'
                : undefined
            }
            onChange={(e) => setAnnotationFormName(e.target.value)}
          />
        </Box>

        {!isNil(annotationToEdit) && showSharingToggle && (
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Switch
                size="small"
                color="info"
                checked={isItemShared(annotationToEdit)}
                onChange={(event) =>
                  toggleShared(annotationToEdit, event.target.checked)
                }
              />
            }
            label="Share with my company"
            slotProps={{
              typography: {
                variant: 'body2',
              },
            }}
          />
        )}

        <Box mt={2} />

        <Menu
          id="annotation-menu"
          MenuListProps={{
            'aria-labelledby': 'annotation-menu-button',
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: '20ch',
            },
          }}
        >
          <MenuItem
            onClick={() => {
              closeMenu();
              handleDuplicate();
            }}
          >
            <ListItemIcon>
              <CopyAllIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Duplicate</ListItemText>
          </MenuItem>
          <MenuItem
            disabled={!isEditMode || (isEditingProhibited && isEditMode)}
            onClick={() => {
              closeMenu();
              handleDelete();
            }}
          >
            <ListItemIcon>
              <DeleteForeverIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>

        {!isNil(properties.lineColor) && (
          <Box mb={2}>
            <ColorPicker
              title="Line Color"
              variant="compact"
              color={properties.lineColor}
              defaultColor={undefined}
              onChange={(color) =>
                onChangeProperty('lineColor', color as string)
              }
            />
          </Box>
        )}
        {!isNil(properties.lineWidth) && (
          <Box mb={1}>
            <Typography variant="caption">Line Width</Typography>
            <Slider
              aria-labelledby="line-width-slider"
              getAriaValueText={(value) => value.toString()}
              size="small"
              marks
              step={1}
              min={1}
              max={10}
              valueLabelDisplay="auto"
              value={properties.lineWidth}
              onChange={(e, lineWidth) =>
                isNumber(lineWidth)
                  ? onChangeProperty('lineWidth', lineWidth)
                  : noop
              }
            />
          </Box>
        )}
        {!isNil(properties.fillColor) && (
          <Box mb={2}>
            <ColorPicker
              title="Fill Color"
              variant="compact"
              color={properties.fillColor}
              defaultColor={undefined}
              onChange={(color) =>
                onChangeProperty('fillColor', color as string)
              }
            />
          </Box>
        )}
        {!isNil(properties.fillOpacity) && (
          <Box mb={1}>
            <Typography variant="caption">Fill Opacity</Typography>
            <Slider
              aria-labelledby="fill-opacity-slider"
              getAriaValueText={(value) => value.toString()}
              size="small"
              marks
              step={0.1}
              min={0.0}
              max={1.0}
              valueLabelDisplay="auto"
              value={properties.fillOpacity}
              onChange={(e, opacity) =>
                isNumber(opacity)
                  ? onChangeProperty('fillOpacity', opacity)
                  : noop
              }
            />
          </Box>
        )}
        {!isNil(properties.textColor) && (
          <Box mb={2}>
            <ColorPicker
              title="Text Color"
              variant="compact"
              color={properties.textColor}
              defaultColor={undefined}
              onChange={(color) =>
                onChangeProperty('textColor', color as string)
              }
            />
          </Box>
        )}
        {!isNil(properties.textSize) && (
          <Box mb={1}>
            <Typography variant="caption">Text Size</Typography>
            <Slider
              aria-labelledby="text-size-slider"
              getAriaValueText={(value) => value.toString()}
              size="small"
              marks
              step={4}
              min={8}
              max={64}
              valueLabelDisplay="auto"
              value={properties.textSize}
              onChange={(e, size) =>
                isNumber(size) ? onChangeProperty('textSize', size) : noop
              }
            />
          </Box>
        )}

        {numberOfDrawnFeatures === 0 &&
          Object.values(properties).every(isNil) && (
            <Alert severity="info">
              <Typography variant="body2">
                Use <kbd className={classes.code}>1</kbd>{' '}
                <kbd className={classes.code}>2</kbd>{' '}
                <kbd className={classes.code}>3</kbd> and{' '}
                <kbd className={classes.code}>4</kbd> keys to select drawing
                tools and <kbd className={classes.code}>Del</kbd> to remove
                selected features.
              </Typography>
            </Alert>
          )}

        {numberOfDrawnFeatures > 1 &&
          Object.values(properties).every(isNil) && (
            <Alert severity="info">
              <Typography variant="body2">
                Hold <kbd className={classes.code}>Shift</kbd> to select
                multiple features.
              </Typography>
            </Alert>
          )}

        {drawMode === 'draw_annotation_text' && (
          <Alert severity="info">
            <Typography variant="body2">
              Press <kbd className={classes.code}>Enter</kbd> to save text.
            </Typography>
          </Alert>
        )}

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          mt={2}
        >
          <Box mr={1}>
            <Button
              color="primary"
              variant="text"
              size="small"
              onClick={closeAnnotationInterface}
            >
              Cancel
            </Button>
          </Box>

          <Button
            color="info"
            variant="text"
            size="small"
            disabled={isSubmitButtonDisabled}
            onClick={isEditMode ? handleUpdate : () => handleCreate({})}
          >
            {isEditMode ? 'Update' : 'Create'}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}

function updateMatchingDrawProperties(
  drawProperties: DrawProperties,
  property: keyof AnnotationFeature['properties'],
  value: string | number
): DrawProperties {
  const updatedDrawProperties = cloneDeep(drawProperties);

  keys(drawProperties).forEach((drawMode) => {
    if (property in updatedDrawProperties[drawMode as DrawMode]) {
      updatedDrawProperties[drawMode as DrawMode][property] = value as any;
    }
  });

  return updatedDrawProperties;
}

/**
 * @onChangeProperty
 * Update the draw properties for the currently selected draw mode.
 */
// if (drawMode !== 'simple_select' && drawMode !== 'direct_select') {
//   setDrawProperties((prev) => ({
//     ...prev,
//     [drawMode]: {
//       ...prev[drawMode],
//       [property]: value,
//     },
//   }));
// }
