import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './CustomTable.styles';

interface Props extends DataGridProProps {
  areRowClicksEnabled: boolean;
  disableBorder?: boolean;
  className?: string;
  columns: any[];
  rows: any[];
}

export const CustomTable = ({
  areRowClicksEnabled = false,
  disableBorder = false,
  className,
  columns,
  rows,
  ...rest
}: Props) => {
  const { classes } = useStyles({ areRowClicksEnabled, disableBorder });

  const styledColumns = React.useMemo(
    () => columns.map((col) => ({ ...col, width: col.width ?? 150 })),
    [columns]
  );

  return (
    <DataGridPro
      columns={styledColumns}
      rows={rows}
      rowHeight={38}
      hideFooterSelectedRowCount
      className={clsx(classes.root, className)}
      {...rest}
    />
  );
};
