import { drop } from 'lodash-es';

export const appViews = [
  {
    title: 'Maps',
    route: '/',
    hidden: false,
  },
  {
    title: 'Dashboards',
    route: '/dashboards',
    hidden: false,
  },
  {
    title: 'Login',
    route: '/login',
    hidden: true,
  },
  {
    title: 'My Account',
    route: '/account',
    hidden: true,
  },
  {
    title: 'Enterprise Dashboard',
    route: '/enterprise',
    hidden: true,
  },
  {
    title: 'Charts',
    route: '/production',
    hidden: true,
  },
  {
    title: 'Aggregate Production',
    route: '/lists',
    hidden: true,
  },
  {
    title: 'Map Print Builder',
    route: '/map-print-builder',
    hidden: true,
  },
  {
    title: 'Terms',
    route: '/terms',
    hidden: true,
  },
  {
    title: 'Privacy',
    route: '/privacy',
    hidden: true,
  },
  {
    title: 'SaaS Terms',
    route: '/saas',
    hidden: true,
  },
  {
    title: 'Error',
    route: '/error',
    hidden: true,
  },
];

export function getAppView(pathname: string) {
  for (const view of drop(appViews)) {
    if (pathname.startsWith(view.route)) {
      return view;
    }
  }

  return appViews[0];
}
