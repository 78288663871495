import { dependencies } from '@pn/core/dependencies';
import {
  isAnnotationSource,
  type AnnotationFeature,
} from '@pn/core/domain/layer';
import {
  createAnnotationMapConfig,
  createWorkspaceItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { isEditable } from '@pn/core/permissions/editability';
import {
  useCurrentUserStorage,
  useMapStorage,
  workspaceActions,
} from '@pn/core/storage';
import { generateCopyName } from '@pn/core/utils/string';
import { apiAnnotationMapper } from '@pn/services/api/annotation/apiAnnotationMapper';
import { draw } from '@pn/services/map/mapbox-gl-draw/draw';
import {
  defaultAreaProperties,
  defaultLineProperties,
  defaultPolygonProperties,
  defaultTextProperties,
  isAnnotationDrawMode,
  type DrawMode,
} from '@pn/services/styles/annotation';
import { useConfirmationDialog } from '@pn/ui/context-components/ConfirmationDialog';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelStateProvider';
import assert from 'assert';
import { isEmpty, isNil, set } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useConvertToSelection } from './hooks/useConvertToSelection';
import { useDelegateKeyboardEvents } from './hooks/useDelegateKeyboardEvents';
import { useMapKeyboardControls } from './hooks/useMapKeyboardControls';
import { useQuickSelection } from './hooks/useQuickSelection';

type AnnotationForm = {
  name: string;
  features: AnnotationFeature[];
  isArea: boolean;
};

export type DrawProperties = Record<DrawMode, AnnotationFeature['properties']>;

type AnnotationContextType = {
  isAnnotationInterfaceOpen: boolean;
  openAnnotationInterface: () => void;
  closeAnnotationInterface: () => void;
  toggleAnnotationInteface: () => void;

  isEditMode: boolean;
  isEditingProhibited: boolean;
  annotationToEdit: WorkspaceItem | undefined;
  annotationForm: AnnotationForm;
  setAnnotationFormName: (name: string) => void;

  drawProperties: DrawProperties;
  setDrawProperties: React.Dispatch<React.SetStateAction<DrawProperties>>;

  drawMode: DrawMode;
  setDrawMode: React.Dispatch<React.SetStateAction<DrawMode>>;
  areFeaturesSelected: boolean;
  selectedFeatures: AnnotationFeature[];
  setSelectedFeatures: (features: AnnotationFeature[]) => void;
  numberOfDrawnFeatures: number;
  setNumberOfDrawnFeatures: (numberOfDrawnFeatures: number) => void;

  convertToSelection: () => void;

  handleCreate: (params: { isArea?: boolean }) => Promise<WorkspaceItem>;
  handleDuplicate: () => void;
  handleUpdate: () => void;
  handleDelete: () => void;
};

const AnnotationContext = React.createContext({} as AnnotationContextType);

const useStyles = makeStyles()((theme) => ({
  title: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
}));

const initialAnnotationForm: AnnotationForm = {
  name: '',
  features: [],
  isArea: false,
};

const defaultDrawProperties: DrawProperties = {
  simple_select: {},
  direct_select: {},
  draw_annotation_line: defaultLineProperties,
  draw_annotation_polygon: defaultPolygonProperties,
  draw_annotation_circle: defaultPolygonProperties,
  draw_annotation_text: defaultTextProperties,
  draw_annotation_area: defaultAreaProperties,
};

type ContextProviderProps = {
  children: React.ReactNode;
};

export const AnnotationProvider = ({ children }: ContextProviderProps) => {
  const {
    map,
    notificationService: { notify },
    apiClient,
  } = dependencies;
  const mapboxMap = map._native as mapboxgl.Map;

  const { classes } = useStyles();

  const { user } = useCurrentUserStorage();
  const { isInitialized: isMapInitialized } = useMapStorage();

  const { annotationToEdit, setAnnotationToEdit } = useWorkspaceItemPanel();
  const { handleOpenConfirmationDialog } = useConfirmationDialog();

  const isEditMode = !isNil(annotationToEdit);
  const isEditingProhibited = isEditMode && !isEditable(annotationToEdit, user);

  const [isAnnotationInterfaceOpen, setIsAnnotationInterfaceOpen] =
    React.useState(false);
  const [annotationForm, setAnnotationForm] = React.useState(
    initialAnnotationForm
  );

  const [drawMode, setDrawMode] = React.useState<DrawMode>('simple_select');
  const [selectedFeatures, setSelectedFeatures] = React.useState<
    AnnotationFeature[]
  >([]);
  const areFeaturesSelected = !isEmpty(selectedFeatures);
  const [numberOfDrawnFeatures, setNumberOfDrawnFeatures] = React.useState(0);

  const [drawProperties, setDrawProperties] = React.useState(
    defaultDrawProperties
  );

  const skipEffects =
    !isMapInitialized ||
    (!isAnnotationInterfaceOpen && drawMode === 'simple_select'); // to support multi-selection tools outside of Annotation UI

  const reset = React.useCallback(() => {
    setAnnotationToEdit(undefined);
    setIsAnnotationInterfaceOpen(false);
    setDrawMode('simple_select');
    setSelectedFeatures([]);
    setNumberOfDrawnFeatures(0);
    setDrawProperties({ ...defaultDrawProperties });
    setAnnotationForm(initialAnnotationForm);
    draw.deleteAll();
  }, [setAnnotationToEdit]);

  // Add mapbox-gl-draw controls to the map
  React.useEffect(() => {
    if (skipEffects) return;

    mapboxMap.addControl(draw);

    return () => {
      mapboxMap.removeControl(draw);
    };
  }, [skipEffects, mapboxMap]);

  // Set or unset annotation form when selected annotation changes
  React.useEffect(() => {
    if (skipEffects) return;

    if (!isNil(annotationToEdit)) {
      assert(
        isAnnotationSource(annotationToEdit.map.layers[0].source),
        'Invalid annotation source'
      );

      setAnnotationForm({
        name: annotationToEdit.name,
        isArea: false,
        features: annotationToEdit.map.layers[0].source.data.features,
      });
      setNumberOfDrawnFeatures(
        annotationToEdit.map.layers[0].source.data.features.length
      );
      draw.add({
        type: 'FeatureCollection',
        features: annotationToEdit.map.layers[0].source.data.features,
      });
    } else {
      setAnnotationForm(initialAnnotationForm);
      // reset();
    }
  }, [skipEffects, annotationToEdit]);

  /**
   * Clear visualized annotation layers when MapboxGL Draw is active.
   */
  React.useEffect(() => {
    if (
      skipEffects ||
      isNil(annotationToEdit) ||
      annotationToEdit.itemType !== 'annotation'
    ) {
      return;
    }

    map.removeAnnotationLayer(annotationToEdit);

    return () => {
      workspaceActions().revisualize(annotationToEdit.id);
    };
  }, [skipEffects, annotationToEdit, map]);

  React.useEffect(() => {
    if (skipEffects) return;

    const onModeChange = (event: { mode: DrawMode }) => {
      setDrawMode(event.mode);
    };

    const onSelectionChange = (event: { features: AnnotationFeature[] }) => {
      setSelectedFeatures(event.features);
    };

    const onStatsRefresh = () => {
      setNumberOfDrawnFeatures(draw.getAll().features.length);
    };

    mapboxMap.on('draw.modechange', onModeChange);
    mapboxMap.on('draw.selectionchange', onSelectionChange);
    mapboxMap.on('draw.create', onStatsRefresh);
    mapboxMap.on('draw.delete', onStatsRefresh);

    return () => {
      mapboxMap.off('draw.modechange', onModeChange);
      mapboxMap.off('draw.selectionchange', onSelectionChange);
      mapboxMap.off('draw.create', onStatsRefresh);
      mapboxMap.off('draw.delete', onStatsRefresh);
    };
  }, [skipEffects, mapboxMap]);

  React.useEffect(() => {
    if (skipEffects) return;

    /**
     * HACK: do not override `opts` for simple_select and direct_select modes
     * after they have been set by the plugin.
     * I.e. only override `opts` for draw_annotation_* modes because that's how
     * we set new style properties from the PropertiesPanel UI before drawing.
     */
    if (!isAnnotationDrawMode(drawMode) && draw.getMode() === drawMode) return;

    /**
     * This happens when a user changes text color/size while typing.
     */
    if (
      draw.getMode() === 'draw_annotation_text' &&
      drawMode === 'draw_annotation_text'
    ) {
      draw.changeMode<any>(drawMode, {
        ...drawProperties[drawMode],
        inProgress: true,
      });
      return;
    }

    draw.changeMode<any>(drawMode, drawProperties[drawMode]);
  }, [skipEffects, drawMode, drawProperties]);

  /**
   * Kind of a hack.
   * Clean up all incomplete text draw features.
   */
  React.useEffect(() => {
    if (skipEffects) return;

    if (drawMode !== 'draw_annotation_text') {
      const features = draw.getAll().features;
      const badFeatures = features.filter(
        (f) => f.properties?.textField === ''
      );
      badFeatures.forEach((badFeature) => draw.delete(badFeature.id as string));
    }
  }, [skipEffects, drawMode]);

  useMapKeyboardControls({ drawMode });
  useDelegateKeyboardEvents({ drawMode, isAnnotationInterfaceOpen });

  const openAnnotationInterface = React.useCallback(() => {
    setIsAnnotationInterfaceOpen(true);
  }, []);

  const closeAnnotationInterface = () => {
    function onClose() {
      reset();
    }

    if (!isEmpty(draw.getAll().features)) {
      if (!isNil(handleOpenConfirmationDialog)) {
        // TODO: dont want/need this in intel
        handleOpenConfirmationDialog({
          title: 'Exit annotation editor?',
          text: 'All unsaved changes will be lost.',
          action: onClose,
        });
      } else {
        onClose();
      }
    } else {
      onClose();
    }
  };

  const { convertToSelection } = useConvertToSelection();

  useQuickSelection({
    isAnnotationInterfaceOpen,
    convertToSelection,
    reset,
  });

  const setAnnotationFormName = (name: string) => {
    setAnnotationForm((prev) => ({ ...prev, name }));
  };

  const handleCreate = async ({ isArea = false }) => {
    assert(user, 'User must be defined to create annotations');

    const newItem = createWorkspaceItem(
      {
        source: 'annotation',
        isTemporary: false,
        name: annotationForm.name.trim(),
        metadata: isArea ? { isQueryArea: true } : undefined,
      },
      user
    );

    set(newItem, 'map.layers[0].source.data.features', draw.getAll().features);

    const response = await apiClient.request<{ id: string }>({
      method: 'POST',
      url: 'v2/annotations',
      payload: apiAnnotationMapper().toOriginalItem(newItem),
    });

    newItem.id = response.id;
    newItem.dataType = response.id;
    newItem.query.dataType = response.id;
    newItem.map = createAnnotationMapConfig({
      id: response.id,
      features: draw.getAll().features as AnnotationFeature[],
    });

    workspaceActions().create(newItem);
    workspaceActions().addToWorkspace(newItem.id);

    notify('Annotation created!');
    reset();

    return newItem;
  };

  const handleDuplicate = async () => {
    assert(user, 'User must be defined to create annotations');

    const newItem = createWorkspaceItem(
      {
        source: 'annotation',
        isTemporary: false,
        name: generateCopyName(annotationForm.name),
      },
      user
    );

    set(newItem, 'map.layers[0].source.data.features', draw.getAll().features);

    const response = await apiClient.request<{ id: string }>({
      method: 'POST',
      url: 'v2/annotations',
      payload: apiAnnotationMapper().toOriginalItem(newItem),
    });

    newItem.id = response.id;
    newItem.dataType = response.id;
    newItem.query.dataType = response.id;

    workspaceActions().create(newItem);
    workspaceActions().addToWorkspace(newItem.id);

    notify('Annotation duplicated!');
    reset();
  };

  const handleUpdate = () => {
    assert(annotationToEdit, 'annotationToEdit is undefined');
    assert(
      isAnnotationSource(annotationToEdit.map.layers[0].source),
      'Invalid annotation source'
    );

    const updatedWorkspaceItem: WorkspaceItem = {
      ...annotationToEdit,
      name: annotationForm.name.trim(),
      map: {
        ...annotationToEdit.map,
        layers: [
          {
            ...annotationToEdit.map.layers[0],
            source: {
              ...annotationToEdit.map.layers[0].source,
              data: {
                ...annotationToEdit.map.layers[0].source.data,
                features: draw.getAll().features as AnnotationFeature[],
              },
            },
          },
        ],
      },
    };

    workspaceActions().update(updatedWorkspaceItem);

    notify('Annotation updated!');
    reset();
  };

  const handleDelete = () => {
    assert(annotationToEdit, 'annotationToEdit is undefined');

    handleOpenConfirmationDialog({
      title: 'Delete annotation?',
      text: (
        <span>
          Are you sure you want to delete{' '}
          <span className={classes.title}>{annotationToEdit.name}</span>? This
          action cannot be undone.
        </span>
      ),
      action: () => {
        workspaceActions().remove(annotationToEdit);
        notify('Annotation deleted');
        reset();
      },
    });
  };

  return (
    <AnnotationContext.Provider
      value={{
        isAnnotationInterfaceOpen,
        openAnnotationInterface,
        closeAnnotationInterface,
        toggleAnnotationInteface: () => {
          setIsAnnotationInterfaceOpen((prev) => !prev);
        },

        isEditMode,
        isEditingProhibited,
        annotationToEdit,
        annotationForm,
        setAnnotationFormName,

        drawProperties,
        setDrawProperties,

        drawMode,
        setDrawMode,
        areFeaturesSelected,
        selectedFeatures,
        setSelectedFeatures,
        numberOfDrawnFeatures,
        setNumberOfDrawnFeatures,

        convertToSelection,

        handleCreate,
        handleDuplicate,
        handleUpdate,
        handleDelete,
      }}
    >
      {children}
    </AnnotationContext.Provider>
  );
};

export const useAnnotations = () => React.useContext(AnnotationContext);
