import { UserPlans, type User } from '@pn/core/domain/user';
import { ConfigurationError } from '@pn/core/errors';
import { isNil } from 'lodash-es';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cordova: any;
  }
}

export function isProduction(): boolean {
  return import.meta.env.VITE_APP_HOST_ENV === 'production';
}

export function isStaging(): boolean {
  return import.meta.env.VITE_APP_HOST_ENV === 'staging';
}

export function isCordova(): boolean {
  // return import.meta.env.VITE_APP_HOST_ENV !== 'production'
  //   ? true
  //   : !isNil(window.cordova); // DEBUG
  return !isNil(window.cordova);
}

export function isFreeCordova(user: User | undefined): boolean {
  return (
    isCordova() && (isNil(user?.userPlan) || user?.userPlan === UserPlans.Free)
  );
}

export function isAndroid(): boolean {
  return getPlatform() === 'android';
}

export function isIos(): boolean {
  return getPlatform() === 'ios';
}

export function isPWA(): boolean {
  return window.matchMedia('(display-mode: standalone)').matches;
}

export function isTouchDevice(): boolean {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

function getPlatform(): string {
  return window?.cordova?.platformId?.toLowerCase();
}

/**
 * Environment variables.
 */

function set(key: string, value: string | undefined) {
  if (isNil(value)) {
    throw new ConfigurationError(`Environment variable ${key} is not set`);
  }
  env[key] = value;
}

const env: Record<string, string> = {};

set(
  'PN_API_URL',
  import.meta.env.VITE_APP_API_URL ?? import.meta.env.NEXT_PUBLIC_API_URL
);
set(
  'PN_API_KEY',
  import.meta.env.VITE_APP_API_KEY ?? import.meta.env.NEXT_PUBLIC_API_KEY
);
set(
  'PN_WS_URL',
  isProduction() ? 'wss://api.petroninja.com' : 'wss://api.petroniche.com'
);

set(
  'PN_MAPBOX_ACCESS_TOKEN',
  'pk.eyJ1Ijoic2JpbHN0b24iLCJhIjoiY2l1eGJmYXFkMDQ0bzJvbDF3cm43YW8yZiJ9.shjV2e-iaV6RcTjP_7GiMw'
);

export default env;
