import { StackedNotificationProvider } from '@pn/services/notifications/StackedNotificationProvider';
import { AnnotationProvider } from '@pn/ui/annotations/AnnotationProvider';
import { ConfirmationDialogStateProvider } from '@pn/ui/context-components/ConfirmationDialog';
import { ExternalPanelStateProvider } from '@pn/ui/context-components/ExternalPanel';
import { LibraryStateProvider } from '@pn/ui/workspace/LibraryStateProvider';
import { WorkspaceItemPanelStateProvider } from '@pn/ui/workspace/WorkspaceItemPanelStateProvider';
import React from 'react';
import { ExtraDataProvider } from 'src/web-ui/main-panel/components/dense-components/ExtraDataProvider';
import { PortalsProvider } from 'src/web-ui/main-panel/portals/PortalsProvider';
import { ProjectDialogProvider } from 'src/web-ui/project/ProjectDialogProvider';

type ReactProvider = React.ComponentType<{ children: React.ReactNode }>;
type Props = {
  children: React.ReactNode;
};

export function ReactContextsProvider({ children }: Props) {
  const providers: ReactProvider[] = [
    StackedNotificationProvider,
    ConfirmationDialogStateProvider,
    ExternalPanelStateProvider,
    PortalsProvider,
    LibraryStateProvider,
    WorkspaceItemPanelStateProvider,
    ProjectDialogProvider,
    AnnotationProvider,
    ExtraDataProvider,
  ];

  return composeProviders(providers, children);
}

function composeProviders(
  providers: ReactProvider[],
  children: React.ReactNode
): React.ReactNode {
  return providers.reduceRight(
    (kids, Provider) => <Provider>{kids}</Provider>,
    children
  );
}
