import type { UnitSystem } from '@pn/core/domain/types';
import { convertUnit, toSIUnit } from '@pn/core/domain/units';
import { isNil } from 'lodash-es';

// currently, only used in Query mappers, so it's ok to keep it here
export function inverseConvertValue(
  value: number,
  symbol: string,
  unitSystem: UnitSystem
): number {
  if (isNil(value)) return value;
  const unit = toSIUnit({ value, symbol });
  const convertedValue = convertUnit(unit, unitSystem).value!;
  const conversionFactor = convertedValue / value;
  return value / conversionFactor;
}
