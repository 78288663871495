import { ErrorBoundary } from '@appsignal/react';
import { useCurrentUserStorage } from '@pn/core/storage';
import { stringifyUserId } from '@pn/services/loggers/appSignalErrorLogger';
import { appsignal } from './appSignalManager';

export type ErrorPageProps = {
  error: Error;
  reset: () => void;
};

type Props = {
  children: React.ReactNode;
  errorPage: ({ error, reset }: ErrorPageProps) => JSX.Element;
};

/**
 * Only React render errors are caught here.
 * Reference: https://reactjs.org/docs/error-boundaries.html
 *
 * {@link https://github.com/appsignal/appsignal-javascript/blob/main/packages/react/src/ErrorBoundary.tsx}
 */
export const ErrorBoundaryProvider = ({
  children,
  errorPage: ErrorPage,
}: Props) => {
  const { user } = useCurrentUserStorage();

  const handleReset = () => {
    window.location.replace('/');
  };

  return (
    <ErrorBoundary
      instance={appsignal}
      action="ErrorBoundary"
      tags={{ userId: stringifyUserId(user?.id) }}
      fallback={(error: Error) => (
        <ErrorPage error={error} reset={handleReset} />
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

/**
 * For apps without user authentication.
 */
export const UserlessErrorBoundaryProvider = ({
  children,
  errorPage: ErrorPage,
}: Props) => {
  const handleReset = () => {
    window.location.replace('/');
  };

  return (
    <ErrorBoundary
      instance={appsignal}
      action="ErrorBoundary"
      fallback={(error: Error) => (
        <ErrorPage error={error} reset={handleReset} />
      )}
    >
      {children}
    </ErrorBoundary>
  );
};
