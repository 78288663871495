import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

type ActionFn = (...args: any[]) => void | Promise<void>;

type ConfirmDialogOpenProps = {
  title: string;
  text: string | React.ReactNode;
  action: ActionFn;
};

type ContextType = {
  isConfirmationDialogOpen: boolean;
  inProgress: boolean;
  title: string;
  text: string | React.ReactNode;
  action: ActionFn;
  handleOpenConfirmationDialog: ({
    title,
    text,
    action,
  }: ConfirmDialogOpenProps) => void;
  handleCloseConfirmationDialog: () => void;
  setInProgress: (inProgress: boolean) => void;
};

const ConfirmationDialogStateContext = React.createContext({} as ContextType);

export const useConfirmationDialog = () =>
  React.useContext(ConfirmationDialogStateContext);

type ContextProviderProps = {
  children: React.ReactNode;
};

export const ConfirmationDialogStateProvider = ({
  children,
}: ContextProviderProps) => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [text, setText] = React.useState<string | React.ReactNode>('');
  const [action, setAction] = React.useState<ActionFn>(() => {});

  const handleOpenConfirmationDialog = React.useCallback(
    ({
      title: dialogTitle,
      text: dialogText,
      action: dialogAction,
    }: ConfirmDialogOpenProps) => {
      setTitle(dialogTitle);
      setText(dialogText);
      setAction(() => dialogAction);
      setIsConfirmationDialogOpen(true);
    },
    []
  );

  const handleCloseConfirmationDialog = React.useCallback(() => {
    setIsConfirmationDialogOpen(false);
    setAction(() => {});
  }, []);

  return (
    <ConfirmationDialogStateContext.Provider
      value={{
        isConfirmationDialogOpen,
        inProgress,
        text,
        title,
        action,
        handleOpenConfirmationDialog,
        handleCloseConfirmationDialog,
        setInProgress,
      }}
    >
      {children}
      <ConfirmationDialog />
    </ConfirmationDialogStateContext.Provider>
  );
};

const useStyles = makeStyles()((theme) => ({
  dialog: {
    maxWidth: `calc(600px - ${theme.spacing(6)})`, // slightly narrower than default width
  },
}));

function ConfirmationDialog() {
  const { classes } = useStyles();
  const {
    isConfirmationDialogOpen,
    title,
    text,
    inProgress,
    action,
    handleCloseConfirmationDialog,
    setInProgress,
  } = React.useContext(ConfirmationDialogStateContext);

  const handleClose = () => handleCloseConfirmationDialog();

  const handleYes = async () => {
    setInProgress(true);
    await action();
    setInProgress(false);

    handleCloseConfirmationDialog();
  };

  return (
    <Dialog
      fullWidth
      open={isConfirmationDialogOpen}
      classes={{ paper: classes.dialog }}
      onClose={inProgress ? () => {} : handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={inProgress} onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" disabled={inProgress} onClick={handleYes}>
          Yes
        </Button>
      </DialogActions>
      {inProgress && <LinearProgress />}
    </Dialog>
  );
}
