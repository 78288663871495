import GetAppIcon from '@mui/icons-material/GetApp';
import { ClassNameMap } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { KeyValueTable } from '@pn/core/domain/data-info';
import { isCordova } from '@pn/core/utils/env';
import { FormattedTableCell } from 'src/web-ui/main-panel/components/dense-components/tables/FormattedTableCell';

type Props = {
  classes: ClassNameMap;
  table: KeyValueTable;
  onExportCSV: () => void;
};

export function KeyValueTableComponent({ classes, table, onExportCSV }: Props) {
  return (
    <Box>
      <Box className={classes.titleContainer}>
        <Typography className={classes.tableTitle}>{table.title}</Typography>
        {!table.noExport && !isCordova() && (
          <IconButton size="small" color="default" onClick={onExportCSV}>
            <GetAppIcon />
          </IconButton>
        )}
      </Box>
      <Divider />
      <Table size="small">
        <TableBody>
          {table.rows.map((row) => {
            return (
              <TableRow key={row.key} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>{row.key}</TableCell>
                <FormattedTableCell
                  align="right"
                  classes={classes}
                  value={row.value}
                  isButton={row.isButton}
                  intelUrl={row.intelUrl}
                  inlineSymbol
                />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
}
