import { IDataInfoDocumentMapper } from '@pn/core/mappers/data-info/ports';
import { ApiDocument } from './types';

export const apiDataInfoDocumentMapper: IDataInfoDocumentMapper<ApiDocument> = {
  toDomainDocument: (apiDocument) => {
    return {
      id: apiDocument?.id?.toString() ?? '',
      dataId: apiDocument?.uwi ?? '',
      dataType: '',
      isPrivate: false, // private docs will be coming from Stack
      name: apiDocument.file_name,
      folder: apiDocument.folder,
      url: apiDocument.fileKey ? apiDocument.fileKey : apiDocument.url,
      createdAt: apiDocument.created_at,
      createdBy: {
        email: apiDocument.user.email,
        name: {
          firstName: apiDocument.user.first_name,
          lastName: apiDocument.user.last_name,
        },
      },
    };
  },
  toTargetDocument: (document) => {
    return {
      file_name: document.name,
      url: document.url,
      object_id: document.dataId,
    };
  },
};
