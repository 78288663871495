import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { ProductionChartMode } from '@pn/services/charts/types';
import { startCase } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    // flex: 1,
    // width: '100%',
  },
  accordion: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  accordionDetails: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
  },
}));

type Props = {
  accordion?: boolean;
  disabled: boolean;
  mode: ProductionChartMode;
  onChange: (newMode: ProductionChartMode) => void;
};

export function ChartModeSelector({
  accordion = false,
  disabled,
  mode,
  onChange,
}: Props) {
  const { classes } = useStyles();

  const form = (
    <FormControl disabled={disabled}>
      <RadioGroup
        name="chart-mode-selector"
        value={mode}
        onChange={(_event, newMode) => onChange(newMode as ProductionChartMode)}
      >
        {Object.entries(ProductionChartMode).map(([key, value]) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio />}
            label={startCase(key)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );

  if (accordion) {
    return (
      <Paper className={classes.container} variant="outlined">
        <Accordion className={classes.accordion} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Production mode</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {form}
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  } else {
    return (
      <Paper
        component={Box}
        className={classes.container}
        variant="outlined"
        px={2}
        py={1}
      >
        {form}
      </Paper>
    );
  }
}
