import {
  useCurrentUserStorage,
  workspaceActions,
} from '@pn/core/storage';
import { Timer } from '@pn/core/utils/Timer';
import { apiAnnotationProvider } from '@pn/services/api/annotation/apiAnnotationProvider';
import { apiLayerProvider } from '@pn/services/api/layer/apiLayerProvider';
import { apiListProvider } from '@pn/services/api/list/apiListProvider';
import React from 'react';

export function useAutoGetWorkspaceItems() {
  const { isUserLoaded } = useCurrentUserStorage();

  React.useEffect(() => {
    if (!isUserLoaded) return;

    (async () => {
      Timer.read('init', '\n[start] getWorkspaceItems');

      workspaceActions().updateIsFetching(true);

      const [
        layerWorkspaceItems,
        listWorkspaceItems,
        annotationWorkspaceItems,
      ] = await Promise.all([
        apiLayerProvider.getLayerWorkspaceItems(),
        apiListProvider.getListWorkspaceItems(),
        apiAnnotationProvider.getAnnotationWorkspaceItems(),
      ]);

      workspaceActions().updateIsFetching(false);
      workspaceActions().add(
        [
          ...layerWorkspaceItems,
          ...listWorkspaceItems,
          ...annotationWorkspaceItems,
        ],
        false // do not overwrite existing items (e.g. the ones initialized from URL)
      );

      Timer.read('init', '\n[done] getWorkspaceItems');
    })();
  }, [isUserLoaded]);
}
