import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { DEFAULT_COLOR_HEX } from '@pn/services/styles';
import { CustomButton } from '@pn/ui/custom-components/CustomButton';
import { DebouncedSlider } from '@pn/ui/custom-components/DebouncedSlider';
import {
  ColorSelector,
  LARGE_SQ_GAP,
  LARGE_SQ_SIZE,
} from '@pn/ui/custom-components/color-picker/ColorSelector';
import { generateSliderMarks } from '@pn/ui/utils';
import Color from 'color';
import { isNil, isString } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  standardContainer: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
  },
  bottomContainer: {
    display: 'flex',
    gap: LARGE_SQ_SIZE + LARGE_SQ_GAP * 2,
  },
  resetButton: {
    flexShrink: 0,
    height: 30,
    width: LARGE_SQ_SIZE * 4 + LARGE_SQ_GAP * 3,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(12),
  },
  slider: {
    marginRight: theme.spacing(1.5),
    marginBottom: 0,
    '& .MuiSlider-markLabel': {
      top: 25,
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
  },
  disabledText: {
    color: theme.palette.text.disabled,
  },
}));

type Props = {
  disabled?: boolean;
  color: unknown;
  defaultColor: unknown;
  title?: string;
  variant?: 'standard' | 'compact';
  showAlphaSlider?: boolean;
  onChange: (value: unknown) => void;
};

export const ColorPicker = ({
  disabled = false,
  color,
  defaultColor,
  variant = 'standard',
  title,
  showAlphaSlider = false,
  onChange,
}: Props) => {
  const isValidColor = isString(color) && color !== 'transparent';
  const isColorSelectorDisabled = disabled || color === 'transparent';

  const alpha = isValidColor ? Color(color).alpha() : 1;

  const { classes } = useStyles();

  switch (variant) {
    case 'standard':
      return (
        <Box className={classes.standardContainer}>
          <ColorSelector
            variant="standard"
            disabled={isColorSelectorDisabled}
            colors={colors}
            color={isValidColor ? color : '#000000'}
            onChange={onChange}
          />

          <Box className={classes.bottomContainer} mt={LARGE_SQ_GAP * 2 + 'px'}>
            <CustomButton
              size="small"
              className={classes.resetButton}
              disabled={disabled}
              onClick={() => onChange(defaultColor)}
            >
              Reset to default
            </CustomButton>
            {showAlphaSlider && isValidColor && (
              <DebouncedSlider
                aria-labelledby="alpha-slider"
                getAriaValueText={(value) => value.toString()}
                className={classes.slider}
                size="small"
                step={0.1}
                min={0.0}
                max={1.0}
                marks={generateSliderMarks({
                  step: 0.1,
                  min: 0,
                  max: 1,
                  labels: [
                    {
                      value: 0,
                      label: '0%',
                    },
                    {
                      value: 0.5,
                      label: '50%',
                    },
                    {
                      value: 1,
                      label: '100%',
                    },
                  ],
                })}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => `${value * 100}%`}
                disabled={disabled}
                value={alpha}
                onChange={(_e, alpha) =>
                  onChange(
                    Color(color)
                      .alpha(alpha as number)
                      .rgb()
                      .string()
                  )
                }
              />
            )}
          </Box>
        </Box>
      );
    case 'compact':
      return (
        <Box>
          {!isNil(title) && <Typography variant="caption">{title}</Typography>}
          <ColorSelector
            variant="compact"
            disabled={isColorSelectorDisabled}
            colors={colors}
            color={isValidColor ? color : '#000000'}
            onChange={onChange}
          />
        </Box>
      );
    default:
      throw new Error('Invalid ColorPicker variant');
  }
};

const colors = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFEB3B',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#795548',
  '#607D8B',
  DEFAULT_COLOR_HEX, // NB check border styling before modifying!
  '#FFFFFF', // NB check border styling before modifying!
];
