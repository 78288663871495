import {
  isGeoJSONPosition,
  isValidLonLat,
  type GeoJSONCoordinates,
} from '@pn/core/utils/geospatial';
import { hasKey, hasKeyWithType } from '@pn/core/utils/logic';
import { distance, point } from '@turf/turf';
import { isArray, isObject } from 'lodash-es';

export type GeoPoint = {
  lat: number;
  lon: number;
};
export function isGeoPoint(geoPoint: unknown): geoPoint is GeoPoint {
  return (
    isObject(geoPoint) && hasKey(geoPoint, 'lat') && hasKey(geoPoint, 'lon')
  );
}

export function toGeoPoint(lat: number, lon: number): GeoPoint {
  return {
    lat,
    lon,
  };
}

export enum GeoShapeType {
  Point = 'Point',
  MultiPoint = 'MultiPoint',
  Line = 'Line',
  MultiLine = 'MultiLine',
  Polygon = 'Polygon',
  MultiPolygon = 'MultiPolygon',
}
function isGeoShapeType(arg: unknown): arg is GeoShapeType {
  return [
    GeoShapeType.Point,
    GeoShapeType.MultiPoint,
    GeoShapeType.Line,
    GeoShapeType.MultiLine,
    GeoShapeType.Polygon,
    GeoShapeType.MultiPolygon,
  ].includes(arg as GeoShapeType);
}

export type GeoPointsCollection = GeoPoint | GeoPointsCollection[];
export type GeoShape = {
  type: GeoShapeType;
  shape: GeoPointsCollection;
};
export function isGeoShape(arg: unknown): arg is GeoShape {
  return (
    isObject(arg) &&
    hasKeyWithType(arg, 'type', isGeoShapeType) &&
    hasKeyWithType(arg, 'shape', isArrayOrObject)
  );
}

const isArrayOrObject = (arg: unknown): arg is object | unknown[] =>
  isArray(arg) || isObject(arg);

export function toGeometryType(
  geoShapeType: GeoShapeType
): GeoJSON.GeoJsonGeometryTypes {
  switch (geoShapeType) {
    case GeoShapeType.Point:
      return 'Point';
    case GeoShapeType.MultiPoint:
      return 'MultiPoint';
    case GeoShapeType.Line:
      return 'LineString';
    case GeoShapeType.MultiLine:
      return 'MultiLineString';
    case GeoShapeType.Polygon:
      return 'Polygon';
    case GeoShapeType.MultiPolygon:
      return 'MultiPolygon';
    default:
      throw new Error(`Invalid GeoShape type: ${geoShapeType}`);
  }
}

export function toGeoShapeType(
  geometryType: GeoJSON.GeoJsonGeometryTypes
): GeoShapeType {
  switch (geometryType) {
    case 'Point':
      return GeoShapeType.Point;
    case 'MultiPoint':
      return GeoShapeType.MultiPoint;
    case 'LineString':
      return GeoShapeType.Line;
    case 'MultiLineString':
      return GeoShapeType.MultiLine;
    case 'Polygon':
      return GeoShapeType.Polygon;
    case 'MultiPolygon':
      return GeoShapeType.MultiPolygon;
    default:
      throw new Error(`Unsupported geometry type: ${geometryType}`);
  }
}

export function geometryToGeoShape(geometry: GeoJSON.Geometry): GeoShape {
  const convertCoordinatesRecursive = (
    coordinates: GeoJSONCoordinates
  ): GeoPointsCollection => {
    if (isGeoJSONPosition(coordinates)) {
      return toGeoPoint(coordinates[1], coordinates[0]);
    } else {
      return coordinates.map((el) =>
        convertCoordinatesRecursive(el)
      ) as GeoPointsCollection;
    }
  };

  if (geometry.type === 'GeometryCollection') {
    throw new Error('GeometryCollection cannot be converted to GeoShape');
  }

  return {
    type: toGeoShapeType(geometry.type),
    shape: convertCoordinatesRecursive(geometry.coordinates),
  };
}

export function geoShapeToGeometry(geoShape: GeoShape): GeoJSON.Geometry {
  const convertGeoPointsRecursive = (
    param: GeoPointsCollection
  ): GeoJSONCoordinates => {
    if (isGeoPoint(param)) {
      if (!isValidLonLat([param.lon, param.lat])) {
        throw new Error(`Invalid GeoPoint: [${param.lat}, ${param.lon}]`);
      }
      return [param.lon, param.lat];
    } else if (isArray(param)) {
      return param.map((el) =>
        convertGeoPointsRecursive(el)
      ) as GeoJSONCoordinates;
    } else {
      console.error(param);
      throw new Error('Invalid GeoPointsCollection');
    }
  };

  return {
    type: toGeometryType(geoShape.type),
    coordinates: convertGeoPointsRecursive(geoShape.shape),
  } as GeoJSON.Geometry;
}

export function getCenterPoint(geoShape: GeoShape): GeoPoint {
  return getMiddlePoint(flattenShape(geoShape.shape));
}

function flattenShape(shape: GeoPointsCollection): GeoPoint[] {
  if (isGeoPoint(shape)) {
    return [shape];
  } else {
    return shape.flatMap((el) => flattenShape(el));
  }
}

function getMiddlePoint(geoPoints: GeoPoint[]): GeoPoint {
  const [minLon, minLat] = geoPoints.reduce(
    ([minLon, minLat], geoPoint) => {
      return [Math.min(minLon, geoPoint.lon), Math.min(minLat, geoPoint.lat)];
    },
    [180, 90]
  );

  const [maxLon, maxLat] = geoPoints.reduce(
    ([maxLon, maxLat], geoPoint) => {
      return [Math.max(maxLon, geoPoint.lon), Math.max(maxLat, geoPoint.lat)];
    },
    [-180, -90]
  );

  return toGeoPoint((minLat + maxLat) / 2, (minLon + maxLon) / 2);
}

export type GeoBoundingBox = {
  southWest: GeoPoint; // bottomLeft
  northEast: GeoPoint; // topRight
};

function isArrayOfGeoPoints(arg: unknown): arg is GeoPoint[] {
  return isArray(arg) && arg.every(isGeoPoint);
}

function isArrayOfGeoShapes(arg: unknown): arg is GeoShape[] {
  return isArray(arg) && arg.every(isGeoShape);
}

export function getPointsApproximation(arg: GeoPointsCollection): GeoPoint[] {
  if (isGeoPoint(arg)) {
    return [arg];
  } else if (isArrayOfGeoPoints(arg)) {
    if (arg.length === 1) {
      return arg;
    } else {
      return [arg[0], arg[arg.length - 1]];
    }
  } else if (isArray(arg)) {
    return arg.reduce<GeoPoint[]>((geoPoints, geoPointsCollection) => {
      const newPoints = getPointsApproximation(geoPointsCollection);
      Array.prototype.push.apply(geoPoints, newPoints);
      return geoPoints;
    }, []);
  } else {
    throw new Error('Invalid argument');
  }
}

export function getBoundingBox(arg: GeoPoint[] | GeoShape[]): GeoBoundingBox {
  let minLon = 180,
    minLat = 90,
    maxLon = -180,
    maxLat = -90;

  if (isArrayOfGeoPoints(arg)) {
    for (const point of arg) {
      minLon = Math.min(minLon, point.lon);
      minLat = Math.min(minLat, point.lat);
      maxLon = Math.max(maxLon, point.lon);
      maxLat = Math.max(maxLat, point.lat);
    }
  } else if (isArrayOfGeoShapes(arg)) {
    for (const shape of arg) {
      for (const point of flattenShape(shape.shape)) {
        minLon = Math.min(minLon, point.lon);
        minLat = Math.min(minLat, point.lat);
        maxLon = Math.max(maxLon, point.lon);
        maxLat = Math.max(maxLat, point.lat);
      }
    }
  } else {
    throw new Error('Invalid argument');
  }

  return {
    southWest: { lat: minLat, lon: minLon },
    northEast: { lat: maxLat, lon: maxLon },
  };
}

/**
 * @returns distance in meters
 */
export function getDistanceBetweenTwoPoints(
  pointA: GeoPoint,
  pointB: GeoPoint
): number {
  return distance(
    point([pointA.lon, pointA.lat]),
    point([pointB.lon, pointB.lat]),
    {
      units: 'meters',
    }
  );
}
