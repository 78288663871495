import { Box } from '@mui/material';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { useDataSelection } from '@pn/core/operations/dataSelection/useDataSelection';
import { createList } from '@pn/core/operations/workspace/crud/createList';
import {
  useCurrentUserStorage,
  useProjectsStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import {
  isBottomTableShown,
  isEmbedded,
} from '@pn/core/utils/embedded';
import { isFreeCordova } from '@pn/core/utils/env';
import { DEFAULT_LIST_COLOR } from '@pn/services/styles/list';
import { useAnnotations } from '@pn/ui/annotations/AnnotationProvider';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { Library } from '@pn/ui/workspace/Library';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelStateProvider';
import clsx from 'clsx';
import { isNil } from 'lodash-es';
import React from 'react';
import { BottomTable } from 'src/web-ui/bottom-table';
import { Splash } from 'src/web-ui/components/Splash';
import { EmbeddedEffects, MainEffects } from 'src/web-ui/effects';
import { Header } from 'src/web-ui/layout/Header';
import { MainPanel } from 'src/web-ui/main-panel/MainPanel';
import { MainTray } from 'src/web-ui/main-panel/MainTray';
import { ExternalPanel } from 'src/web-ui/main-panel/components/ExternalPanel';
import { Portals } from 'src/web-ui/main-panel/portals/Portals';
import { Map } from 'src/web-ui/map/Map';
import { AnnotationEditor } from 'src/web-ui/map/addons/AnnotationEditor';
import { ProjectDialog } from 'src/web-ui/project/ProjectDialog';
import { useProjectDialog } from 'src/web-ui/project/ProjectDialogProvider';
import { Workspace } from 'src/web-ui/workspace';
import { WorkspaceItemPanel } from 'src/web-ui/workspace/WorkspaceItemPanel';
import { InvalidIdsDialog } from 'src/web-ui/workspace/components/InvalidIdsDialog';
import {
  UploadLayerDialog,
  useUploadLayerDialog,
} from 'src/web-ui/workspace/components/UploadLayerDialog';
import { zIndex } from 'src/web-ui/zIndex';
import { makeStyles } from 'tss-react/mui';

export const MAIN_TRAY_WIDTH = 56 + 12 * 2;
export const MENU_DRAWER_WIDTH = 250;
export const WORKSPACE_DRAWER_WIDTH = 300;

export const BOTTOM_TABLE_HEIGHT = isEmbedded() ? 358 - 40 : 358; // (5 rows OR 4 rows + header filters) + scrollbar

const useStyles = makeStyles()((theme) => ({
  main: {
    display: 'grid',
    gridTemplateAreas: `
      "header workspace"
      "map workspace"
      "bottom-table workspace"
    `,
    gridTemplateRows: 'min-content 1fr min-content',
    gridTemplateColumns: '1fr min-content',
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      gridTemplateAreas: `
        "header"
        "map"
      `,
    },
  },
  mapContainer: {
    gridArea: 'map',
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    zIndex: zIndex(theme).map,
  },
  bottomTableContainer: {
    gridArea: 'bottom-table',
    position: 'relative',
    height: BOTTOM_TABLE_HEIGHT,
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
    zIndex: zIndex(theme).map,
    [`@media (max-height: 600px)`]: {
      height: 205, // single row with no scrollbar
    },
  },
  bottomTableContainerShift: {
    marginLeft: `${MAIN_TRAY_WIDTH}px`,
  },
}));

export const Main = () => {
  const { classes } = useStyles();
  const { xsScreen, smScreen } = useScreenSize();

  const { user, isStackUser } = useCurrentUserStorage();
  const {
    isFetching,
    isFetchingStack,
    allWorkspaceItems,
    idsInWorkspace,
    workspaceItemSelected,
  } = useWorkspaceStorage();
  const { projects } = useProjectsStorage();

  const { liveItemOpened, openWorkspaceItemPanel } = useWorkspaceItemPanel();
  const { openProjectDialog } = useProjectDialog();
  const { isAnnotationInterfaceOpen, openAnnotationInterface } =
    useAnnotations();
  const {
    isUploadLayerDialogOpen,
    openUploadLayerDialog,
    closeUploadLayerDialog,
  } = useUploadLayerDialog();

  const [_isWorkspaceOpen, setWorkspaceOpen] = React.useState(false);
  const isWorkspaceOpen = _isWorkspaceOpen || !smScreen;

  // const isSelected = !isNil(dataItemSelected); // page_with_id
  // const isAboutToBeSelected =
  //   !isNil(dataItemRequested.id) &&
  //   workspaceItemSelected?.query.pageWithId === dataItemRequested.id; // page_with_id
  // const isSelectable = isSelected || isAboutToBeSelected; // page_with_id
  const isSelectable = useDataSelection().isSelectable;

  /**
   * Main Tray is open when either a data item is selected or will be selected
   * by the end of the current mapData request.
   */
  const isMainTrayOpen = isSelectable && !isAnnotationInterfaceOpen;
  const isQuickInfoOpen = xsScreen && isMainTrayOpen;

  const handleOpenWorkspaceItemPanel = React.useCallback(
    (item: WorkspaceItem) => {
      openWorkspaceItemPanel({ item, openAnnotationInterface });
    },
    [openWorkspaceItemPanel, openAnnotationInterface]
  );

  const handleToggleItem = React.useCallback(
    (itemId: WorkspaceItem['id'], checked: boolean) => {
      if (checked) {
        workspaceActions().addToWorkspace(itemId);
      } else {
        workspaceActions().removeFromWorkspace(itemId);
      }
    },
    []
  );

  const handleCreateList = React.useCallback(() => {
    if (isNil(user)) return;
    
    const newItem = createList({ user, color: DEFAULT_LIST_COLOR });

    handleOpenWorkspaceItemPanel(newItem);
  }, [user, handleOpenWorkspaceItemPanel]);

  if (isEmbedded()) {
    return (
      <Box className={classes.main}>
        <Header />

        <Box className={classes.mapContainer}>
          <Map />
        </Box>

        {isBottomTableShown() && !smScreen && (
          <Box
            id="bottom-table-container"
            className={clsx(classes.bottomTableContainer, {
              [classes.bottomTableContainerShift]: isMainTrayOpen,
            })}
          >
            <BottomTable />
          </Box>
        )}

        <EmbeddedEffects />
      </Box>
    );
  }

  return (
    <Box className={classes.main}>
      <Header
        showSearchbar
        isWorkspaceOpen={isWorkspaceOpen}
        setWorkspaceOpen={setWorkspaceOpen}
      />

      {!isNil(workspaceItemSelected) && (
        <>
          <MainTray
            isMainTrayOpen={isMainTrayOpen}
            item={workspaceItemSelected}
          />
          <MainPanel
            isMainTrayOpen={isMainTrayOpen}
            item={workspaceItemSelected}
          />
        </>
      )}

      <Box className={classes.mapContainer}>
        <Map
          isMainTrayOpen={isMainTrayOpen}
          isQuickInfoOpen={isQuickInfoOpen}
        />
      </Box>

      {!isFreeCordova(user) && !smScreen && (
        <Box
          id="bottom-table-container"
          className={clsx(classes.bottomTableContainer, {
            [classes.bottomTableContainerShift]: isMainTrayOpen,
          })}
        >
          <BottomTable />
        </Box>
      )}

      <Library
        isFetching={isFetching}
        isFetchingStack={isFetchingStack}
        isStackUser={isStackUser}
        projects={projects}
        allWorkspaceItems={allWorkspaceItems}
        idsInWorkspace={idsInWorkspace}
        onToggleItem={handleToggleItem}
        onOpenItemDialog={handleOpenWorkspaceItemPanel}
        onCreateList={handleCreateList}
        onOpenProjectDialog={openProjectDialog}
        onUploadLayer={openUploadLayerDialog}
      />
      <Workspace open={isWorkspaceOpen} setWorkspaceOpen={setWorkspaceOpen} />

      {!isNil(liveItemOpened) && <WorkspaceItemPanel item={liveItemOpened} />}
      <ExternalPanel />
      <InvalidIdsDialog />
      <ProjectDialog />
      <UploadLayerDialog
        open={isUploadLayerDialogOpen}
        onClose={closeUploadLayerDialog}
        onOpenItemDialog={handleOpenWorkspaceItemPanel}
      />
      <AnnotationEditor />
      <Portals />

      <Splash />

      <MainEffects />
    </Box>
  );
};
