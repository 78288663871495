import {
  getProductionGroupings,
  type GroupedProductionItem,
} from '@pn/core/domain/production';
import { isNil, uniq } from 'lodash-es';
import type { IGroupedProductionItemsMapper } from './ports';

export const groupedProductionItemsMapper: IGroupedProductionItemsMapper = {
  toGroupedProductionItems: (productionItems, mode) => {
    const dates = uniq(productionItems.map((item) => item.date));
    const productionGroupings = getProductionGroupings(productionItems, mode);

    return dates.map((date) => {
      const itemsOnDate = productionItems.filter((item) => item.date === date);

      const productionItem = itemsOnDate.find(
        (item) => item.fluidType === 'production'
      );
      const injectionItem = itemsOnDate.find(
        (item) => item.fluidType === 'injection'
      );

      const groupedProductionItem: GroupedProductionItem = {
        mode,
        date,
        hours:
          isNil(productionItem?.hours) && isNil(injectionItem?.hours)
            ? undefined
            : [productionItem, injectionItem].reduce(
                (acc, item) => acc + (item?.hours ?? 0),
                0
              ),
        count: itemsOnDate[0].count,
      };

      productionGroupings.forEach((grouping) => {
        const groupingItem = itemsOnDate.find(
          (item) => item.productionGrouping === grouping.label
        );

        if (groupingItem) {
          groupedProductionItem[grouping.label] = groupingItem[mode];
        } else {
          // groupedProductionItem[grouping.label] = {
          //   value: undefined,
          //   symbol: grouping.symbol,
          // };
        }
      });

      /* Move hours to the end of the object */
      const { hours, ...rest } = groupedProductionItem;
      return { ...rest, hours };
    });
  },
};
