import { Box } from '@mui/material';
import { DynamicLink } from '@pn/ui/custom-components/DynamicLink';
import React from 'react';
import { MAIN_TRAY_WIDTH } from 'src/web-ui/Main';
import { makeStyles } from 'tss-react/mui';

const LEFT_OFFSET = 104; // in px

const useStyles = makeStyles<{ isMainTrayOpen: boolean }>()(
  (theme, { isMainTrayOpen }) => ({
    container: {
      position: 'absolute',
      left: LEFT_OFFSET,
      bottom: 0,
      padding: '0px 5px',
      lineHeight: '20px', // matches Mapbox style
      fontSize: 12, // matches Mapbox style
      fontFamily: '"Helvetica Neue", Arial, Helvetica, sans-serif', // matches Mapbox style
      color: 'rgba(0, 0, 0, 0.75)', // matches Mapbox style
      backgroundColor: 'rgba(255, 255, 255, 0.5)', // matches Mapbox style
    },
    containerShift: {
      left: LEFT_OFFSET + (isMainTrayOpen ? MAIN_TRAY_WIDTH : 0),
    },
    link: {
      color: theme.palette.info.dark,
    },
  })
);

type Props = {
  isMainTrayOpen: boolean;
};

export const MapTerms = ({ isMainTrayOpen }: Props) => {
  const { classes, cx } = useStyles({ isMainTrayOpen });

  return (
    <Box
      className={cx(classes.container, {
        [classes.containerShift]: isMainTrayOpen,
      })}
    >
      <span>&copy; Petro Ninja </span>
      <DynamicLink link="/terms" target="_blank" className={classes.link}>
        Terms
      </DynamicLink>
      <span> | </span>
      <DynamicLink link="/privacy" target="_blank" className={classes.link}>
        Privacy
      </DynamicLink>
    </Box>
  );
};
