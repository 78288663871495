import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  type DragEndEvent,
  type DragMoveEvent,
  type DragOverEvent,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove } from '@dnd-kit/sortable';
import { Box } from '@mui/material';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { moveWorkspaceItem } from '@pn/core/operations/workspace';
import {
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { findOrThrow } from '@pn/core/utils/logic';
import { restrictToVerticalContainer } from '@pn/services/utils/dnd';
import { isNil } from 'lodash-es';
import React from 'react';
import { WORKSPACE_DRAWER_WIDTH } from 'src/web-ui/Main';
import { WorkspaceAccessAlert } from 'src/web-ui/workspace/WorkspaceAccessAlert';
import { WorkspaceContent } from 'src/web-ui/workspace/WorkspaceContent';
import { WorkspaceControls } from 'src/web-ui/workspace/WorkspaceControls';
import { WorkspaceLibraryControls } from 'src/web-ui/workspace/WorkspaceLibraryControls';
import { WorkspaceQuickBrowser } from 'src/web-ui/workspace/WorkspaceQuickBrowser';
import { TRASH_BIN_ID } from 'src/web-ui/workspace/WorkspaceTrashBin';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'grid',
    gridTemplateAreas: `
      "access-alert"
      "workspace-controls"
      "workspace-content"
      "quick-browser"
      "button-container"
    `,
    gridTemplateRows: 'min-content min-content 1fr auto min-content',
    height: '100%',
    width: WORKSPACE_DRAWER_WIDTH, // CSS fix for 1px width difference when toggling the Library on/off
  },
}));

export const WorkspaceContainer = () => {
  const { classes } = useStyles();

  const { workspaceItems } = useWorkspaceStorage();

  const [internalIds, setInternalIds] = React.useState<string[]>([]);
  React.useLayoutEffect(() => {
    setInternalIds(
      workspaceItems
        .map(({ id }) => id)
        .slice()
        .reverse()
    );
  }, [workspaceItems]);

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  function handleDragStart(_event: DragMoveEvent) {}

  function handleDragOver(event: DragOverEvent) {
    const { active, over } = event;

    event.activatorEvent.stopPropagation(); // prevents SwipeableDrawer from closing during the drag

    if (over && over.id === TRASH_BIN_ID) {
      setInternalIds(internalIds.filter((id) => id !== active.id));
      return;
    }

    if (over && active.id !== over.id) {
      if (!internalIds.includes(active.id as string)) {
        setInternalIds([active.id as string, ...internalIds]);
        return;
      }

      const oldIndex = internalIds.indexOf(active.id as string);
      const newIndex = internalIds.indexOf(over.id as string);

      const newIds = arrayMove(internalIds, oldIndex, newIndex);

      setInternalIds(newIds);
    }
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    const activeItem = findOrThrow(
      workspaceItems,
      (item) => item.id === active.id
    );

    const beforeItem = findNextVisibleItem({
      internalIds,
      index: internalIds.indexOf(activeItem.id),
      workspaceItems,
    });

    if (over?.id === TRASH_BIN_ID) {
      if (activeItem.isTemporary) {
        workspaceActions().remove(activeItem);
      } else {
        workspaceActions().removeFromWorkspace(activeItem.id);
      }

      return;
    }

    workspaceActions().setWorkspace(internalIds.slice().reverse(), true);
    moveWorkspaceItem(activeItem, beforeItem);
  }

  return (
    <Box className={classes.container}>
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis, restrictToVerticalContainer]}
        onDragMove={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragEnd}
      >
        <WorkspaceAccessAlert />
        <WorkspaceControls />
        <WorkspaceContent internalIds={internalIds} />
        <WorkspaceQuickBrowser />
        <WorkspaceLibraryControls />
      </DndContext>
    </Box>
  );
};

function findNextVisibleItem(params: {
  internalIds: string[];
  index: number;
  workspaceItems: WorkspaceItem[];
}): WorkspaceItem | undefined {
  const { internalIds, index, workspaceItems } = params;

  const nextId = internalIds[index - 1];
  if (isNil(nextId)) return undefined;

  const nextItem = workspaceItems.find((item) => item.id === nextId);
  if (isNil(nextItem)) return undefined;

  if (nextItem.isVisible) return nextItem;

  return findNextVisibleItem({ internalIds, index: index - 1, workspaceItems });
}
