import {
  pickMappingItemsFromFields,
  type MappingItem,
} from '@pn/core/domain/data';
import type { Filter } from '@pn/core/domain/query';
import type { UnitSystem } from '@pn/core/domain/types';
import type { IQueryMapper } from '@pn/core/mappers/query/ports';
import { findOrThrow } from '@pn/core/utils/logic';
import { inverseConvertValue } from '@pn/services/api/query/utils';
import assert from 'assert';
import { isNumber } from 'lodash-es';
import type { ParquetQuery } from './types';

export const apiParquetQueryMapper = (
  mapping: MappingItem[]
): IQueryMapper<ParquetQuery> => {
  return {
    toTargetQuery: (query) => {
      return {
        data_type: query.dataType,
        fields: pickMappingItemsFromFields(mapping, query.fields).map(
          (m) => m.field
        ),
        sorts: query.sorts,
        filters: query.filters.map((filter) => ({
          ...filter,
          value: toElasticFilterValue({
            mappingItem: findOrThrow(mapping, (m) => m.field === filter.field),
            fromValue: filter.value,
            unitSystem: filter.unitSystem,
          }),
        })),
        filters_link_operator: query.filtersLinkOperator,
        requested_ids: query.requestedIds,
        ...('page' in query && { page: query.page }),
        // ...('pageWithId' in query && { page_with_id: query.pageWithId }),
        ...('pageSize' in query && { page_size: query.pageSize }),
      };
    },
  };
};

function toElasticFilterValue(params: {
  mappingItem: MappingItem;
  fromValue: Filter['value'];
  unitSystem: UnitSystem | undefined;
}): Filter['value'] {
  const { unitSystem, mappingItem, fromValue } = params;

  switch (mappingItem.domainType) {
    case 'SIUnit': {
      assert(isNumber(fromValue), 'fromValue must be a number');
      assert(unitSystem, 'unitSystem must be defined');
      return inverseConvertValue(
        fromValue,
        mappingItem.domainTypeAttributes.symbol,
        unitSystem
      );
    }
    default:
      return fromValue;
  }
}
