import { Box } from '@mui/material';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import {
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { useLibrary } from '@pn/ui/workspace/LibraryStateProvider';
import React from 'react';
import { WORKSPACE_DRAWER_WIDTH } from 'src/web-ui/Main';
import { QuickBrowser } from 'src/web-ui/components/QuickBrowser';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    gridArea: 'quick-browser',
    width: WORKSPACE_DRAWER_WIDTH,
    height: '40vh', // % doesn't work with grid layout
    maxHeight: 288,
  },
  buttonContainer: {
    gridArea: 'button-container',
    display: 'flex',
    alignItems: 'center',
    height: 70,
    padding: theme.spacing(1.5),
    background: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  button: {
    textTransform: 'none',
  },
}));

export const WorkspaceQuickBrowser = () => {
  const { classes } = useStyles();

  const { idsInWorkspace } = useWorkspaceStorage();
  const { isLibraryOpen } = useLibrary();

  const handleAddItem = React.useCallback((itemId: WorkspaceItem['id']) => {
    workspaceActions().addToWorkspace(itemId);
    workspaceActions().select(itemId);
  }, []);

  if (isLibraryOpen) return null;

  return (
    <Box className={classes.container}>
      <QuickBrowser
        squareTop
        itemIdsToExclude={idsInWorkspace}
        onAddItem={handleAddItem}
      />
    </Box>
  );
};
