import { isString } from 'lodash-es';

export const pnDataTypes = [
  'wells',
  'wells_usa',
  'pipelines',
  'facilities',
  'mineral_rights',
  'land_postings',
  'land_results',
  'units',
  'pools',
  'dispositions',
  'dispositions_historical',
  'grids',
] as const;

export type PNDataType = (typeof pnDataTypes)[number];
type CustomDataType = string;

export type DataType = PNDataType | CustomDataType;

export function isPNDataType(arg: unknown): arg is PNDataType {
  return isString(arg) && pnDataTypes.includes(arg as PNDataType);
}

export function isCustomDataType(arg: unknown): arg is CustomDataType {
  return isString(arg) && !isPNDataType(arg);
}
