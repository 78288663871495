import type { DataItemId } from '@pn/core/domain/data';
import type { DataSelectionReason } from '@pn/core/domain/query';
import {
  isNonStreamableItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { getMapDataItems } from '@pn/core/operations/workspace/mapData';
import { workspaceActions } from '@pn/core/storage';

export function selectDataItem(params: {
  id: DataItemId;
  reason: DataSelectionReason;
  workspaceItem: WorkspaceItem;
  pageSize: number;
}): void {
  const { id, reason, workspaceItem, pageSize } = params;

  workspaceActions().setRequestedDataItemId(workspaceItem.id, id, reason);

  workspaceActions().addToWorkspace(workspaceItem.id);
  workspaceActions().select(workspaceItem.id);

  if (workspaceItem.tableDataItems.find(({ _id }) => _id === id)) {
    /**
     * Do nothing.
     */
  } else if (isNonStreamableItem(workspaceItem)) {
    workspaceActions().updateRequestedIds(workspaceItem.id, {
      ids: [id],
      reason,
    });
    workspaceActions().updatePage(workspaceItem.id, 0);
  } else {
    const mapDataItems = getMapDataItems(workspaceItem);
    const index = mapDataItems.findIndex(({ _id }) => _id === id); // can be -1 if called before table data was fetched

    workspaceActions().updatePage(
      workspaceItem.id,
      index === -1 ? 0 : Math.floor(index / pageSize)
    );

    // workspaceActions().updatePageWithId(workspaceItem.id, id); // page_with_id
  }
}
