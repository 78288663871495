import type { Theme } from '@mui/material';
import { MapMode } from '@pn/core/domain/map';
import { Hacker } from '@pn/ui/theme/themes/Hacker.ts';
import type { PNTheme } from './PetroNinjaThemeProvider';
import { BOEDark } from './themes/BOEDark';
import { BOELight } from './themes/BOELight';
import { Default } from './themes/Default';
import { PetroNight } from './themes/PetroNight';

declare module '@mui/material/styles' {
  interface Theme {
    borderColor: string;
    productionColors: {
      generalProduction: string;
      oilProduction: string;
      gasProduction: string;
      nglAndCondensateProduction: string;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    borderColor?: string;
    productionColors?: {
      generalProduction: string;
      oilProduction: string;
      gasProduction: string;
      nglAndCondensateProduction: string;
    };
  }
}

export const themes: Record<PNTheme, Theme> = {
  Default: Default,
  'Petro Night': PetroNight,
  'BOE Light': BOELight,
  'BOE Dark': BOEDark,
  Hacker: Hacker,
};

export const themeToMapMode: Record<PNTheme, MapMode> = {
  Default: MapMode.Standard,
  'Petro Night': MapMode.Light,
  'BOE Light': MapMode.Light,
  'BOE Dark': MapMode.Dark,
  Hacker: MapMode.Dark,
};
