import { isNil } from 'lodash-es';
import { IApiCompanyMapper } from '@pn/core/mappers/company/ports';
import { CompanyPurpose } from '@pn/core/domain/intel/company';

export const apiCompanyMapper: IApiCompanyMapper = {
  toDomainCompany: (apiIntelCompany) => {
    if (isNil(apiIntelCompany)) {
      throw new Error('apiIntel is not a valid object');
    }

    return {
      id: apiIntelCompany.id.toString(),
      name: apiIntelCompany.display_name,
      legalName: apiIntelCompany.main_legal_name,
      aliases: apiIntelCompany.search_string_list,
      website: apiIntelCompany.website_hostname,
      hasIntelData: apiIntelCompany.has_intel_data,
      isLandBroker: apiIntelCompany.is_land_broker,
      stockSymbol: apiIntelCompany.stock_symbol,
      isDataPublic: apiIntelCompany.is_data_public,
      notes: apiIntelCompany.notes,
      boeReportLink: apiIntelCompany.boe_report_link,
      isDefunct: apiIntelCompany.is_defunct,
      purpose: apiIntelCompany.company_purpose as CompanyPurpose,
    };
  },
  toTargetCompany: (domainCompany) => {
    if (isNil(domainCompany)) {
      throw new Error('domainCompany is not a valid object');
    }

    return {
      id: domainCompany.id,
      display_name: domainCompany.name,
      main_legal_name: domainCompany.legalName,
      search_string_list: domainCompany.aliases,
      website_hostname: domainCompany.website
        ? domainCompany.website
        : undefined,
      has_intel_data: domainCompany.hasIntelData,
      stock_symbol: domainCompany.stockSymbol,
      is_land_broker: domainCompany.isLandBroker,
      is_data_public: domainCompany.isDataPublic,
      notes: domainCompany.notes ? domainCompany.notes : null,
      boe_report_link: domainCompany.boeReportLink,
      is_defunct: domainCompany.isDefunct,
      company_purpose: domainCompany.purpose,
    };
  },
};
