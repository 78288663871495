import {
  isGeoPoint,
  type GeoPoint,
  type GeoPointsCollection,
  type GeoShape,
} from '@pn/core/domain/geography';
import type { Point } from '@pn/core/domain/types';
import { hasKey, hasKeyWithType } from '@pn/core/utils/logic';
import { area } from '@turf/turf';
import { isArray, isNumber, isObject, isString, maxBy, minBy } from 'lodash-es';
import Long from 'long';

export type GeoJSONCoordinates = GeoJSON.Position | GeoJSONCoordinates[];

export function isGeoJSONGeometry(arg: unknown): arg is GeoJSON.Geometry {
  return (
    isObject(arg) &&
    hasKeyWithType(arg, 'type', isString) &&
    [
      'Point',
      'MultiPoint',
      'LineString',
      'MultiLineString',
      'Polygon',
      'MultiPolygon',
      'GeometryCollection',
    ].includes(arg.type) &&
    hasKeyWithType(arg, 'coordinates', isArray)
  );
}

export function isGeoJSONFeatureCollection(
  arg: unknown
): arg is GeoJSON.FeatureCollection {
  return (
    isObject(arg) &&
    hasKey(arg, 'type') &&
    arg.type === 'FeatureCollection' &&
    hasKeyWithType(arg, 'features', isArray)
  );
}

export function getGeometriesAreaInKm2(geometries: GeoJSON.Geometry[]) {
  return geometries.reduce((acc, geometry) => {
    if (geometry.type === 'Polygon' || geometry.type === 'MultiPolygon') {
      const areaInSquareMeters = area(geometry);
      const areaInSquareKilometers = areaInSquareMeters / 1e6;
      return acc + areaInSquareKilometers;
    } else {
      console.warn('Unsupported geometry type:', geometry.type);
      return acc;
    }
  }, 0);
}

export function getBboxAroundPoint(
  point: Point,
  radius: number
): [Point, Point] {
  return [
    {
      x: point.x - radius,
      y: point.y - radius,
    },
    {
      x: point.x + radius,
      y: point.y + radius,
    },
  ];
}

export function shrinkBbox(bbox: [Point, Point]): [Point, Point] {
  const SHRINK_FACTOR = 5; // in px

  const minX = minBy(bbox, (pt) => pt.x)!.x;
  const minY = minBy(bbox, (pt) => pt.y)!.y;
  const maxX = maxBy(bbox, (pt) => pt.x)!.x;
  const maxY = maxBy(bbox, (pt) => pt.y)!.y;

  const pt1 = { x: minX + SHRINK_FACTOR, y: minY + SHRINK_FACTOR };
  const pt2 = { x: maxX - SHRINK_FACTOR, y: maxY - SHRINK_FACTOR };

  return [pt1, pt2];
}

/**
 * Recursively applies tippecanoe-like transformations to GeoPoints that comprise
 * the GeoShape. This is necessary to render on-the-fly GeoJSON features directly
 * on top of their Mapbox counterparts and stop them from "jumping".
 */
export function simplifyGeoShape(geoShape: GeoShape): GeoShape {
  const simplifyRecursive = (
    param: GeoPointsCollection
  ): GeoPointsCollection => {
    if (isGeoPoint(param)) {
      // return param;
      return simplifyGeoPoint(param);
    } else {
      return param.map((el) => simplifyRecursive(el));
    }
  };

  return {
    type: geoShape.type,
    shape: simplifyRecursive(geoShape.shape),
  };
}

// https://github.com/mapbox/tippecanoe/issues/888
function simplifyGeoPoint(geoPoint: GeoPoint, zoom = 22): GeoPoint {
  // console.log('lon, lat', lon, lat);

  const { ll_x, ll_y } = lonlat2tile(geoPoint.lon, geoPoint.lat, zoom);
  const [_lon, _lat] = tile2lonlat(ll_x, ll_y, zoom);
  // console.log('_lon, _lat', _lon, _lat);

  return {
    lat: _lat,
    lon: _lon,
  };
}

function lonlat2tile(lon: number, lat: number, zoom: number) {
  const lat_rad = (lat * Math.PI) / 180;
  // unsigned long long n = 1LL << zoom;
  const ll_n = new Long(0x1, 0x0, true).shiftLeft(zoom);

  // long long llx = n * ((lon + 180) / 360);
  const ll_x = Long.fromInt(ll_n.toNumber() * ((lon + 180) / 360));
  // long long lly = n * (1 - (log(tan(lat_rad) + 1 / cos(lat_rad)) / M_PI)) / 2;
  const ll_y = Long.fromInt(
    ll_n.toNumber() *
      ((1 - Math.log(Math.tan(lat_rad) + 1 / Math.cos(lat_rad)) / Math.PI) / 2)
  );

  return { ll_x, ll_y };
}

function tile2lonlat(ll_x: Long.Long, ll_y: Long.Long, zoom: number) {
  // unsigned long long n = 1LL << zoom;
  const ll_n = new Long(0x1, 0x0, true).shiftLeft(zoom);

  // *lon = 360.0 * x / n - 180.0;
  const lon = (360.0 * ll_x.toNumber()) / ll_n.toNumber() - 180.0;
  // *lat = atan(sinh(M_PI * (1 - 2.0 * y / n))) * 180.0 / M_PI;
  const lat =
    (Math.atan(
      Math.sinh(Math.PI * (1 - (2.0 * ll_y.toNumber()) / ll_n.toNumber()))
    ) *
      180.0) /
    Math.PI;

  return [lon, lat];
}

export const isLongitude = (num: unknown): boolean =>
  isNumber(num) && isFinite(num) && Math.abs(num) <= 180;
export const isLatitude = (num: unknown): boolean =>
  isNumber(num) && isFinite(num) && Math.abs(num) <= 90;

export function isValidLonLat(param: unknown): param is [number, number] {
  if (!isArray(param) || param.length !== 2) {
    return false;
  }

  return isLongitude(param[0]) && isLatitude(param[1]);
}

/**
 * @returns true if parameter is of type [lon, lat] or [lon, lat, alt]
 */
export function isGeoJSONPosition(param: unknown): param is GeoJSON.Position {
  if (!isArray(param) || param.length < 2 || param.length > 3) {
    return false;
  }

  const isLon = isLongitude(param[0]);
  const isLat = isLatitude(param[1]);

  // If the first coordinate is a valid latitude but not a valid longitude
  // AND the second coordinate is a valid longitude but not a valid latitude,
  // then warn that it's possibly [lat, lon] format.
  if (!isLon && !isLat && isLatitude(param[0]) && isLongitude(param[1])) {
    console.error(
      'Expected coordinates in [lon, lat] format but received potential [lat, lon] format: ' +
        [param[0], param[1]].join(', ')
    );
  }

  return isLon && isLat;
}
