import { useDndContext } from '@dnd-kit/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {
  Box,
  Button,
  Collapse,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import type { Project } from '@pn/core/domain/project';
import { useRouter } from '@pn/core/operations/router';
import {
  projectsActions,
  useCurrentUserStorage,
  useProjectsStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { defaultWorkspace } from '@pn/core/storage/workspace/defaultWorkspaces';
import { isFreeCordova } from '@pn/core/utils/env';
import { arraysShareSameValues } from '@pn/core/utils/logic';
import { PermissionButton } from '@pn/ui/custom-components/permission/PermissionButton';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { isEmpty, isNil, last } from 'lodash-es';
import React from 'react';
import { useProjectDialog } from 'src/web-ui/project/ProjectDialogProvider';
import { WorkspaceTrashBin } from 'src/web-ui/workspace/WorkspaceTrashBin';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    gridArea: 'workspace-controls',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    minHeight: 64,
  },
  button: {
    textTransform: 'none',
  },
  projectsButton: {
    width: 175,
  },
  resetButton: {
    flexGrow: 1,
  },
  selectorMenu: {
    marginTop: theme.spacing(0.5),
  },
  selectorMenuList: {
    height: '100%',
    minWidth: 175,
    maxWidth: 175,
    paddingTop: 0,
  },
  selectorMenuItem: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  save: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: theme.typography.body2.fontSize,
  },
  sharedIcon: {
    position: 'relative',
    top: 4,
    marginRight: theme.spacing(1),
    fontSize: 16,
    color: theme.palette.primary.light,
  },
}));

export const WorkspaceControls = () => {
  const { pushUrl } = useRouter();

  const { smScreen } = useScreenSize();
  const { classes, cx } = useStyles();

  const { user } = useCurrentUserStorage();
  const { idsInWorkspace } = useWorkspaceStorage();
  const { projects, selectedProject } = useProjectsStorage();

  const { openProjectDialog } = useProjectDialog();
  const { active } = useDndContext();

  const toggleableProjects = React.useMemo(
    () => projects.filter((p) => p.toggleAllEnabled && p.origin === 'pn'),
    [projects]
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleVisualizeProject = (project: Project) => {
    projectsActions().select(project.id);
    workspaceActions().setWorkspace(project.workspaceItemIds);
    workspaceActions().select(last(project.workspaceItemIds));
    project.workspaceItemIds.forEach((itemId) =>
      workspaceActions().revisualize(itemId)
    );
  };

  const handleResetWorkspace = () => {
    workspaceActions().setWorkspace(defaultWorkspace);
    workspaceActions().select('wells');
    workspaceActions().resetInternalState();

    workspaceActions().updatePage('wells', 0);
    workspaceActions().updateRequestedIds('wells', {
      ids: [],
      reason: undefined,
    });

    pushUrl({ pathname: '/', search: '' });
  };

  if (!isNil(active)) return <WorkspaceTrashBin />;

  return (
    <Box className={classes.container} px={1.5}>
      {!isFreeCordova(user) && (
        <PermissionButton
          permissionPath="projects"
          id="projects-menu-button"
          aria-controls={open ? 'projects-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          className={cx(classes.button, classes.projectsButton)}
          variant="contained"
          onClick={handleClick}
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        >
          Projects
        </PermissionButton>
      )}
      <Menu
        id="projects-menu"
        className={classes.selectorMenu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'projects-menu-button',
          className: classes.selectorMenuList,
        }}
        TransitionComponent={Collapse}
      >
        {!smScreen && (
          <Box p={1}>
            <Button
              fullWidth
              size="small"
              variant="contained"
              onClick={() => {
                openProjectDialog(undefined);
                handleClose();
              }}
            >
              Create new
            </Button>
          </Box>
        )}
        {isEmpty(toggleableProjects) ? (
          <Box textAlign="center" px={2} py={1}>
            <Typography color="textSecondary">No projects</Typography>
          </Box>
        ) : (
          toggleableProjects.map((project) => (
            <MenuItem
              key={project.id}
              selected={
                selectedProject?.id === project.id &&
                arraysShareSameValues(idsInWorkspace, project.workspaceItemIds)
              }
              className={classes.selectorMenuItem}
              title={project.name}
              onClick={() => {
                handleVisualizeProject(project);
                handleClose();
              }}
            >
              <Typography color="inherit" variant="body2" noWrap>
                <>
                  {!isNil(project.channelId) ? (
                    <PeopleAltIcon className={classes.sharedIcon} />
                  ) : null}
                  {project.name}
                </>
              </Typography>
            </MenuItem>
          ))
        )}
      </Menu>

      <Button
        variant="contained"
        color="inherit"
        className={cx(classes.button, classes.resetButton)}
        onClick={handleResetWorkspace}
      >
        Reset
      </Button>
    </Box>
  );
};
