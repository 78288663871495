import CropFreeIcon from '@mui/icons-material/CropFree';
import { Box, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { formatDataType } from '@pn/core/domain/data';
import {
  getDataItemSelected,
  getFormattedItemName,
  getItemColor,
} from '@pn/core/domain/workspace';
import { centerMapOnWorkspaceItem } from '@pn/core/operations/mapInteractions';
import { useWorkspaceStorage } from '@pn/core/storage';
import { generateIconBackground } from '@pn/services/utils/color';
import { CustomTooltip } from '@pn/ui/custom-components/CustomTooltip';
import { isNil } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ color: string }>()((theme, { color }) => ({
  titleContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 200,
    height: 34,
    padding: `4px ${theme.spacing(2)}`,
    paddingRight: 4,
    background: theme.palette.action.hover,
    borderRadius: theme.shape.borderRadius,
  },
  titleColorIndicator: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: 4,
    background: color,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  iconButton: {
    /**
     * The combination of padding and icon size make the icon same height as
     * other toolbar buttons (34px).
     */
    padding: 7,
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
  /**
   * REMOVE BELOW
   */
  tooltip: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[8],
    borderRadius: theme.shape.borderRadius,
  },
  arrow: {
    color: theme.palette.text.secondary,
  },
  highlight: {
    fontWeight: 500,
  },
  closeLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export const LayerTitle = () => {
  const { workspaceItemSelected } = useWorkspaceStorage();

  const color = getItemColor(workspaceItemSelected);
  const { classes } = useStyles({
    color: generateIconBackground(color, workspaceItemSelected?.isTemporary),
  });

  const isCenterOnLayerEnabled =
    workspaceItemSelected?.itemType === 'annotation' ||
    (workspaceItemSelected?.streamedCount !== 0 &&
      workspaceItemSelected?.isRendered) ||
    (!isNil(workspaceItemSelected) &&
      !isNil(getDataItemSelected(workspaceItemSelected)));

  /**
   * REMOVE BELOW
   */
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [notifyShown, setNotifyShown] = React.useState(false);
  React.useEffect(() => {
    const cutoffDate = new Date('2024-07-28T00:00:00Z');
    const lsNotify = localStorage.getItem('notify_center_map');
    if (!isCenterOnLayerEnabled || !isNil(lsNotify) || new Date() > cutoffDate)
      return;

    setNotifyShown(true);
  }, [isCenterOnLayerEnabled]);
  const handleCloseNotify = () => {
    setNotifyShown(false);
    localStorage.setItem('notify_center_map', 'closed');
  };

  return (
    <Box className={classes.titleContainer}>
      <Box className={classes.titleColorIndicator} />
      <Typography
        noWrap
        variant="body2"
        color={
          isNil(workspaceItemSelected) || workspaceItemSelected.isTemporary
            ? 'textSecondary'
            : 'textPrimary'
        }
      >
        {getFormattedItemName(workspaceItemSelected)}
      </Typography>
      {notifyShown ? (
        <Tooltip
          open
          title={
            <Box>
              <Typography>
                <span className={classes.highlight}>Center On Layer</span>{' '}
                button has moved.
              </Typography>
              <Box mt={1} />
              <Typography
                component={Link}
                className={classes.closeLink}
                onClick={handleCloseNotify}
              >
                Don't show again
              </Typography>
            </Box>
          }
          arrow
          classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        >
          <span>
            <IconButton
              ref={buttonRef}
              size="small"
              color="primary"
              className={classes.iconButton}
              disabled={!isCenterOnLayerEnabled}
              onClick={() => centerMapOnWorkspaceItem(workspaceItemSelected)}
            >
              <CropFreeIcon />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <CustomTooltip
          disabled={isCenterOnLayerEnabled}
          title={
            isNil(workspaceItemSelected)
              ? 'Select a layer first'
              : `Cannot center on all ${formatDataType(
                  workspaceItemSelected.dataType
                )}`
          }
        >
          <span>
            <IconButton
              ref={buttonRef}
              size="small"
              color="primary"
              className={classes.iconButton}
              disabled={!isCenterOnLayerEnabled}
              onClick={() => centerMapOnWorkspaceItem(workspaceItemSelected)}
            >
              <CropFreeIcon />
            </IconButton>
          </span>
        </CustomTooltip>
      )}
    </Box>
  );
};
