import {
  pickMappingItemsFromFields,
  type DataItem,
  type MappingItem,
} from '@pn/core/domain/data';
import { geometryToGeoShape } from '@pn/core/domain/geography';
import { isWKTString, wktToGeoShape } from '@pn/core/domain/types';
import { toSIUnit } from '@pn/core/domain/units';
import { ConfigurationError } from '@pn/core/errors';
import type {
  IDataItemMapper,
  IDataItemValueMapper,
} from '@pn/core/mappers/data/ports';
import { isProduction } from '@pn/core/utils/env';
import { isGeoJSONGeometry } from '@pn/core/utils/geospatial';
import assert from 'assert';
import {
  isBoolean,
  isNil,
  isNumber,
  isObject,
  isString,
  isUndefined,
  keys,
} from 'lodash-es';
import { apiGeometryToGeoShape, type ApiDataItem } from './types';

export const apiDataItemMapper = (
  mapping: MappingItem[]
): IDataItemMapper<ApiDataItem> => {
  return {
    toDomainDataItem: (apiDataItem) => {
      assert(
        isUndefined(apiDataItem.internal_id) || // not needed for exports
          isString(apiDataItem.internal_id) ||
          isNumber(apiDataItem.internal_id),
        'API data mapping failed: internal_id must be a (string | number)'
      );

      return pickMappingItemsFromFields(
        mapping,
        keys(apiDataItem)
      ).reduce<DataItem>(
        (dataItem, mappingItem) => {
          if (mappingItem.field === 'geometry') return dataItem;

          const field = mappingItem['field'];

          const fromValue = apiDataItem[field];
          const toValue = apiDataItemValueMapper.toDomainValue(
            fromValue,
            mappingItem
          );

          dataItem[field] = toValue;
          return dataItem;
        },
        {
          _id: apiDataItem.internal_id,
          geoShape: apiGeometryToGeoShape(apiDataItem.geometry),
        }
      );
    },
    toTargetDataItem: () => {
      throw new ConfigurationError('Not implemented');
    },
  };
};

export const apiDataItemValueMapper: IDataItemValueMapper<unknown> = {
  toDomainValue: (sourceValue, mappingItem) => {
    if (isNil(sourceValue)) return undefined;

    try {
      switch (mappingItem.domainType) {
        case 'string':
        case 'DateString':
          assert(
            isString(sourceValue),
            getErrorMessage(mappingItem.field, sourceValue, 'string')
          );
          return sourceValue;
        case 'number':
          assert(
            isNumber(sourceValue),
            getErrorMessage(mappingItem.field, sourceValue, 'number')
          );
          return sourceValue;
        case 'boolean':
          assert(
            isBoolean(sourceValue),
            getErrorMessage(mappingItem.field, sourceValue, 'boolean')
          );
          return sourceValue;
        case 'object':
          assert(
            isObject(sourceValue),
            getErrorMessage(mappingItem.field, sourceValue, 'object')
          );
          return sourceValue;
        case 'GeoShape':
          assert(
            isGeoJSONGeometry(sourceValue),
            getErrorMessage(mappingItem.field, sourceValue, 'GeoJSON.Geometry')
          );
          return geometryToGeoShape(sourceValue);
        case 'WKT':
          assert(
            isWKTString(sourceValue),
            getErrorMessage(mappingItem.field, sourceValue, 'WKT')
          );
          return wktToGeoShape(sourceValue);
        case 'SIUnit':
          assert(
            isNumber(sourceValue),
            getErrorMessage(mappingItem.field, sourceValue, 'number')
          );
          return toSIUnit({
            value: sourceValue,
            symbol: mappingItem.domainTypeAttributes.symbol,
          });
        default:
          return mappingItem satisfies never;
      }
    } catch (error) {
      if (!isProduction()) {
        // console.error(error);
      }
      return undefined;
    }
  },
  toTargetValue: () => {
    throw new Error('Not implemented');
  },
};

function getErrorMessage(
  field: string,
  sourceValue: unknown,
  expectedType: string
): string {
  return `Bad data [${field}]: expected ${expectedType}, got ${typeof sourceValue}`;
}
