import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { kebabCase } from 'lodash-es';
import React from 'react';
import type { MainPanelRoute } from 'src/web-ui/main-panel/routes';

export type Portal = {
  id: string;
  item: WorkspaceItem;
  route: MainPanelRoute;
};

type ContextType = {
  portals: Portal[];
  handleOpenNewPortal: ({
    item,
    route,
  }: Pick<Portal, 'item' | 'route'>) => void;
  handleClosePortal: (id: string) => void;
};

const PortalsContext = React.createContext({} as ContextType);
export const usePortals = () => React.useContext(PortalsContext);

type Props = {
  children: React.ReactNode;
};

export const PortalsProvider = ({ children }: Props) => {
  const [portals, setPortals] = React.useState<Portal[]>([]);

  const handleOpenNewPortal = ({
    item,
    route,
  }: Pick<Portal, 'item' | 'route'>) => {
    setPortals((prevPortals) => [
      ...prevPortals,
      {
        id: `${kebabCase(item.dataType)}-${kebabCase(route.title)}`,
        item,
        route,
      },
    ]);
  };

  const handleClosePortal = (id: string) => {
    setPortals((prevPortals) =>
      prevPortals.filter((portal) => portal.id !== id)
    );
  };

  return (
    <PortalsContext.Provider
      value={{
        portals,
        handleOpenNewPortal,
        handleClosePortal,
      }}
    >
      {children}
    </PortalsContext.Provider>
  );
};
