import { useTheme } from '@mui/material';
import type { MappingItem } from '@pn/core/domain/data';
import { UnitSystem } from '@pn/core/domain/types';
import {
  convertUnit,
  toSIUnit,
  unitToString,
} from '@pn/core/domain/units';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { useAppStorage } from '@pn/core/storage';
import { isNil } from 'lodash-es';
import React from 'react';
import { map } from 'src/application/externalDependencies';

export function useMapAttributeViewer(
  item: WorkspaceItem,
  mappingItem: MappingItem | undefined
) {
  const theme = useTheme();

  const { unitSystem } = useAppStorage();

  const layerIds = React.useMemo(
    () => item.map.layers.map(({ id }) => id),
    [item]
  );

  React.useEffect(() => {
    if (isNil(mappingItem)) return;

    const mapboxMap = map._native as mapboxgl.Map;

    const tooltip = document.createElement('div');
    tooltip.style.display = 'none';
    tooltip.style.position = 'absolute';
    tooltip.style.zIndex = '2000';
    tooltip.style.padding = `${theme.spacing(0.5)} ${theme.spacing(1)}`;
    tooltip.style.backgroundColor = theme.palette.background.paper;
    tooltip.style.borderRadius = theme.shape.borderRadius + 'px';
    tooltip.style.fontSize = '14px';
    tooltip.style.fontFamily = theme.typography.fontFamily!;
    document.body.appendChild(tooltip);

    const handleMouseMove = (e: any) => {
      if (e.features.length > 0) {
        const feature = e.features[0];

        tooltip.textContent = getTextContent(
          feature.properties[mappingItem.field],
          mappingItem,
          unitSystem
        );

        /* Set the tooltip position to the current mouse location */
        tooltip.style.left = e.point.x + 'px';
        tooltip.style.top = e.point.y + 18 + 'px';

        tooltip.style.display = 'block';
      }
    };

    const handleMouseLeave = () => {
      tooltip.style.display = 'none';
    };

    layerIds.forEach((layerId) => {
      mapboxMap.on('mousemove', layerId, handleMouseMove);
      mapboxMap.on('mouseleave', layerId, handleMouseLeave);
      mapboxMap.on('mousedown', layerId, handleMouseLeave);
    });

    return () => {
      tooltip.style.display = 'none'; // hide the tooltip whenever any external change comes through (e.g. unit system change or hot reload)

      layerIds.forEach((layerId) => {
        mapboxMap.off('mousemove', layerId, handleMouseMove);
        mapboxMap.off('mouseleave', layerId, handleMouseLeave);
        mapboxMap.off('mousedown', layerId, handleMouseLeave);
      });
    };
  }, [theme, layerIds, mappingItem, unitSystem]);
}

function getTextContent(
  value: unknown,
  mappingItem: MappingItem,
  unitSystem: UnitSystem
): string {
  if (isNil(value) || value === 'N/A') {
    return 'N/A';
  } else if (mappingItem.domainType === 'SIUnit') {
    return unitToString(
      convertUnit(
        toSIUnit({
          value: value as number,
          symbol: mappingItem.domainTypeAttributes.symbol,
        }),
        unitSystem
      ),
      { displaySymbol: true }
    );
  } else {
    return (value as any).toString();
  }
}
