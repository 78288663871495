import SearchOffIcon from '@mui/icons-material/SearchOff';
import {
  Box,
  Divider,
  List,
  listItemButtonClasses,
  listItemSecondaryActionClasses,
  Paper,
  styled,
} from '@mui/material';
import {
  getColumnsTreeFromMapping,
  type ColumnsTreeFolder,
  type ColumnsTreeItem,
  type MappingItem,
} from '@pn/core/domain/data';
import { useTableFields } from '@pn/core/operations/dataTableInteractions';
import { usePrevious } from '@pn/core/utils/hooks';
import { isCloseMatch } from '@pn/core/utils/string';
import { CustomButton } from '@pn/ui/custom-components/CustomButton';
import { EmptyStateWithIcon } from '@pn/ui/custom-components/EmptyStateWithIcon';
import { useDebouncedValue } from '@pn/ui/hooks/useDebouncedValue';
import { flatten, isEmpty, noop } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { ColumnsSearchInput } from './ColumnsSearchInput';
import { ColumnTreeItem } from './ColumnTreeItem';

const useStyles = makeStyles()(() => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  listContainer: {
    maxHeight: 384, // fits Wells folders perfectly
    overflowY: 'auto',
  },
}));

type Props = {
  disabled: boolean;
  sourceLayerId: string;
  allowMappingChange?: boolean;
  mapping: MappingItem[];
};

export const ColumnsSelector = ({
  disabled,
  sourceLayerId,
  allowMappingChange = false,
  mapping,
}: Props) => {
  const { classes, theme } = useStyles();

  const { fields, addFields, removeFields, resetFields } =
    useTableFields(sourceLayerId);

  const columnsTree = React.useMemo(
    () => getColumnsTreeFromMapping(mapping),
    [mapping]
  );

  const [collapseTimeout, setCollapseTimeout] = React.useState(
    theme.transitions.duration.standard
  );
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [searchText, setSearchText] = React.useState('');
  const [searchValue, skipSearchValueDebounce] = useDebouncedValue(
    searchText,
    400,
    {
      leading: false,
      skipOnValue: '',
    }
  );

  const searchedColumnsTree = React.useMemo(
    () => searchColumnsTree(columnsTree, searchValue),
    [searchValue, columnsTree]
  );
  const foldersWithResults = React.useMemo(
    () =>
      getAllFolders(searchedColumnsTree)
        .map((ctf) => ctf.folder)
        .filter((f) => f !== '_root_search'),
    [searchedColumnsTree]
  );

  const noResultsFound = isEmpty(searchedColumnsTree.children);
  const previousSearchValue = usePrevious(searchValue);

  React.useEffect(() => {
    if (!isEmpty(searchValue)) {
      /* Disable collapse animation temporarily */
      setCollapseTimeout(0);
      setExpanded(foldersWithResults);
      setTimeout(
        () => setCollapseTimeout(theme.transitions.duration.standard),
        0
      );
    } else if (isEmpty(searchValue) && !isEmpty(previousSearchValue)) {
      /* Disable collapse animation temporarily */
      setCollapseTimeout(0);
      setExpanded([]);
      setTimeout(
        () => setCollapseTimeout(theme.transitions.duration.standard),
        0
      );
    }
  }, [
    foldersWithResults,
    searchValue,
    previousSearchValue,
    theme.transitions.duration.standard,
  ]);

  const handleExpand = React.useCallback(
    (folder: string) => () => {
      setExpanded(
        expanded.includes(folder)
          ? expanded.filter((e) => e !== folder)
          : [...expanded, folder]
      );
    },
    [expanded]
  );

  const handleCheckFields =
    (updatedFields: string[]) => (_e: any, checked: boolean) =>
      checked ? addFields(updatedFields) : removeFields(updatedFields);

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Box display="flex" gap={1.5} p={1.5}>
        <ColumnsSearchInput
          autoFocus
          fullWidth
          placeholder={`${fields.length} columns selected`}
          disabled={disabled}
          value={searchText}
          setSearchText={setSearchText}
          skipDebounce={skipSearchValueDebounce}
        />
        <CustomButton disabled={disabled} onClick={() => resetFields()}>
          Reset
        </CustomButton>
      </Box>
      <Divider />
      <Box className={classes.listContainer}>
        {noResultsFound ? (
          <Box>
            <EmptyStateWithIcon text="No columns found" icon={SearchOffIcon} />
          </Box>
        ) : (
          <StyledListOuter disablePadding>
            {searchedColumnsTree.children.map((columnsTreeItem) => (
              <ColumnTreeItem
                disabled={disabled}
                isClickable={allowMappingChange}
                key={columnsTreeItem.id}
                leftOffset={0}
                columnsTreeItem={columnsTreeItem}
                fields={fields}
                expandedFolders={expanded}
                timeout={collapseTimeout}
                onExpandFolder={handleExpand}
                onCheckFields={handleCheckFields}
                onFieldClick={noop}
              />
            ))}
          </StyledListOuter>
        )}
      </Box>
    </Paper>
  );
};

const StyledListOuter = styled(List)(({ theme }) => ({
  [`& .${listItemButtonClasses.root}`]: {
    paddingRight: theme.spacing(5.5),
  },
  [`& .${listItemSecondaryActionClasses.root}`]: {
    right: theme.spacing(1.5),
  },
}));

function getAllFolders(columnsTreeItem: ColumnsTreeItem): ColumnsTreeFolder[] {
  if ('folder' in columnsTreeItem) {
    return [columnsTreeItem].concat(
      flatten(columnsTreeItem.children.map((item) => getAllFolders(item)))
    );
  } else {
    return [];
  }
}

function searchColumnsTree(
  columnsTree: ColumnsTreeFolder,
  searchValue: string
): ColumnsTreeFolder {
  if (isEmpty(searchValue)) {
    return columnsTree;
  }

  const filteredColumnsTree: ColumnsTreeFolder = {
    id: '_root_search',
    folder: '_root_search',
    children: [],
  };

  traverse(columnsTree, filteredColumnsTree, searchValue);

  return filteredColumnsTree;
}

function traverse(
  columnsTree: ColumnsTreeFolder,
  filteredColumnsTree: ColumnsTreeFolder,
  searchValue: string
): void {
  columnsTree.children.forEach((item) => {
    if ('folder' in item) {
      const folder: ColumnsTreeFolder = {
        id: item.id,
        folder: item.folder,
        children: [],
      };

      traverse(item, folder, searchValue);

      if (!isEmpty(folder.children)) {
        filteredColumnsTree.children.push(folder);
      }
    } else {
      if (isCloseMatch(item.label, searchValue)) {
        filteredColumnsTree.children.push(item);
      }
    }
  });
}
