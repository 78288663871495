import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material';
import {
  LayerType,
  isInteractableLayer,
  type Layer,
} from '@pn/core/domain/layer';
import {
  getColorComponents,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { useDynamicStyling } from '@pn/core/operations/workspace';
import { getQuickDynamicStylingPresets } from '@pn/core/operations/workspace/quickDynamicStylingPresets';
import { workspaceActions } from '@pn/core/storage';
import { CustomTooltip } from '@pn/ui/custom-components/CustomTooltip';
import { ColorPicker } from '@pn/ui/custom-components/color-picker';
import { LARGE_SQ_GAP } from '@pn/ui/custom-components/color-picker/ColorSelector';
import { isArray, isEmpty, isNil, isString } from 'lodash-es';
import React from 'react';
import { StyleByAttributeSelector } from 'src/web-ui/workspace/styling/StyleByAttributeSelector';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  bubbleMapSwitch: {
    marginRight: 0,
  },
  presetButton: {
    textTransform: 'none',
  },
}));

type Props = {
  item: WorkspaceItem;
  layer: Layer;
  disabled: boolean;
  updateAll?: boolean;
  showAlphaSlider?: boolean;
};

export const CombinedColorPicker = ({
  item,
  layer,
  disabled,
  updateAll = false,
  showAlphaSlider = false,
}: Props) => {
  const { classes } = useStyles();

  const colorComponents = React.useMemo(
    () => getColorComponents(layer.style.color),
    [layer.style.color]
  );

  const mappingItem = React.useMemo(
    () => item.mapping.find((m) => m.field === colorComponents.field),
    [item.mapping, colorComponents.field]
  );

  const defaultStyle = !isNil(item.sourceItem)
    ? item.sourceItem.map.layers.find((l) => l.name === layer.name)?.style ?? {}
    : { color: '#000' }; // ensures that "Reset to default" generates a valid color

  const [mode, setMode] = React.useState(
    isEmpty(colorComponents) ? 'solid' : 'dynamic'
  );

  const canBubbleMap =
    mode === 'dynamic' &&
    [LayerType.Icon, LayerType.Circle].includes(layer.type) &&
    !isNil(mappingItem) &&
    ['number', 'SIUnit'].includes(mappingItem.domainType);

  const dynamicStyling = useDynamicStyling({ item, layer, updateAll });
  const {
    isDynamicStylingDisabled,
    isBubbleMapEnabled,
    setIsBubbleMapEnabled,
    applyDynamicStyling,
  } = dynamicStyling;

  const handleUpdateColor = (value: unknown) => {
    if (updateAll) {
      workspaceActions().updateAllLayerStyles(item.id, { color: value });
    } else {
      workspaceActions().updateLayerStyle(item.id, layer.id, { color: value });
    }

    if (isString(value) && isArray(layer.style.radius)) {
      workspaceActions().disableBubbleMapping(item.id);
    }
  };

  if (!isInteractableLayer(layer)) return null;

  return (
    <>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <FormControl>
            <RadioGroup
              row
              name="extended-color-picker-mode-group"
              value={mode}
              onChange={(event) => setMode(event.target.value)}
            >
              <FormControlLabel
                disabled={disabled}
                value="solid"
                label="Solid"
                control={<Radio />}
              />
              <FormControlLabel
                disabled={disabled}
                value="dynamic"
                label="Dynamic"
                control={<Radio />}
              />
            </RadioGroup>
          </FormControl>
          <CustomTooltip
            disabled={canBubbleMap}
            title={
              ![LayerType.Icon, LayerType.Circle].includes(layer.type)
                ? 'Supported by icon and circle layers only'
                : mode === 'solid'
                  ? 'Supported in dynamic mode only'
                  : 'Supported by numeric fields only'
            }
          >
            <FormControlLabel
              disabled={!canBubbleMap}
              control={
                <Switch
                  checked={canBubbleMap && isBubbleMapEnabled}
                  onChange={(event) => {
                    setIsBubbleMapEnabled(event.target.checked);
                    applyDynamicStyling({
                      _isBubbleMapEnabled: event.target.checked,
                    });
                  }}
                />
              }
              label="Bubble map"
              className={classes.bubbleMapSwitch}
            />
          </CustomTooltip>
        </Box>
        <Box mt={LARGE_SQ_GAP * 2 + 'px'}>
          {mode === 'solid' ? (
            <ColorPicker
              disabled={disabled}
              color={layer.style.color}
              defaultColor={defaultStyle.color}
              showAlphaSlider={showAlphaSlider}
              onChange={handleUpdateColor}
            />
          ) : (
            <StyleByAttributeSelector
              disabled={disabled || isDynamicStylingDisabled}
              item={item}
              layer={layer}
              {...dynamicStyling}
            />
          )}
        </Box>
      </Box>
      {updateAll && !isEmpty(getQuickDynamicStylingPresets(item.dataType)) && (
        <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
          {getQuickDynamicStylingPresets(item.dataType).map((preset) => (
            <Button
              key={preset.field}
              size="small"
              variant="outlined"
              className={classes.presetButton}
              disabled={disabled}
              startIcon={<AutoFixHighIcon />}
              onClick={() => {
                setMode('dynamic');
                applyDynamicStyling({ _field: preset.field });
              }}
            >
              By {preset.label}
            </Button>
          ))}
        </Box>
      )}
    </>
  );
};
