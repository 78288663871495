import { cachePolicy } from '@pn/core/cachePolicy';
import type { MappingItem } from '@pn/core/domain/data';
import type { ApiDataSource } from '@pn/core/domain/workspace';
import { ApiError } from '@pn/core/errors';
import type { IDataProvider } from '@pn/core/providers/data/ports';
import { elasticMappings } from '@pn/resources/elastic-mappings';
import { postgresMappings } from '@pn/resources/postgres-mappings';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { apiQueryMapper } from '@pn/services/api/query';
import { apiDataItemMapper } from './apiDataItemMapper';
import { elasticToDomainMapping } from './elastic';
import { postgresToDomainMapping } from './postgres';
import type { ApiDataItem } from './types';

const mappingsCache = new Map<string, MappingItem[]>();

export const apiDataProvider: IDataProvider<ApiDataSource> = (dataSource) => ({
  getDataMapping: async (item) => {
    if (item.isMappingInitialized) return item.mapping;

    if (cachePolicy.mappings && mappingsCache.has(item.dataType)) {
      return mappingsCache.get(item.dataType)!;
    }

    const mapping = (() => {
      switch (dataSource.source) {
        case 'elastic':
          return elasticToDomainMapping(elasticMappings[item.dataType]);
        case 'postgres':
          return postgresToDomainMapping(postgresMappings[item.dataType]);
        case 'parquet':
          if (item.dataType === 'wells')
            return elasticToDomainMapping(elasticMappings.wells); // TEMP
          throw new Error('Parquet layers must come with a predefined mapping');
      }
    })();

    if (cachePolicy.mappings) mappingsCache.set(item.dataType, mapping);

    return mapping;
  },

  getDataByQuery: async ({ query, mapping }) => {
    const apiQuery =
      apiQueryMapper[dataSource.source](mapping).toTargetQuery(query);

    const {
      data: apiData,
      total_hits,
      page,
      retrieval_time,
    } = await pnApiClient.request<{
      data: ApiDataItem[];
      total_hits: number;
      page: number;
      retrieval_time: number;
    }>({
      method: 'POST',
      url: dataSource.url + '/scroll',
      payload: {
        query: apiQuery,
      },
    });

    const data = apiData.map(apiDataItemMapper(mapping).toDomainDataItem);

    return {
      data,
      totalCount: total_hits,
      page,
      retrievalTime: retrieval_time,
    };
  },

  streamDataByQuery: async ({
    query,
    mapping,
    receiveTotalCount,
    receiveChunk,
    signal,
  }) => {
    const apiQuery =
      apiQueryMapper[dataSource.source](mapping).toTargetQuery(query);

    switch (dataSource.source) {
      case 'elastic':
      case 'parquet':
        return pnApiClient.stream({
          method: 'POST',
          url: dataSource.url + '/stream',
          payload: {
            query: apiQuery,
            ignore_limit: query.ignoreLimit,
            total_count: true,
          },
          signal,
          onReceiveTotalCount: receiveTotalCount,
          onReceiveDataChunk: (apiData: ApiDataItem[]) => {
            const domainData = apiData.map(
              apiDataItemMapper(mapping).toDomainDataItem
            );
            receiveChunk(domainData);
          },
        });
      case 'postgres':
        try {
          const { data: apiData, total_count } = await pnApiClient.request<{
            data: ApiDataItem[];
            total_count: number;
          }>({
            method: 'POST',
            url: dataSource.url + '/stream',
            payload: {
              query: apiQuery,
              ignore_limit: query.ignoreLimit,
            },
          });

          const data = apiData.map(apiDataItemMapper(mapping).toDomainDataItem);

          receiveTotalCount(total_count);
          receiveChunk(data);
        } catch (error) {
          if (error instanceof ApiError) {
            if (error.code === 400) {
              return; // exceeded safe limit, do nothing
            }
          }
          throw error;
        }
    }
  },
});
