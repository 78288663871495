import type { IProjectMapper } from '@pn/core/mappers/project/ports';
import { getStackItemId } from './apiStackWorkspaceItemMapper';
import type { StackProject } from './types';

export const apiStackProjectMapper: IProjectMapper<StackProject, never> = {
  toDomainProject: (stackProject) => {
    return {
      id: `stackdx|${stackProject.projectId}`,
      name: stackProject.description,
      folder: 'StackDX',
      createdAt: stackProject.createDate,
      updatedAt: stackProject.updateDate,
      createdBy: undefined,
      channelId: undefined,
      workspaceItemIds: stackProject.lists
        .filter((list) => (list.count ?? 0) > 0)
        .map((list) =>
          getStackItemId(stackProject.projectId, list.sourceLayerId)
        ),
      toggleAllEnabled: true,
      isGlobal: false,
      origin: 'stackdx',
      metadata: {
        dataOriginDescription: stackProject.dataOriginDesc,
      },
    };
  },
  toTargetProjectPayload: () => {
    throw new Error('Manipulation of Stack projects is not supported');
  },
};
