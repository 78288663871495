import { LayerType } from '@pn/core/domain/layer';
import { buildQuery } from '@pn/core/domain/query';
import {
  createInitInternalState,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { isProduction } from '@pn/core/utils/env';
import { DEFAULT_POLYGON_OPACITY } from '@pn/services/styles';
import { generateDataConfig, generateMapConfig } from '.';

export const mineral_rights: WorkspaceItem = {
  folder: 'Petro Ninja',
  isTemporary: false,
  id: 'mineral_rights',
  dataType: 'mineral_rights',
  itemType: 'layer',
  numberOfElements: undefined,
  createdBy: undefined,
  name: 'Mineral Rights',
  description: '115k lands in Canada',
  origin: 'pn',
  createdAt: '1970-01-01T00:00:00.000Z',
  updatedAt: '1970-01-01T00:00:00.000Z',
  isGlobal: true,
  map: generateMapConfig({
    itemId: 'mineral_rights',
    type: LayerType.Polygon,
    style: {
      color: `hsla(45, 100%, 50%, ${DEFAULT_POLYGON_OPACITY})`,
    },
    source: {
      type: 'vector',
      url: isProduction()
        ? 'mapbox://sbilston.production-mineral_rights'
        : 'mapbox://sbilston.staging-mineral_rights',
    },
    sourceLayer: 'mineral_rights',
    renderAsPoints: true,
  }),
  dataSource: generateDataConfig(['internal_id', 'geometry']),
  detailsSource: 'api',
  query: buildQuery({
    id: 'mineral_rights',
    dataType: 'mineral_rights',
  }),
  ...createInitInternalState({ isVisualized: false }),
};
