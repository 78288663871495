import type { Theme } from '@mui/material';

// mobileStepper: 1000,
// fab: 1050,
// speedDial: 1050,
// appBar: 1100,
// drawer: 1200,
// modal: 1300,
// snackbar: 1400,
// tooltip: 1500,

export const zIndex = (theme: Theme) => ({
  appBar: theme.zIndex.appBar,
  map: theme.zIndex.appBar + 1,
  annotationControls: theme.zIndex.appBar + 2,
  mainPanel: theme.zIndex.appBar + 50,
  mainTray: theme.zIndex.appBar + 51,
  library: theme.zIndex.appBar + 52,
  workspace: theme.zIndex.appBar + 53,
});
