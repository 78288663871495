import { dumpElasticMapping } from '@pn/services/api/data/elastic';
import type { ElasticMappingItem } from '@pn/services/api/data/types';
import { dispositionsElasticMapping } from './dispositions';
import { dispositionsHistoricalElasticMapping } from './dispositions_historical';
import { facilitiesElasticMapping } from './facilities';
import { gridsElasticMapping } from './grids';
import { landPostingsElasticMapping } from './land_postings';
import { mineralRightsElasticMapping } from './mineral_rights';
import { pipelinesElasticMapping } from './pipelines';
import { poolsElasticMapping } from './pools';
import { unitsElasticMapping } from './units';
import { wellsElasticMapping } from './wells';
import { wellsUsaElasticMapping } from './wells_usa';

export const elasticMappings: Record<string, ElasticMappingItem[]> = {
  wells: wellsElasticMapping,
  wells_usa: wellsUsaElasticMapping,
  pipelines: pipelinesElasticMapping,
  facilities: facilitiesElasticMapping,
  mineral_rights: mineralRightsElasticMapping,
  land_postings: landPostingsElasticMapping,
  units: unitsElasticMapping,
  pools: poolsElasticMapping,
  dispositions: dispositionsElasticMapping,
  dispositions_historical: dispositionsHistoricalElasticMapping,
  grids: gridsElasticMapping,
};

dumpElasticMapping({
  rawMapping: elasticMappings.wells,
  dumpRaw: false,
  dumpElasticTemplate: false,
});
