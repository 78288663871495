import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  AppBar,
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  List,
  Toolbar,
  Typography,
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import type { Project } from '@pn/core/domain/project';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { useDebouncedValue } from '@pn/ui/hooks/useDebouncedValue';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';
import { useStyles } from './Library.styles';
import { LibrarySearch } from './LibrarySearch';
import { libraryFolders, useLibrary } from './LibraryStateProvider';
import { StackDXMarketingPage } from './StackDXMarketingPage';
import { LibraryActionButtons } from './components/LibraryActionButtons';
import { LibraryFolder } from './components/LibraryFolder';
import { LibraryGridEmptyState } from './components/LibraryGridEmptyState';
import { LibraryLoadingIndicator } from './components/LibraryLoadingIndicator';
import { StackCompanySelector } from './components/StackCompanySelector';
import { useLibraryColumns } from './hooks/useLibraryColumns';
import { useLibraryRows } from './hooks/useLibraryRows';
import type { LibraryProject, LibraryRow } from './types';
import { getProjectCheckedState } from './utils';

type SharedProps = {
  isFetching: boolean;
  isFetchingStack: boolean;
  isStackUser: boolean;
  projects: Project[];
  allWorkspaceItems: WorkspaceItem[];
  idsInWorkspace: WorkspaceItem['id'][];
  onToggleItem: (id: WorkspaceItem['id'], checked: boolean) => void;
  onOpenItemDialog: (item: WorkspaceItem) => void;
  onOpenProjectDialog: (project: Project) => void;
};

type PropsWithUploads = {
  onCreateList: () => void;
  onUploadLayer: () => void;
};

type PropsWithoutUploads = {
  onCreateList?: never;
  onUploadLayer?: never;
};

type Props = SharedProps & (PropsWithUploads | PropsWithoutUploads);

export const Library = React.memo(_Library);
function _Library({
  isFetching,
  isFetchingStack,
  isStackUser,
  projects,
  allWorkspaceItems,
  idsInWorkspace,
  onToggleItem,
  onOpenItemDialog,
  onOpenProjectDialog,
  onCreateList,
  onUploadLayer,
}: Props) {
  const { isLibraryOpen, libraryRouter, setLibraryRouter, closeLibrary } =
    useLibrary();

  const [searchText, setSearchText] = React.useState('');
  const [searchValue, skipSearchValueDebounce] = useDebouncedValue(
    searchText,
    400,
    {
      leading: false,
      skipOnValue: '',
    }
  );
  const [folderSelected, projectIdSelected] = libraryRouter;

  const libraryProjectSelected: LibraryProject | undefined =
    React.useMemo(() => {
      const projectSelected = projects.find((p) => p.id === projectIdSelected);

      return !isNil(projectSelected)
        ? {
            id: projectSelected.id,
            name: projectSelected.name,
            workspaceItemIds: projectSelected.workspaceItemIds,
            isGlobal: projectSelected.isGlobal,
            _checked: getProjectCheckedState(
              projectSelected.workspaceItemIds,
              idsInWorkspace
            ),
          }
        : undefined;
    }, [idsInWorkspace, projects, projectIdSelected]);

  const showStackMarketingPage = folderSelected === 'StackDX' && !isStackUser;

  const isLoading =
    isFetching || (folderSelected === 'StackDX' && isFetchingStack);

  const { classes } = useStyles();

  const handleRowClick = (params: { row: LibraryRow }) => {
    const { row } = params;

    if (row.rowType === 'project') {
      setLibraryRouter([row.source.folder, row.id]);
      setSearchText('');
    } else {
      onOpenItemDialog(row.source);
    }
  };

  const handleCheckboxChange = React.useCallback(
    (checked: boolean, row: LibraryRow) => {
      if (row.rowType === 'project') {
        row.source.workspaceItemIds.forEach((id) => {
          onToggleItem(id, checked);
        });
      } else {
        onToggleItem(row.id, checked);
      }
    },
    [onToggleItem]
  );

  const handleHeaderCheckboxChange = React.useCallback(
    (checked: boolean) => {
      if (isNil(libraryProjectSelected)) return;

      libraryProjectSelected.workspaceItemIds.forEach((id) => {
        onToggleItem(id, checked);
      });
    },
    [onToggleItem, libraryProjectSelected]
  );

  const rows = useLibraryRows({
    folderSelected,
    searchValue,
    projects,
    libraryProjectSelected,
    allWorkspaceItems,
    idsInWorkspace,
  });
  const columns = useLibraryColumns({
    libraryProjectSelected,
    onCheckboxChange: handleCheckboxChange,
    onHeaderCheckboxChange: handleHeaderCheckboxChange,
    onOpenProjectDialog,
  });

  const breadcrumbs = isEmpty(searchValue)
    ? libraryRouter.map((route, index) => {
        const isLast = index === libraryRouter.length - 1;
        const isProject = index === 1 && !isNil(libraryProjectSelected);

        if (isLast) {
          return (
            <Typography key={route} variant="h6" color="text.primary">
              {isProject ? libraryProjectSelected.name : route}
            </Typography>
          );
        }

        return (
          <Typography
            key={route}
            component={Link}
            variant="h6"
            color="inherit"
            underline="hover"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              setLibraryRouter([libraryRouter[0]]);
            }}
          >
            {route}
          </Typography>
        );
      })
    : [
        <Typography key="Search results" variant="h6" color="text.primary">
          Search results
        </Typography>,
      ];

  if (!isLibraryOpen) return null;

  return (
    <Box className={classes.container}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar disableGutters className={classes.toolbar}>
          <IconButton
            edge="start"
            className={classes.closeButton}
            aria-label="menu"
            onClick={() => closeLibrary()}
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            component="div"
            variant="h6"
            color="textSecondary"
            className={classes.text}
          >
            Petro Ninja <span className={classes.title}>Library</span>
          </Typography>
          <Box ml="40px" />
          <LibrarySearch
            searchText={searchText}
            setSearchText={setSearchText}
            skipDebounce={skipSearchValueDebounce}
          />
        </Toolbar>
      </AppBar>

      <Box className={classes.sideBar}>
        <List className={classes.sideBarList}>
          {isEmpty(searchValue) ? (
            libraryFolders.map((folder) => (
              <LibraryFolder
                key={folder}
                folder={folder}
                isSelected={folderSelected === folder}
                onClick={(folder) => setLibraryRouter([folder])}
              />
            ))
          ) : (
            <LibraryFolder
              folder="Search results"
              isSelected
              onClick={(folder) => setLibraryRouter([folder])}
            />
          )}
        </List>
      </Box>

      <Box className={classes.content}>
        {!showStackMarketingPage && (
          <Box className={classes.controls}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>

            {folderSelected === 'StackDX' ? (
              <StackCompanySelector disabled={isLoading} />
            ) : (
              <LibraryActionButtons
                onCreateList={onCreateList}
                onUploadLayer={onUploadLayer}
              />
            )}
          </Box>
        )}

        <Box className={classes.tableContainer}>
          {isEmpty(rows) ? (
            showStackMarketingPage ? (
              <StackDXMarketingPage />
            ) : isLoading ? (
              <LibraryLoadingIndicator />
            ) : (
              <LibraryGridEmptyState />
            )
          ) : (
            <DataGridPro
              className={classes.dataGrid}
              rowHeight={56}
              rows={rows}
              columns={columns}
              disableColumnSelector
              disableRowSelectionOnClick
              columnVisibilityModel={{ origin: folderSelected === 'StackDX' }}
              onRowClick={handleRowClick}
              localeText={{
                footerTotalRows: 'Total:',
                footerRowSelected: (count) =>
                  `${count.toLocaleString()} layer${
                    count !== 1 ? 's' : ''
                  } active in the workspace`,
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
