import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// import '@fontsource-variable/roboto-flex';
import { isNil } from 'lodash-es';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from 'src/web-ui/App';
import 'src/web-ui/index.css';
import 'src/web-ui/markdown.css';
import 'src/web-ui/polyfills';

declare global {
  interface Window {
    FreshworksWidget: any;
    fwSettings: any; // freshdesk settings
    Keyboard: any; // cordova only call
    BuildInfo: any; // cordova only call
  }
}

const GLOBAL_STRICT_MODE = false;
const ConditionalStrictMode = GLOBAL_STRICT_MODE
  ? React.StrictMode
  : React.Fragment;

function renderApp() {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLDivElement
  );
  root.render(
    <ConditionalStrictMode>
      <App />
    </ConditionalStrictMode>
  );
}

if (!isNil(window.cordova)) {
  document.addEventListener('deviceready', onDeviceReady, false);
} else {
  renderApp();

  // try {
  //   if (window.FreshworksWidget) {
  //     window.FreshworksWidget('hide', 'launcher');
  //   }
  // } catch (err) {
  //   console.error('Error hiding Freshdesk widget', err);
  // }
}

function onDeviceReady() {
  window.open = window.cordova.InAppBrowser.open; // open all links in the in-app-browser
  renderApp();
}
