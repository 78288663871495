import { isCordova } from '@pn/core/utils/env';
import React from 'react';

type ContextType = {
  isLibraryOpen: boolean;
  libraryRouter: [string] | [string, string];
  setLibraryRouter: (router: [string] | [string, string]) => void;
  openLibrary: () => void;
  closeLibrary: () => void;
};

export const LibraryContext = React.createContext({} as ContextType);
export const useLibrary = () => React.useContext(LibraryContext);

export const libraryFolders = isCordova()
  ? ['Petro Ninja', 'Personal', 'Shared']
  : ['Petro Ninja', 'Personal', 'Shared', 'BOE Intel', 'StackDX'];

type ContextProviderProps = {
  children: React.ReactNode;
};

export const LibraryStateProvider = ({ children }: ContextProviderProps) => {
  const [isLibraryOpen, setIsLibraryOpen] = React.useState(false);
  const [libraryRouter, setLibraryRouter] = React.useState<
    [string] | [string, string]
  >([libraryFolders[0]]);

  const openLibrary = React.useCallback(() => {
    setIsLibraryOpen(true);
  }, []);
  const closeLibrary = React.useCallback(() => {
    setIsLibraryOpen(false);
  }, []);

  return (
    <LibraryContext.Provider
      value={{
        isLibraryOpen,
        libraryRouter,
        setLibraryRouter,
        openLibrary,
        closeLibrary,
      }}
    >
      {children}
    </LibraryContext.Provider>
  );
};
