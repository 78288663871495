import { dependencies } from '@pn/core/dependencies';
import {
  getDataItemSelected,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { useBuildSliceSelector } from '@pn/services/redux/useBuildSliceSelector';
import { createSelector } from '@reduxjs/toolkit';
import { isNil } from 'lodash-es';
import type { IWorkspaceActions, IWorkspaceStorage } from './ports';
import { type State, workspaceSlice } from './workspaceSlice';

export const useReduxWorkspaceStorage = (): IWorkspaceStorage => {
  const { name, getInitialState } = workspaceSlice;

  const useSliceSelector = useBuildSliceSelector(name, getInitialState());

  return {
    isFetching: useSliceSelector((state) => state.isFetching),
    isFetchingStack: useSliceSelector((state) => state.isFetchingStack),
    allWorkspaceItems: useSliceSelector((state) => state.allWorkspaceItems),
    workspaceItems: useSliceSelector(selectWorkspaceItems),
    idsInWorkspace: useSliceSelector((state) => state.idsInWorkspace),
    workspaceItemSelected: useSliceSelector(selectWorkspaceItemSelected),
    dataItemRequested: useSliceSelector(selectDataItemRequested),
    dataItemSelected: useSliceSelector(selectDataItemSelected),
    invalidDataItemIds: useSliceSelector((state) => state.invalidDataItemIds),

    allAreaWorkspaceItems: useSliceSelector(selectAllAreaWorkspaceItems),
    areaWorkspaceItems: useSliceSelector(selectAreaWorkspaceItems),
    areaWorkspaceItemSelected: useSliceSelector(
      selectAreaWorkspaceItemSelected
    ),
  };
};

export const reduxWorkspaceActions = (): IWorkspaceActions => {
  const { dispatch } = dependencies.store;
  const { actions } = workspaceSlice;

  return {
    add: (itemOrItems, upsert = true) =>
      dispatch(actions.add({ itemOrItems, upsert })),
    create: (item) => dispatch(actions.create(item)),
    update: (item) => dispatch(actions.update(item)),
    remove: (item) => dispatch(actions.remove(item)),
    removeStackOriginatedItems: () =>
      dispatch(actions.removeStackOriginatedItems()),
    removeAreasFromWorkspace: () =>
      dispatch(actions.removeAreasFromWorkspace()),
    // requestStackItems: () => dispatch(actions.requestStackItems()),
    // receiveStackItems: (items) => dispatch(actions.receiveStackItems(items)),
    updateIsFetching: (isFetching) =>
      dispatch(actions.updateIsFetching(isFetching)),
    updateIsFetchingStack: (isFetching) =>
      dispatch(actions.updateIsFetchingStack(isFetching)),
    updateInvalidDataItemIds: (ids) =>
      dispatch(actions.updateInvalidDataItemIds(ids)),
    updateQueryProperty: (itemId, query) =>
      dispatch(actions.updateQueryProperty({ itemId, query })),
    addLayer: (itemId, layer) => dispatch(actions.addLayer({ itemId, layer })),
    removeLayer: (itemId, layerId) =>
      dispatch(actions.removeLayer({ itemId, layerId })),
    updateFilterPropertyOptions: (itemId, filterPropertyOptions) =>
      dispatch(
        actions.updateFilterPropertyOptions({
          itemId,
          filterPropertyOptions,
        })
      ),
    updateLayerStyle: (itemId, layerId, style) =>
      dispatch(actions.updateLayerStyle({ itemId, layerId, style })),
    updateAllLayerStyles: (itemId, style) =>
      dispatch(actions.updateAllLayerStyles({ itemId, style })),
    enableBubbleMapping: (itemId, params) =>
      dispatch(actions.enableBubbleMapping({ itemId, ...params })),
    disableBubbleMapping: (itemId) =>
      dispatch(actions.disableBubbleMapping(itemId)),
    updateNumberOfElements: (itemId, numberOfElements) =>
      dispatch(
        actions.updateNumberOfElements({
          itemId,
          numberOfElements,
        })
      ),
    updateName: (itemId, name) =>
      dispatch(actions.updateName({ itemId, name })),
    addToWorkspace: (itemId) => dispatch(actions.addToWorkspace(itemId)),
    removeFromWorkspace: (itemId) =>
      dispatch(actions.removeFromWorkspace(itemId)),
    setWorkspace: (itemIds, preserveVisibility) =>
      dispatch(actions.setWorkspace({ itemIds: itemIds, preserveVisibility })),
    select: (itemId) => dispatch(actions.select(itemId)),

    /* INTERNAL STATE */

    setUnsavedChanges: (itemId) => dispatch(actions.setUnsavedChanges(itemId)),
    updateCounts: (itemId, params) =>
      dispatch(actions.updateCounts({ itemId, ...params })),
    updateVisibility: (itemId, isVisible) =>
      dispatch(actions.updateVisibility({ itemId, isVisible })),
    updateMapping: (itemId, mapping) =>
      dispatch(actions.updateMapping({ itemId, mapping })),
    updateTableDataItems: (itemId, tableDataItems) =>
      dispatch(
        actions.updateTableDataItems({
          itemId,
          tableDataItems,
        })
      ),
    setRequestedDataItemId: (itemId, id, reason) =>
      dispatch(actions.setRequestedDataItemId({ itemId, id, reason })),
    unsetRequestedDataItemId: (itemId) =>
      dispatch(actions.unsetRequestedDataItemId(itemId)),
    markAsLoaded: (itemId) => dispatch(actions.markAsLoaded(itemId)),
    markAsProcessed: (itemId) => dispatch(actions.markAsProcessed(itemId)),
    markAsUnfinalized: (itemId) => dispatch(actions.markAsUnfinalized(itemId)),
    markAsRendered: (itemId) => dispatch(actions.markAsRendered(itemId)),
    revisualize: (itemId) => dispatch(actions.revisualize(itemId)),
    resetInternalState: () => dispatch(actions.resetInternalState()),

    /* QUERY ACTIONS */

    updateSorting: (itemId, sorts) =>
      dispatch(actions.updateSorts({ itemId, sorts })),
    updateFiltering: (itemId, filters, linkOperator) =>
      dispatch(actions.updateFiltering({ itemId, filters, linkOperator })),
    updatePage: (itemId, page) =>
      dispatch(actions.updatePage({ itemId, page })),
    // updatePageWithId: (itemId, pageWithId) =>
    //   dispatch(actions.updatePageWithId({ itemId, pageWithId })),
    setCheckedIds: (itemId, ids) =>
      dispatch(actions.setCheckedIds({ itemId, ids })),
    updateRequestedIds: (itemId, params) =>
      dispatch(actions.updateRequestedIds({ itemId, ...params })),
    overrideLimit: (itemId) => dispatch(actions.overrideLimit(itemId)),
  };
};

const selectWorkspaceItems = createSelector(
  [
    (state: State) => state.allWorkspaceItems,
    (state: State) => state.idsInWorkspace,
  ],
  (allWorkspaceItems, idsInWorkspace) =>
    idsInWorkspace
      .map((id) => allWorkspaceItems.find((item) => item.id === id))
      .filter((i): i is NonNullable<typeof i> => !isNil(i))
);

const selectWorkspaceItemSelected = createSelector(
  [
    (state: State) => state.allWorkspaceItems,
    (state: State) => state.idsInWorkspace,
    (state: State) => state.workspaceItemIdSelected,
  ],
  (allWorkspaceItems, idsInWorkspace, workspaceItemIdSelected) =>
    allWorkspaceItems.find(
      ({ id }) => idsInWorkspace.includes(id) && id === workspaceItemIdSelected
    )
);

const selectDataItemRequested = createSelector(
  [
    (state: State) => state.allWorkspaceItems,
    (state: State) => state.idsInWorkspace,
    (state: State) => state.workspaceItemIdSelected,
  ],
  (allWorkspaceItems, idsInWorkspace, workspaceItemIdSelected) => {
    const workspaceItemSelected = allWorkspaceItems.find(
      ({ id }) => idsInWorkspace.includes(id) && id === workspaceItemIdSelected
    );

    return (
      workspaceItemSelected?.requestedDataItem ?? {
        id: undefined,
        reason: undefined,
      }
    );
  }
);

const selectDataItemSelected = createSelector(
  [
    (state: State) => state.allWorkspaceItems,
    (state: State) => state.idsInWorkspace,
    (state: State) => state.workspaceItemIdSelected,
  ],
  (allWorkspaceItems, idsInWorkspace, workspaceItemIdSelected) => {
    const workspaceItemSelected = allWorkspaceItems.find(
      ({ id }) => idsInWorkspace.includes(id) && id === workspaceItemIdSelected
    );

    if (isNil(workspaceItemSelected)) return undefined;
    return getDataItemSelected(workspaceItemSelected);
  }
);

const selectAreaWorkspaceItems = createSelector(
  [
    (state: State) => state.allWorkspaceItems,
    (state: State) => state.idsInWorkspace,
  ],
  (allWorkspaceItems, idsInWorkspace) =>
    allWorkspaceItems.filter(
      (item) => idsInWorkspace.includes(item.id) && item.metadata?.isQueryArea
    )
);

const selectAllAreaWorkspaceItems = createSelector(
  [(state: State) => state.allWorkspaceItems],
  (allWorkspaceItems) =>
    allWorkspaceItems.filter(({ metadata }) => metadata?.isQueryArea)
);

const selectAreaWorkspaceItemSelected = createSelector(
  [
    (state: State) => state.allWorkspaceItems,
    (state: State) => state.idsInWorkspace,
    (state: State) => state.workspaceItemIdSelected,
  ],
  (allWorkspaceItems, idsInWorkspace, workspaceItemIdSelected) =>
    allWorkspaceItems
      .filter(
        (item) => idsInWorkspace.includes(item.id) && item.metadata?.isQueryArea
      )
      .find(({ id }) => id === workspaceItemIdSelected)
);

export function getWorkspaceItemsByIds(
  ids: WorkspaceItem['id'][]
): WorkspaceItem[] {
  const allWorkspaceItems: WorkspaceItem[] =
    dependencies.store.getState().workspace.allWorkspaceItems;

  return ids
    .map((id) => allWorkspaceItems.find((item) => item.id === id))
    .filter((i): i is NonNullable<typeof i> => !isNil(i));
}

// HACK find a better solution?
export function isWorkspaceItemCleared(itemId: string): boolean {
  const { idsInWorkspace, allWorkspaceItems } = dependencies.store.getState()
    .workspace as State;

  const isInWorkspace = !isNil(
    idsInWorkspace.find((id: string) => id === itemId)
  );
  const item = allWorkspaceItems.find((item) => item.id === itemId);
  const isProcessed = item?.isProcessed ?? false;

  return !isInWorkspace && !isProcessed;
}
