import {
  pnDataTypes,
  type DataItemId,
  type PNDataType,
} from '@pn/core/domain/data';
import { UnitSystem } from '@pn/core/domain/types';
import {
  convertUnit,
  toSIUnit,
  type SIUnit,
} from '@pn/core/domain/units';
import {
  isNil,
  isNumber,
  isString,
  last,
  remove,
  round,
  sortBy,
  uniq,
  uniqBy,
} from 'lodash-es';

export type ProductionMode =
  | 'producingDaily'
  | 'calendarDaily'
  | 'monthly'
  | 'cumulative';

export type ProductionItem = {
  date: string;
  fluidType: 'production' | 'injection' | 'other';
  productionGrouping: string; // e.g. 'Oil', 'Gas', 'Water'
  producingDaily: SIUnit;
  calendarDaily: SIUnit;
  monthly: SIUnit;
  cumulative: SIUnit;
  hours: number | undefined;
  count: number | undefined;
  /* Comparison charts only */
  id: DataItemId | undefined;
  itemType: 'item' | 'avg';
};

export type GroupedProductionItem = {
  mode: ProductionMode;
  date: string;
  hours: number | undefined;
  count: number | undefined;
  [key: ProductionItem['productionGrouping']]:
    | SIUnit
    | ProductionMode
    | string
    | number
    | undefined;
};

type ProductionType =
  | 'producingDaily'
  | 'calendarDaily'
  | 'monthly'
  | 'cumulative';

/**
 * - `PNDataType` is a standard single-line production view (one line per series)
 * - `"list_aggregate"` is a single-line aggregation of all data items in a list
 *   (one line per series)
 * - `"list_compare"` is a multi-line production view with a trend line (one
 *   line per list item, one series at a time)
 * - `"aggregate"` is a single-line production view driven from an array of well IDs
 */
export type ProductionChartType =
  | PNDataType
  | 'list_aggregate'
  | 'list_compare'
  | 'aggregate'
  | 'compare';
export function isProductionChartType(
  arg: unknown
): arg is ProductionChartType {
  return (
    isString(arg) &&
    [
      ...pnDataTypes,
      'list_aggregate',
      'list_compare',
      'aggregate',
      'compare',
    ].includes(arg)
  );
}

export function convertProductionItem(
  productionItem: ProductionItem,
  unitSystem: UnitSystem
): ProductionItem {
  return {
    ...productionItem,
    producingDaily: convertUnit(productionItem.producingDaily, unitSystem),
    calendarDaily: convertUnit(productionItem.calendarDaily, unitSystem),
    monthly: convertUnit(productionItem.monthly, unitSystem),
    cumulative: convertUnit(productionItem.cumulative, unitSystem),
  };
}

export function addProductionDerivatives(
  productionItems: ProductionItem[]
): ProductionItem[] {
  const enhancedProductionItems: ProductionItem[] = [];

  const ids = uniq(productionItems.map(({ id }) => id));

  ids.forEach((id) => {
    const dates = uniq(
      productionItems.filter((item) => item.id === id).map(({ date }) => date)
    );

    dates.forEach((date) => {
      const productionItemsOnDate = productionItems.filter(
        (item) => item.date === date && item.id === id
      );

      /* Add existing items */
      enhancedProductionItems.push(...productionItemsOnDate);

      /* Add derivatives */
      enhancedProductionItems.push(
        generateDerivativeProductionItem({
          id,
          date,
          productionGrouping: 'Water Cut',
          symbol: '%',
          productionItemsOnDate,
          valueFn: getWaterCutValue,
        }),
        generateDerivativeProductionItem({
          id,
          date,
          productionGrouping: 'GOR',
          symbol: 'm3/m3',
          productionItemsOnDate,
          valueFn: getGasOilRatioValue,
        }),
        generateDerivativeProductionItem({
          id,
          date,
          productionGrouping: 'WGR',
          symbol: 'm3/e3m3',
          productionItemsOnDate,
          valueFn: getWaterGasRatioValue,
        }),
        generateDerivativeProductionItem({
          id,
          date,
          productionGrouping: 'CGR',
          symbol: 'm3/e3m3',
          productionItemsOnDate,
          valueFn: getCondensateGasRatioValue,
        })
      );
    });
  });

  /* Remove all derivatives that have no defined values */
  removeEmptySeries(enhancedProductionItems, 'Water Cut');
  removeEmptySeries(enhancedProductionItems, 'GOR');
  removeEmptySeries(enhancedProductionItems, 'WGR');
  removeEmptySeries(enhancedProductionItems, 'CGR');

  return enhancedProductionItems;
}

function generateDerivativeProductionItem(params: {
  id: DataItemId | undefined;
  date: string;
  productionGrouping: string;
  symbol: string;
  productionItemsOnDate: ProductionItem[];
  valueFn: (
    productionItems: ProductionItem[],
    type: ProductionType
  ) => number | undefined;
}): ProductionItem {
  return {
    date: params.date,
    fluidType: 'other',
    productionGrouping: params.productionGrouping,
    producingDaily: toSIUnit({
      value: params.valueFn(params.productionItemsOnDate, 'producingDaily'),
      symbol: params.symbol,
    }),
    calendarDaily: toSIUnit({
      value: params.valueFn(params.productionItemsOnDate, 'calendarDaily'),
      symbol: params.symbol,
    }),
    monthly: toSIUnit({
      value: params.valueFn(params.productionItemsOnDate, 'monthly'),
      symbol: params.symbol,
    }),
    cumulative: toSIUnit({
      value: params.valueFn(params.productionItemsOnDate, 'cumulative'),
      symbol: params.symbol,
    }),
    hours: undefined,
    count: params.productionItemsOnDate[0].count,
    id: params.id,
    itemType: params.productionItemsOnDate[0].itemType,
  };
}

function getWaterCutValue(
  productionItems: ProductionItem[],
  type: ProductionType
): number | undefined {
  const oilItem = productionItems.find(
    (item) => item.productionGrouping === 'Oil'
  );
  const waterItem = productionItems.find(
    (item) => item.productionGrouping === 'Water'
  );

  if (!isNil(oilItem) && !isNil(waterItem)) {
    const oilValue = oilItem[type].value;
    const waterValue = waterItem[type].value;

    if (isNumber(oilValue) && isNumber(waterValue)) {
      return round((waterValue / (oilValue + waterValue)) * 100, 1);
    }
  }

  return undefined;
}

function getGasOilRatioValue(
  productionItems: ProductionItem[],
  type: ProductionType
): number | undefined {
  const oilItem = productionItems.find(
    (item) => item.productionGrouping === 'Oil'
  );
  const gasItem = productionItems.find(
    (item) => item.productionGrouping === 'Gas'
  );

  if (!isNil(oilItem) && !isNil(gasItem)) {
    const oilValue = oilItem[type].value;
    const gasValue = gasItem[type].value;

    if (isNumber(oilValue) && isNumber(gasValue)) {
      return round((gasValue * 1000) / oilValue, 1);
    }
  }

  return undefined;
}

function getWaterGasRatioValue(
  productionItems: ProductionItem[],
  type: ProductionType
): number | undefined {
  const waterItem = productionItems.find(
    (item) => item.productionGrouping === 'Water'
  );
  const gasItem = productionItems.find(
    (item) => item.productionGrouping === 'Gas'
  );

  if (!isNil(waterItem) && !isNil(gasItem)) {
    const waterValue = waterItem[type].value;
    const gasValue = gasItem[type].value;

    if (isNumber(waterValue) && isNumber(gasValue)) {
      return round(waterValue / gasValue, 3);
    }
  }

  return undefined;
}

function getCondensateGasRatioValue(
  productionItems: ProductionItem[],
  type: ProductionType
): number | undefined {
  const condensateItem = productionItems.find(
    (item) => item.productionGrouping === 'Condensate'
  );
  const gasItem = productionItems.find(
    (item) => item.productionGrouping === 'Gas'
  );

  if (!isNil(condensateItem) && !isNil(gasItem)) {
    const condensateValue = condensateItem[type].value;
    const gasValue = gasItem[type].value;

    if (isNumber(condensateValue) && isNumber(gasValue)) {
      return round(condensateValue / gasValue, 3);
    }
  }

  return undefined;
}

function removeEmptySeries(
  productionItems: ProductionItem[],
  productionGrouping: string
): ProductionItem[] {
  const items = productionItems.filter(
    (item) => item.productionGrouping === productionGrouping
  );

  if (items.every((item) => isNil(item.monthly.value))) {
    return remove(
      productionItems,
      (item) => item.productionGrouping === productionGrouping
    );
  }

  return productionItems;
}

export type ProductionGrouping = {
  label: string;
  symbol: string;
};
export function getProductionGroupings(
  productionItems: ProductionItem[],
  mode: ProductionMode
): ProductionGrouping[] {
  const productionGroupings = sortBy(
    uniqBy(
      productionItems.map((item) => ({
        label: item.productionGrouping,
        symbol: item[mode].symbol,
      })),
      'label'
    ),
    'label'
  );

  /* Reorder groupings */

  const oil = productionGroupings.find((item) => item.label === 'Oil');
  const gas = productionGroupings.find((item) => item.label === 'Gas');
  const water = productionGroupings.find((item) => item.label === 'Water');

  if (water) {
    productionGroupings.splice(productionGroupings.indexOf(water), 1);
    productionGroupings.unshift(water);
  }

  if (gas) {
    productionGroupings.splice(productionGroupings.indexOf(gas), 1);
    productionGroupings.unshift(gas);
  }

  if (oil) {
    productionGroupings.splice(productionGroupings.indexOf(oil), 1);
    productionGroupings.unshift(oil);
  }

  const waterCut = productionGroupings.find(
    (item) => item.label === 'Water Cut'
  );
  const gor = productionGroupings.find((item) => item.label === 'GOR');
  const wgr = productionGroupings.find((item) => item.label === 'WGR');
  const cgr = productionGroupings.find((item) => item.label === 'CGR');

  if (waterCut) {
    productionGroupings.splice(productionGroupings.indexOf(waterCut), 1);
    productionGroupings.push(waterCut);
  }

  if (gor) {
    productionGroupings.splice(productionGroupings.indexOf(gor), 1);
    productionGroupings.push(gor);
  }

  if (wgr) {
    productionGroupings.splice(productionGroupings.indexOf(wgr), 1);
    productionGroupings.push(wgr);
  }

  if (cgr) {
    productionGroupings.splice(productionGroupings.indexOf(cgr), 1);
    productionGroupings.push(cgr);
  }

  return productionGroupings;
}

export function getCumulatives(
  productionItems: ProductionItem[],
  unitSystem: UnitSystem
): {
  oil: SIUnit;
  gas: SIUnit;
  water: SIUnit;
  fluid: SIUnit;
  loadFluid: SIUnit;
} {
  const oilItems = productionItems.filter(
    (item) => item.productionGrouping === 'Oil'
  );
  const gasItems = productionItems.filter(
    (item) => item.productionGrouping === 'Gas'
  );
  const waterItems = productionItems.filter(
    (item) => item.productionGrouping === 'Water'
  );
  const condensateItems = productionItems.filter(
    (item) => item.productionGrouping === 'Condensate'
  );
  const loadFluidItems = productionItems.filter(
    (item) => item.productionGrouping === 'Load Fluid'
  );

  return {
    oil: toSIUnit({
      value: last(oilItems)?.cumulative.value ?? 0,
      symbol: unitSystem === UnitSystem.Metric ? 'm3' : 'bbl',
    }),
    gas: toSIUnit({
      value: last(gasItems)?.cumulative.value ?? 0,
      symbol: unitSystem === UnitSystem.Metric ? 'e3m3' : 'mcf',
    }),
    water: toSIUnit({
      value: last(waterItems)?.cumulative.value ?? 0,
      symbol: unitSystem === UnitSystem.Metric ? 'm3' : 'bbl',
    }),
    fluid: toSIUnit({
      value:
        (last(oilItems)?.cumulative.value ?? 0) +
        (last(waterItems)?.cumulative.value ?? 0) +
        (last(condensateItems)?.cumulative.value ?? 0),
      symbol: unitSystem === UnitSystem.Metric ? 'm3' : 'bbl',
    }),
    loadFluid: toSIUnit({
      value: last(loadFluidItems)?.cumulative.value ?? 0,
      symbol: unitSystem === UnitSystem.Metric ? 'm3' : 'bbl',
    }),
  };
}
