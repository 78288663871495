import {
  Button,
  Checkbox,
  ClassNameMap,
  TableCell,
  TableCellProps,
} from '@mui/material';
import { Column } from '@pn/core/domain/data-info';
import { isMapboxLayer } from '@pn/core/domain/layer';
import { UnitSystem } from '@pn/core/domain/types';
import { useAccess } from '@pn/core/permissions/access';
import { useAppStorage } from '@pn/core/storage';
import { formatDomainValue } from '@pn/core/utils/format';
import { IntelDynamicLink } from '@pn/ui/custom-components/DynamicLink';
import assert from 'assert';
import clsx from 'clsx';
import { isNil, isObject, isString } from 'lodash-es';
import {
  map,
  useAuthenticationService,
} from 'src/application/externalDependencies';
import { CustomLink } from 'src/web-ui/components/CustomLink';
import { useExtraDataContext } from 'src/web-ui/main-panel/components/dense-components/ExtraDataProvider';

type Props = {
  classes: ClassNameMap;
  value: unknown;
  isButton: boolean;
  module?: Column['module'];
  intelUrl?: string;
  inlineSymbol?: boolean;
  onClick?: () => void;
} & TableCellProps;

export const FormattedTableCell = ({
  classes,
  value,
  module,
  isButton,
  intelUrl,
  inlineSymbol,
  onClick,
  ...rest
}: Props) => {
  const access = useAccess();
  const { isAuthenticated } = useAuthenticationService();
  const { unitSystem } = useAppStorage();

  return (
    <TableCell
      className={
        isAuthenticated
          ? classes.tableCell
          : clsx(classes.tableCell, classes.blur)
      }
      style={access('details.selection').denied() ? { userSelect: 'none' } : {}}
      {...rest}
    >
      {module === 'visualize_source_layer'
        ? isMapboxLayer(value) && <SourceLayerCheckbox layer={value} />
        : isAuthenticated
          ? format(value, isButton, intelUrl, unitSystem, inlineSymbol, onClick)
          : obfuscate(value)}
    </TableCell>
  );
};

const SourceLayerCheckbox = (props: { layer: mapboxgl.Layer }) => {
  const { layer } = props;

  const {
    dispatch,
    state: { visualizedSourceLayers },
  } = useExtraDataContext();

  const handleChange = (_event: unknown, checked: boolean) => {
    const sourceId = `${layer.id}-source`;

    if (checked) {
      assert(
        isObject(layer.source),
        'visualize_source_layer module error: layer source must contain a full definition'
      );

      map._native.addSource(sourceId, layer.source);
      map._native.addLayer({
        ...layer,
        source: sourceId,
      } as mapboxgl.AnyLayer);

      dispatch({
        type: 'ADD_SOURCE_LAYER',
        payload: { layerId: layer.id },
      });
    } else {
      map._native.removeLayer(layer.id);
      map._native.removeSource(sourceId);

      dispatch({
        type: 'REMOVE_SOURCE_LAYER',
        payload: { layerId: layer.id },
      });
    }
  };

  return (
    <Checkbox
      checked={visualizedSourceLayers[layer.id] ?? false}
      onChange={handleChange}
    />
  );
};

function format(
  value: unknown,
  isButton: boolean,
  intelUrl: string | undefined,
  unitSystem: UnitSystem,
  inlineSymbol?: boolean,
  onClick?: () => void
) {
  /* Handle special cases */
  if (isButton && isString(value) && !isNil(onClick)) {
    return (
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={onClick}
      >
        {value}
      </Button>
    );
  } else if (isString(value) && !isNil(intelUrl)) {
    return (
      <IntelDynamicLink link={intelUrl} target="_blank">
        {value}
      </IntelDynamicLink>
    );
  } else if (
    isString(value) &&
    (value.startsWith('http://') || value.startsWith('https://'))
  ) {
    return (
      <CustomLink to={value} target="_blank">
        {value}
      </CustomLink>
    );
  } else if (isString(value) && value.startsWith('well://')) {
    const wellId = value.replace('well://', '');

    return (
      <a
        href={`${import.meta.env.VITE_APP_BASE_URL}/wells/${wellId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {value}
      </a>
    );
  } else {
    /* Handle domain types */
    return formatDomainValue({
      value,
      unitSystem,
      displaySymbol: inlineSymbol,
    });
  }
}

export function obfuscate(value: unknown): string {
  return formatDomainValue({ value }).replace(/./g, 'X');
}
