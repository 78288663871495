import { dependencies } from '@pn/core/dependencies';
import type { DataItem, DataItemId } from '@pn/core/domain/data';
import { isGeoShape } from '@pn/core/domain/geography';
import {
  LayerType,
  type Layer,
  type LayerStyle,
} from '@pn/core/domain/layer';
import { getMapDataFields } from '@pn/core/domain/query';
import type { UnitSystem } from '@pn/core/domain/types';
import {
  generateSelectionStyle,
  getBeforeItemsLayerIds,
  isStreamableItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import {
  formatDomainValue,
  getRawDomainValue,
} from '@pn/core/utils/format';
import { arraysShareSameValues } from '@pn/core/utils/logic';
import assert from 'assert';
import isEqual from 'fast-deep-equal';
import { isEmpty, isNil } from 'lodash-es';

const hiddenIds: Record<string, Record<string, Set<DataItemId>>> = {};

export function syncVectorFeaturesState(
  item: WorkspaceItem,
  dataItemIds = new Set<DataItemId>()
): void {
  const { map } = dependencies;
  const { id, dataType } = item;

  assert(item.sourceItem, 'sourceItem is required for syncing');

  if (!hiddenIds[dataType]) hiddenIds[dataType] = {};
  if (!hiddenIds[dataType][id]) hiddenIds[dataType][id] = new Set();

  hiddenIds[dataType][id] = dataItemIds;

  const idsToHide = Object.values(hiddenIds[dataType]).reduce<DataItemId[]>(
    (acc, ids) => {
      return [...acc, ...Array.from(ids)];
    },
    []
  );

  item.sourceItem.map.layers.forEach((layer) => {
    if (isEmpty(idsToHide)) {
      map.filterLayer({
        layerId: layer.id,
        key: 'hide_ids',
        filter: undefined,
      });
    } else {
      map.filterLayer({
        layerId: layer.id,
        key: 'hide_ids',
        filter: ['!in', 'internal_id', ...idsToHide],
      });
    }
  });
}

const layersParams = new Map<
  Layer['id'],
  {
    type: LayerType;
    style: LayerStyle;
    mapDataFields: string[];
    mapDataItems: DataItem[];
    unitSystem: UnitSystem;
  }
>();

export async function processLayers(
  item: WorkspaceItem,
  beforeItems: WorkspaceItem[],
  params: {
    mapDataItems: DataItem[];
    unitSystem: UnitSystem;
  }
): Promise<void> {
  const { mapDataItems, unitSystem } = params;
  const { map } = dependencies;

  const fitToTileset = isStreamableItem(item);
  const mapDataFields = getMapDataFields(item);

  for (let i = 0; i < item.map.layers.length; i++) {
    const layer = item.map.layers[i];
    const index = i;

    const beforeLayerIds = getBeforeItemsLayerIds(beforeItems);
    const style = isStreamableItem(item)
      ? getFormattedStyle(layer.style, layer.type)
      : layer.style;
    const selectionStyle = generateSelectionStyle(style, layer.type);

    if (map.hasLayer(layer.id)) {
      const previousParams = layersParams.get(layer.id);

      const hasLayerTypeChanged = previousParams?.type !== layer.type;
      if (hasLayerTypeChanged) {
        map.removeDataLayer(layer);
        map.addDataLayer(layer, {
          beforeLayerIds,
          sourceLayer: item.sourceItem?.map.layers[index],
          style,
          selectionStyle,
        });

        if (isGeoJsonLayer(layer)) {
          const data = generateLayerData({
            layerType: layer.type,
            mapDataItems,
            sourceField: layer.sourceField,
            unitSystem,
          });
          map.updateLayerData({
            layer,
            data,
            fitToTileset,
          });
        }
      } else {
        const hasLayerStyleChanged = !isEqual(previousParams?.style, style);
        if (hasLayerStyleChanged) {
          map.updateDataLayerStyle(layer, {
            style,
            selectionStyle,
          });
        }
      }

      map.moveDataLayer(layer, beforeLayerIds);

      const hasMapDataChanged =
        previousParams?.unitSystem !== unitSystem ||
        !arraysShareSameValues(previousParams?.mapDataFields, mapDataFields) ||
        haveIdsChanged(previousParams?.mapDataItems, mapDataItems);

      if (isGeoJsonLayer(layer) && hasMapDataChanged) {
        const data = generateLayerData({
          layerType: layer.type,
          mapDataItems,
          sourceField: layer.sourceField,
          unitSystem,
        });
        map.updateLayerData({
          layer,
          data,
          fitToTileset,
        });
      }
    } else {
      map.addDataLayer(layer, {
        beforeLayerIds,
        sourceLayer: item.sourceItem?.map.layers[index],
        style,
        selectionStyle,
      });

      if (isGeoJsonLayer(layer)) {
        const data = generateLayerData({
          layerType: layer.type,
          mapDataItems,
          sourceField: layer.sourceField,
          unitSystem,
        });
        map.updateLayerData({
          layer,
          data,
          fitToTileset,
        });
      }
    }

    layersParams.set(layer.id, {
      type: layer.type,
      style,
      mapDataFields,
      mapDataItems,
      unitSystem,
    });
  }
}

export function removeLayers(layers: Layer[]): void {
  const { map } = dependencies;

  layers.forEach((layer) => {
    if (!map.hasLayer(layer.id)) return;

    map.removeDataLayer(layer);
  });
}

function isGeoJsonLayer(layer: Layer): boolean {
  return layer.source.type === 'geojson';
}

function haveIdsChanged(
  prevDataItems: DataItem[],
  newDataItems: DataItem[]
): boolean {
  if (prevDataItems.length !== newDataItems.length) {
    return true;
  }

  for (let i = 0; i < prevDataItems.length; i++) {
    if (prevDataItems[i]._id !== newDataItems[i]._id) {
      return true;
    }
  }

  return false;
}

function getFormattedKey(key: string): string {
  return `${key}:formatted`;
}

function generateLayerData(params: {
  layerType: LayerType;
  mapDataItems: DataItem[];
  sourceField?: string;
  unitSystem: UnitSystem;
}): DataItem[] {
  const { layerType, mapDataItems, sourceField, unitSystem } = params;

  const isTextLayer = layerType === LayerType.Text;

  const dataItems = mapDataItems.flatMap((item) => {
    const geoShape = sourceField ? item[sourceField] : item.geoShape;
    if (isNil(geoShape) || !isGeoShape(geoShape)) return [];

    const transformedItem: DataItem = { _id: item._id, geoShape };
    for (const key in item) {
      if (key === '_id' || key === 'geoShape') continue;

      transformedItem[key] = getRawDomainValue({ value: item[key] });
      if (isTextLayer) {
        transformedItem[getFormattedKey(key)] = formatDomainValue({
          value: item[key],
          unitSystem,
          displaySymbol: true,
        });
      }
    }

    return [transformedItem];
  });

  return dataItems;
}

/**
 * Modify the `field` property to fetch formatted values.
 */
function getFormattedStyle(
  style: LayerStyle,
  layerType: LayerType
): LayerStyle {
  return Object.entries(style).reduce<Record<string, unknown>>(
    (acc, [key, value]) => {
      if (layerType === LayerType.Text && key === 'field') {
        acc[key] = ['get', getFormattedKey((value as string[])[1])];
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {}
  );
}
