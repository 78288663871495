type ExportOption = {
  dataType: string;
  name: string;
  type: string;
  description: string;
  format: string;
};

export const multiExportOptions: ExportOption[] = [
  // Wells
  {
    dataType: 'wells',
    name: 'Wells Info',
    type: 'export_well_data',
    description: 'Well Header summary.',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Wells Reports',
    type: 'export_well_files',
    description: 'Separate CSV with Well Details for each well.',
    format: 'zip',
  },
  {
    dataType: 'wells',
    name: 'Basic Production',
    type: 'export_production_data',
    description: 'Daily and monthly Oil, Gas, Water, Water Injection, and Steam Injection volumes.',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'All Production',
    type: 'export_production_v2_data',
    description: 'Monthly volumes for all types of production.',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Production Summaries',
    type: 'export_production_summaries',
    description: 'Cumulative production volumes.',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Val Nav Production',
    type: 'export_valnav_production_data',
    description: 'Val Nav-compatible production export.',
    format: 'mer',
  },
  {
    dataType: 'wells',
    name: 'Mosaic Data',
    type: 'export_mosaic_data',
    description: 'Mosaic-compatible production export.',
    format: 'xlsx',
  },
  {
    dataType: 'wells',
    name: 'Gas Analysis',
    type: 'export_gas_analysis',
    description: 'No description.',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Casings',
    type: 'export_casings_data',
    description: 'No description.',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Cement',
    type: 'export_cement_data',
    description: 'No description.',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Completions',
    type: 'export_completions_data',
    description: 'No description.',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Tops',
    type: 'export_formations_data',
    description: 'No description.',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Directionals',
    type: 'export_directionals_data',
    description: 'No description.',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Pressure Data',
    type: 'export_pressure_data',
    description: 'No description.',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Drilling Details',
    type: 'export_drilling_data',
    description: 'No description.',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Drilling Fluid Report',
    type: 'export_drilling_fluid_report',
    description: 'No description.',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'LAS Files',
    type: 'export_las_files',
    description: 'No description.',
    format: 'zip',
  },
  {
    dataType: 'wells',
    name: 'Core Analysis',
    type: 'export_digital_core_data',
    description: 'No description.',
    format: 'csv',
  },
  {
    dataType: 'wells',
    name: 'Wells Shapefile',
    type: 'export_wells_shapefile',
    description: 'No description.',
    format: 'zip',
  },
  {
    dataType: 'wells',
    name: 'Wells Paths Shapefile',
    type: 'export_well_paths_shapefile',
    description: 'No description.',
    format: 'zip',
  },
  // Wells USA
  {
    dataType: 'wells_usa',
    name: 'Wells Info',
    type: 'export_well_data',
    description: 'Well Header summary.',
    format: 'csv',
  },
  {
    dataType: 'wells_usa',
    name: 'Basic Production',
    type: 'export_production_data',
    description: 'Daily and monthly Oil, Gas, Water, and Injection volumes.',
    format: 'csv',
  },
  // Pipelines
  {
    dataType: 'pipelines',
    name: 'Pipelines Info',
    type: 'export_pipeline_data',
    description: 'Pipeline Header summary.',
    format: 'csv',
  },
  {
    dataType: 'pipelines',
    name: 'Pipelines GeoJSON',
    type: 'export_pipelines_geojson',
    description: 'No description.',
    format: 'geojson',
  },
  {
    dataType: 'pipelines',
    name: 'Pipelines Shapefile',
    type: 'export_pipelines_shapefile',
    description: 'No description.',
    format: 'zip',
  },
  // Facilities
  {
    dataType: 'facilities',
    name: 'Facilities Info',
    type: 'export_facility_data',
    description: 'Facility Header summary.',
    format: 'csv',
  },
  {
    dataType: 'facilities',
    name: 'Facilities Shapefile',
    type: 'export_facilities_shapefile',
    description: 'No description.',
    format: 'zip',
  },
  // Crown Land
  {
    dataType: 'mineral_rights',
    name: 'Mineral Rights Info',
    type: 'export_land_data',
    description: 'Mineral Rights summary.',
    format: 'csv',
  },
  {
    dataType: 'mineral_rights',
    name: 'Mineral Rights by Land ID',
    type: 'export_land_by_land_id_data',
    description: 'Mineral Rights summary by Land ID.',
    format: 'csv',
  },
  {
    dataType: 'mineral_rights',
    name: 'Crown Land Shapefile',
    type: 'export_mineral_rights_shapefile',
    description: 'No description.',
    format: 'zip',
  },
];
