import { isArray, isNil, isObject } from 'lodash-es';
import murmurHash3 from 'murmurhash3js';

export const murmurhash3 = function (str: string, seed = 0): number {
  return murmurHash3.x86.hash32(str, seed);
};

type NestedValue =
  | string
  | number
  | boolean
  | null
  | undefined
  | NestedObject
  | NestedArray;
type NestedArray = NestedValue[];
type NestedObject = { [key: string]: NestedValue };

/**
 * @note
 * This function sorts the arrays contents as well but will not process the
 * objects within those arrays.
 */
function sortObject(obj: NestedValue): NestedValue {
  if (isNil(obj) || !isObject(obj)) {
    return obj;
  }

  if (isArray(obj)) {
    const sortedArray = (obj as NestedArray).map(sortObject) as NestedArray;
    return sortedArray.sort(); // add your own comparison function here if needed
  }

  const sortedKeys = Object.keys(obj as NestedObject).sort();
  const result: NestedObject = {};
  sortedKeys.forEach((key) => {
    result[key] = sortObject((obj as NestedObject)[key]);
  });

  return result;
}

export function hashObject(obj: object): number {
  const str = JSON.stringify(obj);
  const hash = murmurhash3(str);

  return hash;
}

export function hashSortedObject(obj: NestedObject): number {
  const sortedObject = sortObject(obj);
  const str = JSON.stringify(sortedObject);
  const hash = murmurhash3(str);

  return hash;
}
