import FolderIcon from '@mui/icons-material/Folder';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import LinkIcon from '@mui/icons-material/Link';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Checkbox, IconButton, lighten, Typography } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import type { Project } from '@pn/core/domain/project';
import { formatISODateTimeStringAsDate } from '@pn/core/utils/date';
import { isNil } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import type { LibraryProject, LibraryRow } from '../types';

const useStyles = makeStyles()((theme) => ({
  cellWithActions: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    width: '100%',
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  projectIconButton: {
    display: 'none',
  },
  multiRowCell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    marginTop: 2,
    lineHeight: theme.typography.body2.lineHeight,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  sourceIcon: {
    width: 16,
    height: 16,
    marginRight: theme.spacing(1),
    marginTop: 1,
    color: lighten(theme.palette.text.secondary, 0.15),
  },
  sourceText: {
    display: 'inline-flex',
    verticalAlign: 'bottom',
  },
  countCell: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export function useLibraryColumns(params: {
  libraryProjectSelected: LibraryProject | undefined;
  onCheckboxChange: (checked: boolean, row: LibraryRow) => void;
  onHeaderCheckboxChange: (checked: boolean) => void;
  onOpenProjectDialog: (project: Project) => void;
}) {
  const {
    libraryProjectSelected,
    onCheckboxChange,
    onHeaderCheckboxChange,
    onOpenProjectDialog,
  } = params;
  const { classes, cx } = useStyles();

  const columns = React.useMemo((): GridColDef<LibraryRow>[] => {
    return [
      {
        field: '_checked',
        headerName: '',
        headerAlign: 'center',
        headerClassName: 'checkbox-header',
        width: 60,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true,
        filterable: false,
        sortable: false,
        resizable: false,
        renderCell: ({ row }) => {
          return (
            <div onClick={(event) => event.stopPropagation()}>
              <Checkbox
                disabled={row._disabled}
                checked={row._checked === 'checked'}
                indeterminate={row._checked === 'indeterminate'}
                onChange={(_event, checked) => onCheckboxChange(checked, row)}
              />
            </div>
          );
        },
        renderHeader: () => {
          if (isNil(libraryProjectSelected)) return null;

          return (
            <Checkbox
              indeterminate={
                libraryProjectSelected._checked === 'indeterminate'
              }
              checked={libraryProjectSelected._checked === 'checked'}
              onChange={(_event, checked) => onHeaderCheckboxChange(checked)}
            />
          );
        },
      },
      {
        field: 'style',
        headerName: '',
        display: 'flex',
        width: 60,
        align: 'center',
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        resizable: false,
        renderCell: ({ row }) => {
          switch (row.rowType) {
            case 'project':
              return <FolderIcon color="action" />;
            case 'annotation':
              return <PaletteOutlinedIcon color="action" />;
            default:
              return <LayersOutlinedIcon color="action" />;
          }
        },
      },
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 300,
        flex: 3,
        renderCell: ({ row }) => {
          if (row.rowType === 'project') {
            return (
              <span className={classes.cellWithActions}>
                <span className={classes.name}>{row.name}</span>{' '}
                <IconButton
                  className={cx(
                    classes.projectIconButton,
                    'project-icon-button'
                  )}
                  onClick={(event) => {
                    event.stopPropagation();
                    onOpenProjectDialog(row.source);
                  }}
                >
                  <SettingsIcon fontSize="small" color="action" />
                </IconButton>
              </span>
            );
          } else if (row.source.description) {
            return (
              <div className={classes.multiRowCell}>
                {row.source.name}
                <Typography variant="body2" color="textSecondary">
                  {row.source.description}
                </Typography>
              </div>
            );
          } else if (row.source.sourceItem) {
            return (
              <div className={classes.multiRowCell}>
                <span>{row.source.name}</span>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.sourceText}
                >
                  <LinkIcon className={classes.sourceIcon} />
                  {row.source.sourceItem.name}
                </Typography>
              </div>
            );
          } else {
            return row.name;
          }
        },
      },
      {
        field: 'count',
        headerName: 'Count',
        minWidth: 100,
        flex: 1,
        renderCell: ({ row }) => {
          return (
            <Box className={classes.countCell}>
              {row.rowType === 'project' && (
                <LayersOutlinedIcon fontSize="small" color="action" />
              )}
              {row.count?.toLocaleString() ?? '-'}
            </Box>
          );
        },
      },
      {
        field: 'origin',
        headerName: 'Origin',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'updatedAt',
        headerName: 'Last Modified',
        minWidth: 150,
        flex: 1,
        valueFormatter: (value: string) => {
          if (value === '1970-01-01T00:00:00.000Z') return '-';
          return formatISODateTimeStringAsDate(value);
        },
      },
    ];
  }, [
    classes,
    cx,
    libraryProjectSelected,
    onCheckboxChange,
    onHeaderCheckboxChange,
    onOpenProjectDialog,
  ]);

  return columns;
}
