import { noop } from 'lodash-es';
import React from 'react';
import { MENU_DRAWER_WIDTH } from 'src/web-ui/Main';
import { CustomAppBar } from 'src/web-ui/layout/CustomAppBar';
import { MenuDrawer } from 'src/web-ui/layout/MenuDrawer';

type Props = {
  showSearchbar?: boolean;
  extraTitle?: string;
  isWorkspaceOpen?: boolean;
  setWorkspaceOpen?: (open: boolean) => void;
};

export const Header = React.memo(_Header);
function _Header({
  showSearchbar = false,
  extraTitle,
  isWorkspaceOpen = false,
  setWorkspaceOpen = noop,
}: Props) {
  const [menuDrawerOpen, setMenuDrawerOpen] = React.useState(false);

  const onMenuOpen = React.useCallback(
    () => setMenuDrawerOpen(true),
    [setMenuDrawerOpen]
  );
  const onMenuClose = React.useCallback(
    () => setMenuDrawerOpen(false),
    [setMenuDrawerOpen]
  );

  const toggleMenuDrawer = React.useCallback(
    () => setMenuDrawerOpen(!menuDrawerOpen),
    [menuDrawerOpen, setMenuDrawerOpen]
  );

  return (
    <>
      <CustomAppBar
        showSearchbar={showSearchbar}
        isWorkspaceOpen={isWorkspaceOpen}
        extraTitle={extraTitle}
        toggleMenuDrawer={toggleMenuDrawer}
        setWorkspaceOpen={setWorkspaceOpen}
      />

      <MenuDrawer
        open={menuDrawerOpen}
        drawerWidth={MENU_DRAWER_WIDTH}
        onOpen={onMenuOpen}
        onClose={onMenuClose}
      />
    </>
  );
}
