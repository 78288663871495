import { lowerCase } from 'lodash-es';
import { compareTwoStrings } from 'string-similarity';

const DEFAULT_TOLERANCE = 0.6;

export function isCloseMatch(
  queryString: string,
  comparisonString: string,
  tolerance = DEFAULT_TOLERANCE
) {
  const lowerCaseQueryStr = lowerCase(queryString);
  const lowerCaseCompareStr = lowerCase(comparisonString);

  const queryStringIsSubsetOfComparisonString =
    lowerCaseQueryStr.search(lowerCaseCompareStr) !== -1;
  if (queryStringIsSubsetOfComparisonString) return true;

  if (tolerance >= 0 && tolerance <= 1) {
    const queryStringIsSimilarToComparisonString =
      compareTwoStrings(lowerCaseQueryStr, lowerCaseCompareStr) > tolerance;
    if (queryStringIsSimilarToComparisonString) return true;
  } else {
    throw new Error(
      'isCloseMatch error! Tolerance value must be a decimal between 0 and 1 inclusive.'
    );
  }

  return false;
}

export function hasOnlyDigits(value: string): boolean {
  return /^-?\d+$/.test(value);
}

/**
 * Quick hash. Not cryptographically secure.
 * @link https://stackoverflow.com/a/15710692/5309948
 */
export function stringHash(str: string) {
  return str
    .split('')
    .reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0)
    .toString();
}

/**
 * Simple string comparison function.
 * @param string1
 * @param string2
 * @returns A fraction between 0 and 1 representing the similarity between the
 * two strings.
 */
export function compareStrings(string1: string, string2: string): number {
  return compareTwoStrings(string1, string2); // doing this to avoid a depenedency on string-similarity in non-core packages
}

/**
 * Takes 2 strings and returns true if string 1 is a subset of string 2 or if
 * string 2 is a subset of string 1.
 */
export function isSubsetOf(string1: string, string2: string): boolean {
  const string1Lower = string1.toLowerCase();
  const string2Lower = string2.toLowerCase();

  return (
    string1Lower.includes(string2Lower) || string2Lower.includes(string1Lower)
  );
}

/**
 * The first copy will get (1) appended to the name.
 * The next copies will increment the number inside the parentheses.
 */
export function generateCopyName(name: string): string {
  const regex = /\((\d+)\)/;
  const match = regex.exec(name);
  if (match) {
    const number = Number(match[1]);
    return name.replace(regex, `(${number + 1})`);
  }
  return `${name} (1)`;
}