import type { Company } from '@pn/core/domain/intel/company';
import { findOrThrow } from '@pn/core/utils/logic';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { isArray, uniq, uniqBy } from 'lodash-es';

export type State = {
  isFetching: boolean;
  isError: boolean;
  companies: Company[];
  checkedCompanyIds: Company['id'][];
  omittedCompanyIds: Company['id'][];
  selectedCompanyId: Company['id'] | undefined;
};

const initialState: State = {
  isFetching: false,
  isError: false,
  companies: [],
  checkedCompanyIds: [],
  omittedCompanyIds: [],
  selectedCompanyId: undefined,
};

export const createCompaniesSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      request: (state) => {
        state.isFetching = true;
        state.isError = false;
      },
      receive: (state, { payload }: PayloadAction<Company[]>) => {
        state.companies = payload;
        state.isFetching = false;
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
      },
      reset: (state) => {
        state.isFetching = false;
        state.isError = false;
        state.companies = [];
      },
      check: (state, { payload }) => {
        state.checkedCompanyIds = uniq([
          ...payload,
          ...state.checkedCompanyIds,
        ]);
      },
      uncheck: (state, { payload }) => {
        state.checkedCompanyIds = state.checkedCompanyIds.filter(
          (layerId) => !payload.includes(layerId)
        );
      },
      omit: (state, { payload }) => {
        state.omittedCompanyIds = uniq([
          ...payload,
          ...state.omittedCompanyIds,
        ]);
      },
      include: (state, { payload }) => {
        state.omittedCompanyIds = state.omittedCompanyIds.filter(
          (companyId) => !payload.includes(companyId)
        );
      },
      uncheckAll: (state) => {
        state.checkedCompanyIds = [];
      },
      includeAll: (state) => {
        state.omittedCompanyIds = [];
      },
      select: (state, { payload }) => {
        state.selectedCompanyId = payload;
      },
      unselect: (state) => {
        state.selectedCompanyId = undefined;
      },
      load: (state, { payload }: PayloadAction<Company | Company[]>) => {
        if (isArray(payload)) {
          state.companies = uniqBy([...state.companies, ...payload], 'id');
        } else {
          state.companies = uniqBy([...state.companies, payload], 'id');
        }
      },
      update: (
        state,
        {
          payload,
        }: PayloadAction<{ companyId: Company['id']; company: Company }>
      ) => {
        const companyToUpdate = findOrThrow(
          state.companies,
          ({ id }) => id === payload.companyId
        );

        Object.assign(companyToUpdate, payload.company, {
          updatedAt: new Date().toISOString(),
        });
      },
    },
  });

export const companiesSlice = createCompaniesSlice('companies');
