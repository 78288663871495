import type { IMap } from '@pn/core/services/map/ports';
import { addAnnotationLayer } from './methods/addAnnotationLayer';
import { addDataLayer } from './methods/addDataLayer';
import { filterLayer } from './methods/filterLayer';
import { fitToBoundingBox } from './methods/fitToBoundingBox';
import { getScreenshot } from './methods/getScreenshot';
import { hasLayer } from './methods/hasLayer';
import { hideLayer } from './methods/hideLayer';
import { initialize } from './methods/initialize';
import { moveDataLayer } from './methods/moveDataLayer';
import { moveLayer } from './methods/moveLayer';
import { moveTo } from './methods/moveTo';
import { removeAnnotationLayer } from './methods/removeAnnotationLayer';
import { removeDataLayer } from './methods/removeDataLayer';
import { resize } from './methods/resize';
import { setFeatureState } from './methods/setFeatureState';
import { setZoom } from './methods/setZoom';
import { showLayer } from './methods/showLayer';
import { unsetFeatureState } from './methods/unsetFeatureState';
import { updateDataLayerStyle } from './methods/updateDataLayerStyle';
import { updateLayerData } from './methods/updateLayerData';
import { updateMode } from './methods/updateMode';

export type MapboxIMap = IMap<mapboxgl.Map>;

/**
 * Mapbox implementation of Petro Ninja Map core interface.
 *
 * Place every method into a separate file in the ./methods directory.
 * Every property should define its default uninitialized value.
 */
export const mapboxPnMap: MapboxIMap = {
  _native: {} as mapboxgl.Map,

  initialize,

  filters: {},

  hasLayer,

  showLayer,
  hideLayer,
  moveLayer,
  filterLayer,

  setFeatureState,
  unsetFeatureState,

  addDataLayer,
  addAnnotationLayer,
  updateLayerData,
  updateDataLayerStyle,
  moveDataLayer,
  removeDataLayer,
  removeAnnotationLayer,

  updateMode,

  moveTo,
  fitToBoundingBox,
  setZoom,
  resize,

  getScreenshot,
};
