import { findOrThrow } from '@pn/core/utils/logic';
import { createSlice } from '@reduxjs/toolkit';
import { isNil, remove } from 'lodash-es';

type GenericPayloadItem = Record<string, unknown>;
type State<T> = {
  isFetching: boolean;
  isError: boolean;
  payload: T | undefined;
};

const createInitialState = <T>(): State<T> => ({
  isFetching: false,
  isError: false,
  payload: undefined,
});

/**
 * May not be possible to do partial type inference? Will investigate later.
 * @link https://stackoverflow.com/a/60378737/5309948
 */
export const createGenericPayloadSlice = <T extends GenericPayloadItem[]>(
  sliceName: string
) =>
  createSlice({
    name: sliceName,
    initialState: createInitialState<T>(),
    reducers: {
      request: (state) => {
        state.isFetching = true;
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
      },
      receive: (state, { payload }) => {
        state.isFetching = false;
        state.isError = false;
        state.payload = payload;
      },
      reset: (state) => {
        state.isFetching = false;
        state.isError = false;
        state.payload = undefined;
      },
      create: (state, { payload: payloadItem }) => {
        if (isNil(state.payload)) throw new Error('Payload not loaded');

        state.payload.push(payloadItem);
      },
      update: (state, { payload: { payloadItemId, payloadItem } }) => {
        if (isNil(state.payload)) throw new Error('Payload not loaded');

        const item = findOrThrow(
          state.payload,
          ({ id }) => id === payloadItemId
        );
        Object.assign(item, payloadItem);
      },
      updateId: (state, { payload: { currentId, newId } }) => {
        if (isNil(state.payload)) throw new Error('Payload not loaded');

        state.payload.find(({ id }) => id === currentId)!.id = newId;
      },
      remove: (state, { payload: payloadItemId }) => {
        if (isNil(state.payload)) throw new Error('Payload not loaded');

        remove(state.payload, ({ id }) => id !== payloadItemId);
      },
    },
  });
