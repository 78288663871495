import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import InfoIcon from '@mui/icons-material/Info';
import LockIcon from '@mui/icons-material/Lock';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import type { Project } from '@pn/core/domain/project';
import { getFullName, isEnterpriseUser } from '@pn/core/domain/user';
import { WorkspaceItem } from '@pn/core/domain/workspace';
import { isEditable } from '@pn/core/permissions/editability';
import {
  projectsActions,
  useChannelsStorage,
  useCurrentUserStorage,
  useWorkspaceStorage,
} from '@pn/core/storage';
import { generateCopyName } from '@pn/core/utils/string';
import { useConfirmationDialog } from '@pn/ui/context-components/ConfirmationDialog';
import { NotificationTooltip } from '@pn/ui/custom-components/NotificationTooltip';
import { useCopyToClipboard } from '@pn/ui/hooks/useCopyToClipboard';
import { useMenu } from '@pn/ui/hooks/useMenu';
import assert from 'assert';
import { format, parseISO } from 'date-fns';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';
import { notify } from 'src/application/externalDependencies';
import { QuickBrowser } from 'src/web-ui/components/QuickBrowser';
import { useProjectDialog } from 'src/web-ui/project/ProjectDialogProvider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  dialogPaper: {
    background: theme.palette.background.paper,
  },
  dialogTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  listItem: {
    height: 52,
  },
  iconButton: {
    padding: theme.spacing(1.25),
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  selectBox: {
    width: 280,
  },
  quickBrowserContainer: {
    height: 312,
    border: `1px solid ${theme.palette.divider}`,
    borderTop: 'none',
  },
  projectName: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
}));

export const ProjectDialog = React.memo(_ProjectDialog);
function _ProjectDialog() {
  const { classes } = useStyles();

  const { isProjectDialogOpen, projectToEdit, closeProjectDialog } =
    useProjectDialog();

  const { user } = useCurrentUserStorage();
  const { workspaceItems, allWorkspaceItems } = useWorkspaceStorage();
  const { channels } = useChannelsStorage();

  const { handleOpenConfirmationDialog } = useConfirmationDialog();

  const [name, setName] = React.useState('');
  const [channelId, setChannelId] = React.useState('');
  const [itemIds, setItemIds] = React.useState<string[]>([]);

  const {
    anchorEl,
    handlers: { openMenu, closeMenu },
  } = useMenu();

  React.useEffect(() => {
    if (!isProjectDialogOpen) return;

    if (projectToEdit) {
      setName(projectToEdit.name);
      setChannelId(projectToEdit.channelId ?? '');
      setItemIds(projectToEdit.workspaceItemIds);
    } else {
      setName('');
      setChannelId('');
      setItemIds(
        workspaceItems.filter((i) => !i.isTemporary).map(({ id }) => id)
      );
    }
  }, [isProjectDialogOpen, projectToEdit, workspaceItems]);

  const isEditMode = !isNil(projectToEdit);
  const isEditingProhibited = isEditMode && !isEditable(projectToEdit, user);

  const projectItems = React.useMemo(() => {
    return itemIds
      .map((id) => allWorkspaceItems.find((i) => i.id === id))
      .filter((i): i is NonNullable<typeof i> => !isNil(i));
  }, [allWorkspaceItems, itemIds]);

  const { copyText } = useCopyToClipboard();

  const handleAddItem = React.useCallback((itemId: WorkspaceItem['id']) => {
    setItemIds((prev) => [...prev, itemId]);
  }, []);
  const handleRemoveItem = (itemId: WorkspaceItem['id']) => {
    setItemIds((prev) => prev.filter((id) => id !== itemId));
  };

  const handleDuplicate = () => {
    assert(user, 'User must be defined to duplicate projects');
    assert(projectToEdit, 'Project to edit must be defined to duplicate it');

    projectsActions().create(
      {
        name: generateCopyName(projectToEdit.name),
        channelId: undefined, // duplicated projects are not shared by default
        workspaceItemIds: projectToEdit.workspaceItemIds,
      },
      user
    );

    notify('Project duplicated!');

    closeMenu();
    closeProjectDialog();
  };

  const handleDeleteWithConfirmation = () => {
    assert(projectToEdit, 'Project to edit must be defined to delete it');

    closeMenu();

    handleOpenConfirmationDialog({
      title: 'Delete project?',
      text: (
        <Typography>
          Are you sure you want to delete{' '}
          <span className={classes.projectName}>{projectToEdit.name}</span>?
          This action cannot be undone.
        </Typography>
      ),
      action: () => {
        projectsActions().remove(projectToEdit.id);

        closeProjectDialog();
      },
    });
  };

  const handleSubmit = () => {
    if (isEditMode) {
      projectsActions().update({
        ...projectToEdit,
        name: name.trim(),
        channelId: isEmpty(channelId) ? undefined : channelId,
        workspaceItemIds: itemIds,
      });
    } else {
      assert(user, 'User must be defined to create projects');

      projectsActions().create(
        {
          name: name.trim(),
          channelId: isEmpty(channelId) ? undefined : channelId,
          workspaceItemIds: itemIds,
        },
        user
      );

      notify('Project created!');
    }

    closeProjectDialog();
  };

  if (!isProjectDialogOpen) {
    return null;
  }

  return (
    <Dialog
      fullWidth
      open={isProjectDialogOpen}
      classes={{ paper: classes.dialogPaper }}
      onClose={closeProjectDialog}
    >
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        slotProps={{
          paper: {
            style: {
              maxHeight: 48 * 4.5,
              width: '20ch',
            },
          },
        }}
      >
        <MenuItem
          disabled={isNil(projectToEdit) || projectToEdit.isGlobal}
          onClick={handleDuplicate}
        >
          <ListItemIcon>
            <CopyAllIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={isEditingProhibited}
          onClick={handleDeleteWithConfirmation}
        >
          <ListItemIcon>
            <DeleteForeverIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      <Box className={classes.dialogTitleContainer}>
        <DialogTitle>
          {isEditingProhibited
            ? 'Project'
            : isEditMode
              ? 'Edit Project'
              : 'New Project'}
        </DialogTitle>

        {isEditMode && (
          <Box mr={2}>
            <IconButton onClick={openMenu}>
              <MoreVertIcon />
            </IconButton>
          </Box>
        )}
      </Box>

      <DialogContent dividers className={classes.dialogContent}>
        <List dense disablePadding>
          <ListItem disableGutters disablePadding className={classes.listItem}>
            <ListItemIcon>
              {isNil(projectToEdit) ? (
                <HistoryToggleOffIcon className={classes.infoIcon} />
              ) : isEditingProhibited ? (
                <LockIcon className={classes.infoIcon} />
              ) : (
                <PersonIcon className={classes.infoIcon} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={getInfoText(projectToEdit, isEditingProhibited).primary}
              secondary={
                getInfoText(projectToEdit, isEditingProhibited).secondary
              }
            />
          </ListItem>

          {!isNil(projectToEdit) && (
            <ListItem
              disableGutters
              disablePadding
              className={classes.listItem}
            >
              <ListItemIcon>
                <NotificationTooltip
                  title="Copied project URL to clipboard"
                  placement="left"
                >
                  <IconButton
                    aria-label="copy project URL"
                    className={classes.iconButton}
                    color="info"
                    onClick={() =>
                      copyText(
                        import.meta.env.VITE_APP_BASE_URL +
                          '?projectId=' +
                          encodeURIComponent(projectToEdit.id)
                      )
                    }
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </NotificationTooltip>
              </ListItemIcon>
              <ListItemText
                primary={
                  import.meta.env.VITE_APP_BASE_URL +
                  '?projectId=' +
                  encodeURIComponent(projectToEdit.id)
                }
              />
            </ListItem>
          )}
        </List>

        {isEnterpriseUser(user) && (
          <Box display="flex" alignItems="center">
            <FormControl
              size="small"
              className={classes.selectBox}
              disabled={isEditingProhibited}
            >
              <InputLabel id="project-channel-selector-label">Share</InputLabel>
              <Select
                labelId="project-channel-selector-label"
                label="Share"
                value={channelId}
                onChange={(e) => setChannelId(e.target.value)}
              >
                <MenuItem value="">
                  <em>Personal (not shared)</em>
                </MenuItem>
                {channels.map((c) => (
                  <MenuItem value={c.id} key={c.id}>
                    {c.name === 'General' ? 'General (everyone)' : c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box ml={2} />
            <Tooltip
              title={`You can share your project with other members of ${
                user!.enterprise!.name
              }. Only you will be able to edit/delete it.`}
            >
              <InfoIcon color="action" />
            </Tooltip>
          </Box>
        )}

        <TextField
          fullWidth
          size="small"
          autoComplete="off"
          variant="outlined"
          label="Name"
          value={name}
          disabled={isEditingProhibited}
          error={isEmpty(name) || name.length > 100}
          helperText={
            isEmpty(name)
              ? 'Name is required'
              : name.length > 100
                ? 'Max 100 characters'
                : undefined
          }
          onChange={(e) => setName(e.target.value)}
        />

        <Paper variant="outlined" className={classes.quickBrowserContainer}>
          <QuickBrowser
            disabled={isEditingProhibited}
            itemIdsToExclude={itemIds}
            onAddItem={handleAddItem}
          />
        </Paper>

        <Box display="flex" flexWrap="wrap" gap={1}>
          {projectItems.map(({ id, name, module }) => (
            <Chip
              key={id}
              label={name}
              disabled={!isNil(module) || isEditingProhibited}
              onDelete={() => handleRemoveItem(id)}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeProjectDialog}>Close</Button>
        {!isEditingProhibited && (
          <Button color="primary" onClick={handleSubmit}>
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

function getInfoText(
  project: Project | undefined,
  isEditingProhibited: boolean
): { primary: string; secondary: string } {
  let primary = '';
  let secondary = '';

  if (isNil(project)) {
    primary = 'You are creating a new project';
    secondary = 'Projects are a way to organize and share your workspace';
  } else if (project.isGlobal) {
    primary = 'Created by Petro Ninja';
    secondary = 'This project cannot be modified';
  } else if (project.origin === 'stackdx') {
    primary = `Last modified on ${format(
      parseISO(project.updatedAt),
      `MMM dd, yyyy 'at' h:mm aa`
    )}`;
    secondary = 'This project can only be edited through StackDX';
  } else if (isEditingProhibited && !isNil(project.createdBy)) {
    primary = `Last modified by ${getFullName(project.createdBy)} on ${format(
      parseISO(project.updatedAt),
      `MMM dd, yyyy 'at' h:mm aa`
    )}`;
    secondary = 'This project can only be edited by its author';
  } else if (isEditingProhibited && isNil(project.createdBy)) {
    primary = `Last modified on ${format(
      parseISO(project.updatedAt),
      `MMM dd, yyyy 'at' h:mm aa`
    )}`;
    secondary = 'This project cannot be edited';
  } else {
    primary = `Last modified on ${format(
      parseISO(project.updatedAt),
      `MMM dd, yyyy 'at' h:mm aa`
    )}`;
    secondary = 'This project can only be edited by you';
  }

  return { primary, secondary };
}
