import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import Box from '@mui/material/Box';
import { dependencies } from '@pn/core/dependencies';
import { useMapStorage } from '@pn/core/storage';
import { useDeviceOrientation } from '@pn/core/utils/device';
import '@pn/services/map/mapbox/mapbox-2.15.0.css';
import clsx from 'clsx';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{
  isInitialized: boolean;
}>()((theme, { isInitialized }) => ({
  map: {
    height: '100%',
    opacity: isInitialized ? 1 : 0.5,
    transition: 'opacity 0.1s',
    touchAction: 'none', // fix for https://stackoverflow.com/questions/42101723/unable-to-preventdefault-inside-passive-event-listener
    '& canvas.mapboxgl-canvas': {
      outline: 'none',
    },
  },
}));

type Props = {
  mapRef: React.RefObject<HTMLDivElement | undefined>;
  borderRadius?: number;
  extraClassName?: string;
};

/**
 * @description This is the main map component. It is responsible for rendering the map.
 * Without this component, the map will not be rendered. To render the map use this component.
 */
export const PNMap = React.memo(_PNMap);
function _PNMap({ mapRef, extraClassName }: Props) {
  const { isInitialized } = useMapStorage();

  const { classes } = useStyles({ isInitialized });

  const deviceOrientation = useDeviceOrientation();

  const isFirstRun = React.useRef(true);
  React.useEffect(() => {
    if (!isInitialized) return;

    /* Skip first run */
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    setTimeout(() => {
      dependencies.map.resize();
    }, 100);
  }, [isInitialized, deviceOrientation]);

  return (
    <Box
      id="pn-map"
      className={clsx('mapboxgl-map', classes.map, extraClassName)}
      ref={mapRef}
    />
  );
}
