import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { removeLayers } from '@pn/core/operations/workspace/layersProcessing';
import {
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import assert from 'assert';
import { isNil } from 'lodash-es';
import React from 'react';
import { useLibrary } from './LibraryStateProvider';

type ContextType = {
  isWorkspaceItemPanelOpen: boolean;
  liveItemOpened: WorkspaceItem | undefined;
  annotationToEdit: WorkspaceItem | undefined;
  setAnnotationToEdit: (item: WorkspaceItem | undefined) => void;
  tab: 'Content' | 'Style';
  setTab: (tab: 'Content' | 'Style') => void;
  openWorkspaceItemPanel: (params: {
    item: WorkspaceItem;
    tab?: 'Content' | 'Style';
    openAnnotationInterface?: () => void; // HACK annotation context is not defined here
  }) => void;
  closeWorkspaceItemPanel: (skipReset?: boolean) => void;
};

const WorkspaceItemPanelContext = React.createContext({} as ContextType);
export const useWorkspaceItemPanel = () =>
  React.useContext(WorkspaceItemPanelContext);

type ContextProviderProps = {
  children: React.ReactNode;
};

export const WorkspaceItemPanelStateProvider = ({
  children,
}: ContextProviderProps) => {
  const { allWorkspaceItems } = useWorkspaceStorage();

  const [isOpen, setIsOpen] = React.useState(false);
  const [itemOpened, setItemOpened] = React.useState<
    WorkspaceItem | undefined
  >();
  const [annotationToEdit, setAnnotationToEdit] = React.useState<
    WorkspaceItem | undefined
  >();
  const [tab, setTab] = React.useState<'Content' | 'Style'>('Content');

  const { closeLibrary } = useLibrary();

  const liveItemOpened = React.useMemo(() => {
    return allWorkspaceItems.find(({ id }) => id === itemOpened?.id);
  }, [allWorkspaceItems, itemOpened?.id]);

  const openWorkspaceItemPanel = React.useCallback(
    (params: {
      item: WorkspaceItem;
      tab?: 'Content' | 'Style';
      openAnnotationInterface?: () => void;
    }) => {
      const { item, tab: tabOverride, openAnnotationInterface } = params;

      if (!isNil(itemOpened) && liveItemOpened?._unsavedChanges) {
        resetItem(itemOpened, liveItemOpened);
      }

      if (item.itemType === 'annotation') {
        assert(
          openAnnotationInterface,
          'openAnnotationInterface must be defined to open an annotation'
        );

        setAnnotationToEdit(item);
        openAnnotationInterface();
        closeLibrary();

        return;
      }

      setItemOpened(item);
      setIsOpen(true);
      if (tabOverride) setTab(tabOverride);

      workspaceActions().setUnsavedChanges(item.id);
    },
    [closeLibrary, itemOpened, liveItemOpened]
  );

  const closeWorkspaceItemPanel = React.useCallback(
    (skipReset = false) => {
      assert(
        itemOpened && liveItemOpened,
        'itemOpened and liveItemOpened must be defined to close the panel'
      );

      setIsOpen(false);

      if (!skipReset) resetItem(itemOpened, liveItemOpened);
    },
    [itemOpened, liveItemOpened]
  );

  return (
    <WorkspaceItemPanelContext.Provider
      value={{
        isWorkspaceItemPanelOpen: isOpen,
        liveItemOpened,
        annotationToEdit,
        setAnnotationToEdit,
        tab,
        setTab,
        openWorkspaceItemPanel,
        closeWorkspaceItemPanel,
      }}
    >
      {children}
    </WorkspaceItemPanelContext.Provider>
  );
};

/**
 * @param itemOpened - the state of the item when it was first opened
 * @param liveItem - the current state of the item
 */
function resetItem(itemOpened: WorkspaceItem, liveItem: WorkspaceItem): void {
  if (liveItem.isTemporary || liveItem.isGlobal || !isNil(liveItem.module)) {
    return;
  }

  /**
   * Remove layers that were added to the item while the panel was open.
   */
  const diffLayers = liveItem.map.layers.filter(
    (layer) => !itemOpened.map.layers.find(({ id }) => layer.id === id)
  );
  removeLayers(diffLayers);

  /**
   * Reset map configuration and a portion of the query.
   * Using `add` instead of `update` to prevent triggering sync-storage.
   */
  workspaceActions().add({
    ...itemOpened,
    requestedDataItem: liveItem.requestedDataItem,
    query: {
      ...liveItem.query,
      requestedIds: itemOpened.query.requestedIds,
    },
  });
  workspaceActions().revisualize(itemOpened.id);
}
