import { UnitSystem } from '@pn/core/domain/types';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

type State = {
  isInitialized: boolean;
  unitSystem: UnitSystem;
  pageSize: number;
};

const initialState: State = {
  isInitialized: false,
  unitSystem: UnitSystem.Metric,
  pageSize: 5,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    initialize: (state) => {
      state.isInitialized = true;
    },
    updateUnitSystem: (
      state,
      { payload: unitSystem }: PayloadAction<UnitSystem>
    ) => {
      state.unitSystem = unitSystem;
    },
    updatePageSize: (state, { payload: pageSize }: PayloadAction<number>) => {
      state.pageSize = pageSize;
    },
  },
});
