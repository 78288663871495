import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridFilterItem,
  GridFilterOperator,
} from '@mui/x-data-grid-pro';
import { findOrThrow } from '@pn/core/utils/logic';
import { notContainsOperator } from './customOperators';

const gridStringOperators = getGridStringOperators();
const gridDropdownOperators = getGridSingleSelectOperators();
const gridNumericOperators = getGridNumericOperators();
const gridDateOperators = getGridDateOperators();

export const containsOnlyOperator = gridStringOperators.filter((operator) =>
  ['contains'].includes(operator.value)
);

export const equalsOnlyOperator = gridStringOperators.filter((operator) =>
  ['equals'].includes(operator.value)
);

export const defaultStringOperators = gridStringOperators
  .filter((operator) => ['contains'].includes(operator.value))
  .concat(notContainsOperator);

export const defaultDropdownOperators = gridDropdownOperators.filter(
  (operator) => ['is'].includes(operator.value)
);

export const defaultNumericOperators = ['>=', '<=', '='].map((operatorId) =>
  findOrThrow(gridNumericOperators, (o) => o.value === operatorId)
);

export const defaultDateOperators: GridFilterOperator<any, string, any>[] = [
  'onOrAfter',
  'onOrBefore',
  'is',
]
  .map((operatorId) =>
    findOrThrow(gridDateOperators, (o) => o.value === operatorId)
  )
  .map((operator) => ({
    ...operator,
    /**
     * // TODO test this still works
     * We override date filtering since we use strings rather than dates.
     * This only takes effect when the table is in client mode.
     */
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return (value): boolean => {
        const a = Number(value);
        const b = Number(filterItem.value.replace(/-/g, ''));

        switch (value) {
          case 'is':
            return a === b;
          case 'onOrAfter':
            return a >= b;
          case 'onOrBefore':
            return a <= b;
          default:
            throw new Error(
              `Unsupported date filter operator: ${operator.value}`
            );
        }
      };
    },
  }));

export const allOperators = [
  ...defaultStringOperators,
  ...defaultDropdownOperators,
  ...defaultNumericOperators,
  ...defaultDateOperators,
];
