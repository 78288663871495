import { cachePolicy } from '@pn/core/cachePolicy';
import { AUTO_VISUALIZE_LIMIT, STREAMING_LIMIT } from '@pn/core/constants';
import {
  formatDataType,
  type DataItem,
  type MappingItem,
} from '@pn/core/domain/data';
import {
  getVisualizableQuery,
  type StreamableQuery,
} from '@pn/core/domain/query';
import {
  isNonStreamableItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { handleError } from '@pn/core/errors/handleError';
import { dynamicDataProvider } from '@pn/core/providers/data/dynamicDataProvider';
import { isCacheStale } from '@pn/core/utils/cache';
import { log } from '@pn/core/utils/debug';
import { appendArrayInPlace } from '@pn/core/utils/logic';
import { hashSortedObject } from '@pn/services/utils/hash';
import { isEmpty, isNil, omit } from 'lodash-es';
import {
  removePersistentStreamingNotification,
  showPersistentStreamingNotification,
} from './streamingNotification';

const mapDataCache = new Map<number, MapData>();

export const mapDataAbortControllers: Record<
  WorkspaceItem['id'],
  AbortController
> = {};

export type MapData = {
  data: DataItem[];
  timestamp: number;
};

export async function getMapData(params: {
  item: WorkspaceItem;
  mapping: MappingItem[];
  silent?: boolean;
}): Promise<MapData> {
  const { item, mapping, silent } = params;

  if (item.dataSource.type === 'none') {
    return {
      data: [],
      timestamp: 0,
    };
  }

  const mapQuery = getVisualizableQuery(item);

  const hash = getMapQueryHash(mapQuery);
  if (cachePolicy.mapData && mapDataCache.has(hash)) {
    const cacheCandidate = mapDataCache.get(hash)!;
    if (isCacheStale(cacheCandidate.timestamp)) {
      log.info('%cmapDataCache invalidated', 'color: #009688', hash);
      mapDataCache.delete(hash);
    } else {
      log.info('%cmapDataCache hit', 'color: #009688', hash);
      return cacheCandidate;
    }
  }

  const mapData: MapData = {
    data: [],
    timestamp: 0,
  };

  let notificationId: string | undefined = undefined;

  if (isNonStreamableItem(item)) {
    log.info('mapData early exit: non-streamable item');
    /**
     * Do not set the cache for non-streamable items.
     * Doing so breaks the ability to discern between a non-streamable single
     * feature selection vs. a streamable list consisting of that feature.
     */

    return { ...mapData, timestamp: Date.now() };
  }

  try {
    let totalCount = 0;
    mapDataAbortControllers[item.id] = new AbortController();

    log.info('%cfetching mapData from data source', 'color: #009688');
    await dynamicDataProvider(item.dataSource).streamDataByQuery({
      query: mapQuery,
      mapping,
      receiveTotalCount: (count) => {
        totalCount = count;
      },
      receiveChunk: (chunkData) => {
        appendArrayInPlace(mapData.data, chunkData);

        /**
         * Display notification if we are actually streaming data.
         */
        if (!silent && isNil(notificationId)) {
          notificationId = showPersistentStreamingNotification({
            action: 'Streaming',
            dataType: item.dataType,
            totalCount,
            onCancel: () => mapDataAbortControllers[item.id].abort(),
          });
        }
      },
      signal: mapDataAbortControllers[item.id].signal,
    });

    const hasExceededStreamingLimit = totalCount > STREAMING_LIMIT;
    if (hasExceededStreamingLimit) {
      log.info(
        'stream request has exceeded streaming limit of',
        STREAMING_LIMIT.toLocaleString()
      );
    }

    const hasExceededAutoVisualizeLimit =
      !hasExceededStreamingLimit && totalCount > AUTO_VISUALIZE_LIMIT;
    if (hasExceededAutoVisualizeLimit) {
      log.info(
        'stream request has exceeded auto-visualize limit of',
        AUTO_VISUALIZE_LIMIT.toLocaleString()
      );
    }

    if (
      cachePolicy.mapData &&
      (!isEmpty(mapData.data) || hasExceededStreamingLimit)
    ) {
      mapData.timestamp = Date.now();
      mapDataCache.set(hash, mapData);
    }
  } catch (error) {
    handleError({
      error,
      userFriendlyMessage: `Failed to stream ${formatDataType(
        item.dataType
      )} map data`,
    });
  }

  removePersistentStreamingNotification(notificationId);

  return mapData;
}

function getMapQueryHash(mapQuery: StreamableQuery): number {
  return hashSortedObject(omit(mapQuery, 'ignoreLimit'));
}

/**
 * This setup allows to store and return the same mapData for all workspace
 * items with identical visualizable queries.
 * This would not be possible if we used `item.id` instead of a query hash.
 */
function getMapDataByItem(item: WorkspaceItem): MapData | undefined {
  const mapQuery = getVisualizableQuery(item);
  const hash = getMapQueryHash(mapQuery);

  return mapDataCache.get(hash);
}

export function getMapDataItems(item: WorkspaceItem): DataItem[] {
  return getMapDataByItem(item)?.data ?? [];
}

export function clearMapDataByItem(item: WorkspaceItem): void {
  const mapQuery = getVisualizableQuery(item);
  const hash = getMapQueryHash(mapQuery);

  mapDataCache.delete(hash);
}
