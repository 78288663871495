import type { TableTemplate } from '@pn/core/domain/table';
import { findOrThrow } from '@pn/core/utils/logic';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash-es';

export type State = {
  isFetching: boolean;
  isError: boolean;
  wasRequested: boolean;
  defaultTableFields: Record<string, string[]>;
  tableFields: Record<string, string[]>;
  templates: TableTemplate[];
  selectedTemplates: Record<string, TableTemplate>;
};

const initialState: State = {
  isFetching: false,
  isError: false,
  wasRequested: false,
  defaultTableFields: {},
  tableFields: {},
  templates: [],
  selectedTemplates: {},
};

export const createTableSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      request: (state) => {
        state.isFetching = true;
        state.isError = false;
        state.wasRequested = true;
      },
      receive: (
        state,
        { payload: templates }: PayloadAction<TableTemplate[]>
      ) => {
        state.isFetching = false;
        state.isError = false;
        state.templates = templates;
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
      },
      create: (state, { payload: template }: PayloadAction<TableTemplate>) => {
        state.templates.push(template);
      },
      remove: (state, { payload: template }: PayloadAction<TableTemplate>) => {
        state.templates = state.templates.filter((t) => t.id !== template.id);
      },
      select: (state, { payload: template }: PayloadAction<TableTemplate>) => {
        state.selectedTemplates[template.sourceLayerId] = template;
        state.tableFields[template.sourceLayerId] = template.fields;
      },
      updateDefaultTableFields: (
        state,
        {
          payload: { sourceLayerId, fields },
        }: PayloadAction<{ sourceLayerId: string; fields: string[] }>
      ) => {
        state.defaultTableFields[sourceLayerId] = fields;
        if (isEmpty(state.tableFields[sourceLayerId])) {
          state.tableFields[sourceLayerId] = fields;
        }
      },
      updateTableFields: (
        state,
        {
          payload: { sourceLayerId, fields },
        }: PayloadAction<{ sourceLayerId: string; fields: string[] }>
      ) => {
        state.tableFields[sourceLayerId] = fields;
      },
      resetTableFields: (
        state,
        { payload: sourceLayerId }: PayloadAction<string>
      ) => {
        delete state.selectedTemplates[sourceLayerId];
        state.tableFields[sourceLayerId] =
          state.defaultTableFields[sourceLayerId];
      },
      addTableFields: (
        state,
        {
          payload: { sourceLayerId, fields },
        }: PayloadAction<{ sourceLayerId: string; fields: string[] }>
      ) => {
        state.tableFields[sourceLayerId] = [
          ...state.tableFields[sourceLayerId],
          ...fields,
        ];
      },
      removeTableFields: (
        state,
        {
          payload: { sourceLayerId, fields },
        }: PayloadAction<{ sourceLayerId: string; fields: string[] }>
      ) => {
        state.tableFields[sourceLayerId] = state.tableFields[
          sourceLayerId
        ].filter((column) => !fields.includes(column));
      },
      updateId: (
        state,
        {
          payload: { currentId, newId },
        }: PayloadAction<{
          currentId: TableTemplate['id'];
          newId: TableTemplate['id'];
        }>
      ) => {
        findOrThrow(state.templates, ({ id }) => id === currentId).id = newId;
      },
      _replace: (_state, { payload }) => {
        return { ...payload };
      },
    },
  });

export const tableSlice = createTableSlice('table');
