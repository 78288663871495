import { GeoPoint, toGeoPoint } from '@pn/core/domain/geography';
import { tokenManager } from '@pn/core/services/authentication/tokenManager';
import { getCurrentUserHomeLocation } from '@pn/core/storage/user/currentUserStorage';
import { isEmbedded } from '@pn/core/utils/embedded';
import env from '@pn/core/utils/env';
import { isNil } from 'lodash-es';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = env.PN_MAPBOX_ACCESS_TOKEN;

declare module 'mapbox-gl' {
  interface Map {
    geolocate: mapboxgl.GeolocateControl;
  }
}

const lastMapPosition = {
  center: undefined as GeoPoint | undefined,
  zoom: undefined as number | undefined,
};

export const setLastMapPosition = (params: {
  center: GeoPoint;
  zoom: number;
}) => {
  lastMapPosition.center = params.center;
  lastMapPosition.zoom = params.zoom;
};

const getZoomLevel = () =>
  lastMapPosition.zoom ?? (getCurrentUserHomeLocation() ? 9.75 : 9.75);
// lastMapPosition.zoom ?? (getCurrentUserHomeLocation() ? 9.75 : 4.25);
const getHomeLocation = () =>
  lastMapPosition.center ??
  (isEmbedded()
    ? toGeoPoint(55, -114)
    : getCurrentUserHomeLocation() ?? toGeoPoint(55, -114));

export const configureMapboxMap = (
  mapContainer: HTMLElement,
  mapboxStyle: mapboxgl.Style,
  options: {
    zoom?: number;
    center?: GeoPoint;
  }
) => {
  const sw = new mapboxgl.LngLat(-172, 20);
  const ne = new mapboxgl.LngLat(-20, 73);
  const maxBounds = new mapboxgl.LngLatBounds(sw, ne);

  const map = new mapboxgl.Map({
    container: mapContainer,
    style: mapboxStyle,
    center: isNil(options?.center) ? getHomeLocation() : options.center,
    zoom: isNil(options?.zoom) ? getZoomLevel() : options.zoom,
    maxZoom: 18,
    boxZoom: false,
    maxBounds,
    preserveDrawingBuffer: true,
    dragRotate: false,
    touchPitch: false,
    touchZoomRotate: true,
    // @ts-expect-error `useWebGL2` does exist despite what the types say
    useWebGL2: true,
    // fadeDuration: 0,

    transformRequest: (url, resourceType) => {
      if (
        resourceType === 'Tile' &&
        (url.startsWith('https://api.petroniche.com/') ||
          url.startsWith('https://api.petroninja.com/') ||
          url.includes('.cloudfront.net'))
      ) {
        const token = tokenManager.getCached();

        return {
          url,
          headers: {
            Authorization: `Bearer ${token}`,
            'x-api-key': env.PN_API_KEY,
          },
        };
      } else {
        return { url };
      }
    },
  });

  map.touchZoomRotate.disableRotation();
  map.doubleClickZoom.disable(); // `doubleClickZoom` option doesn't work
  map.scrollZoom.setWheelZoomRate(1 / 125); // default is 1/450

  map.addControl(
    new mapboxgl.NavigationControl({
      showCompass: false,
    }),
    'bottom-right'
  );

  if (!isEmbedded()) {
    const geolocate = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showAccuracyCircle: true,
    });

    geolocate.on('error', (error: any) => {
      console.error('Geolocate error', error);
    });

    map.addControl(geolocate, 'bottom-right');
    map.geolocate = geolocate;
  }

  return map;
};
