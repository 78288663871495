import { Link as MuiLink } from '@mui/material';
import { amber, orange } from '@mui/material/colors';
import { isCordova } from '@pn/core/utils/env';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  intelLink: {
    color: theme.palette.mode === 'light' ? orange[700] : amber[700],
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

type Props = {
  link: string;
  target?: string;
  rel?: string;
  className?: string;
  children: React.ReactNode;
};

export const DynamicLink = ({
  link,
  target = '_self',
  rel = 'noopener noreferrer',
  className,
  children,
}: Props) => {
  if (isCordova()) {
    return <Link to={link}>{children}</Link>;
  } else {
    return (
      <MuiLink
        component="a"
        href={link}
        target={target}
        rel={rel}
        className={className}
      >
        {children}
      </MuiLink>
    );
  }
};

export const IntelDynamicLink = ({
  link,
  target = '_self',
  rel = 'noopener noreferrer',
  className,
  children,
}: Props) => {
  const { classes, cx } = useStyles();

  if (isCordova()) {
    return <Link to={link}>{children}</Link>;
  } else {
    return (
      <MuiLink
        component="a"
        href={link}
        target={target}
        rel={rel}
        className={cx(classes.intelLink, className)}
      >
        {children}
      </MuiLink>
    );
  }
};
