import type { Project } from '@pn/core/domain/project';
import type { User } from '@pn/core/domain/user';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { isNil } from 'lodash-es';

export function isEditable(
  arg: WorkspaceItem | Project,
  user: User | undefined
): boolean {
  if (isNil(user) || arg.origin === 'stackdx') {
    return false;
  }

  if (arg.isGlobal && user.isAdmin && user.email === 'global@petroninja.com') {
    return true;
  }

  if (!arg.isGlobal && user.isAdmin) {
    return true;
  }

  if (isNil(arg.createdBy)) {
    return false;
  }

  return arg.createdBy.id === user.id;
}

type StyleEditability =
  | 'no_editability'
  | 'full_editability'
  | 'duplicate_only';

export function getStyleEditability(
  item: WorkspaceItem,
  user: User | undefined
): StyleEditability {
  if (isNil(user)) return 'no_editability';

  const isGlobalAdminUser =
    item.isGlobal && user.isAdmin && user.email === 'global@petroninja.com';
  const isAdminUser = !item.isGlobal && user.isAdmin;
  const isCreator = item.createdBy?.id === user.id;

  if (isGlobalAdminUser || isAdminUser || isCreator) return 'full_editability';
  if (item.isGlobal) return 'no_editability';

  return 'duplicate_only';
}
