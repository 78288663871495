import type { PostgresMappingItem } from '@pn/services/api/data/types';

export const importantLandMappings: PostgresMappingItem[] = [
  {
    field: 'id',
    label: 'ID',
    width: 175,
    sourceType: 'int',
  },
  {
    field: 'notice_date',
    domainType: 'DateString',
    label: 'Date',
    isShownByDefault: true,
    width: 125,
    sourceType: 'date',
  },

  {
    field: 'contract_no',
    label: 'Parcel No.',
    sourceType: 'text',
    isShownByDefault: true,
    width: 80,
  },
  {
    field: 'area_ha',
    label: 'Area',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'ha',
    },
    isShownByDefault: true,
    width: 100,
    sourceType: 'float',
  },
];

export const secondaryLandMappings: PostgresMappingItem[] = [
  {
    field: 'zones_json',
    label: 'Zones',
    sourceType: 'json',
    isShownByDefault: true,
    width: 200,
  },
  {
    field: 'segments',
    label: '# of Segments',
    sourceType: 'text',
    width: 50,
  },
  {
    field: 'contract_type',
    label: 'Contract Type',
    isShownByDefault: true,
    sourceType: 'text',
    domainTypeAttributes: {
      options: ['Lease', 'License'],
    },
  },

  {
    field: 'province',
    label: 'Province',
    sourceType: 'text',
    isShownByDefault: true,
    width: 60,
  },
  {
    field: 'region_name',
    label: 'Region',
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'restriction_code_list',
    label: 'Restrictions',
    sourceType: 'json',
    isNotRenderable: true,
  },
  // {
  //   field: 'tract_count',
  //   label: 'Tract',
  //   isShownByDefault: true,
  //   width: 80,
  //   sourceType: 'integer',
  // },
  {
    field: 'lands_json',
    label: 'Lands JSON',
    sourceType: 'text',
  },
  {
    field: 'is_partial_lsd_or_portion',
    label: 'Is Partial',
    width: 60,
    isShownByDefault: true,
    sourceType: 'bool',
  },
  {
    field: 'has_amendment',
    label: 'Has Amendment',
    width: 60,
    isShownByDefault: true,
    sourceType: 'bool',
    domainType: 'boolean',
  },
  {
    field: 'rights_type',
    domainType: 'string',
    label: 'Type',
    isShownByDefault: true,
    width: 125,
    sourceType: 'text',
  },
];

export const landPostingsMapping: PostgresMappingItem[] = [
  ...importantLandMappings,
  ...secondaryLandMappings,
];
