import ArticleIcon from '@mui/icons-material/Article';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import { Details } from 'src/web-ui/main-panel/components/Details';
import { Documents } from 'src/web-ui/main-panel/components/Documents';
import { Production } from 'src/web-ui/main-panel/components/Production';
import { StackDX } from 'src/web-ui/main-panel/integrations/StackDX';
import type { MainPanelRoute } from 'src/web-ui/main-panel/routes';

export const wellsRoutes: MainPanelRoute[] = [
  {
    path: '/wells/:id/details',
    title: 'Details',
    Icon: ArticleOutlinedIcon,
    IconSelected: ArticleIcon,
    getComponent: ({ item }) => <Details item={item} />,
  },
  {
    path: '/wells/:id/production',
    title: 'Production',
    Icon: WaterDropOutlinedIcon,
    IconSelected: WaterDropIcon,
    getComponent: ({ item }) => <Production item={item} />,
  },
  {
    path: '/wells/:id/documents',
    title: 'Documents',
    Icon: InsertDriveFileOutlinedIcon,
    IconSelected: InsertDriveFileIcon,
    getComponent: ({ item }) => <Documents item={item} />,
  },
  {
    isIntegration: true,
    path: `/wells/:id/stackdx`,
    title: 'StackDX',
    Icon: undefined,
    IconSelected: undefined,
    getComponent: () => <StackDX />,
  },
];

export const wellsUsaRoutes: MainPanelRoute[] = [
  {
    path: '/wells_usa/:id/details',
    title: 'Details',
    Icon: ArticleOutlinedIcon,
    IconSelected: ArticleIcon,
    getComponent: ({ item }) => <Details item={item} />,
  },
  {
    path: '/wells_usa/:id/production',
    title: 'Production',
    Icon: WaterDropOutlinedIcon,
    IconSelected: WaterDropIcon,
    getComponent: ({ item }) => <Production item={item} />,
  },
  {
    path: '/wells_usa/:id/documents',
    title: 'Documents',
    Icon: InsertDriveFileOutlinedIcon,
    IconSelected: InsertDriveFileIcon,
    getComponent: ({ item }) => <Documents item={item} />,
  },
  {
    isIntegration: true,
    path: `/wells/:id/stackdx`,
    title: 'StackDX',
    Icon: undefined,
    IconSelected: undefined,
    getComponent: () => <StackDX />,
  },
];
