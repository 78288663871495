import {
  GridFilterInputValue,
  GridFilterItem,
  GridFilterOperator,
} from '@mui/x-data-grid-pro';
import { escapeRegExp } from 'lodash-es';

export const notContainsOperator: GridFilterOperator = {
  label: 'does not contain',
  value: 'notContains',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.value) return null;

    const disableTrim = false;
    const filterItemValue = disableTrim
      ? filterItem.value
      : filterItem.value.trim();

    const filterRegex = new RegExp(escapeRegExp(filterItemValue), 'i');

    return ({ value }): boolean => {
      return value != null ? filterRegex.test(value.toString()) : false;
    };
  },
  InputComponent: GridFilterInputValue,
};
