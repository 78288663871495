import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { formatDataType } from '@pn/core/domain/data';
import type { UnitSystem } from '@pn/core/domain/types';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { useMultiExport } from '@pn/core/operations/export';
import { UnauthorizedAlert } from '@pn/ui/custom-components/UnauthorizedAlert';
import { PermissionButton } from '@pn/ui/custom-components/permission/PermissionButton';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  fadedLabel: {
    color: theme.palette.text.secondary,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingTop: `${theme.spacing(2)} !important`,
  },
  button: {
    height: 40,
    minWidth: 115,
  },
}));

type Props = {
  item: WorkspaceItem;
  open: boolean;
  onClose: () => void;
};

export const ExportsDialog = React.memo(_ExportsDialog);
function _ExportsDialog({ item, open, onClose }: Props) {
  const { classes } = useStyles();

  const {
    validExportOptions,
    selectedExportOption,
    setSelectedExportOption,
    isExporting,
    exportUnits,
    setExportUnits,
    handleMultiExport,
  } = useMultiExport({
    item,
    includeTableExport: true,
  });

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>
        <span className={classes.fadedLabel}>
          {formatDataType(item.dataType, {
            case: 'sentence',
          })}{' '}
          /
        </span>{' '}
        Exports
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <UnauthorizedAlert path="dataTable.export" />

        <Autocomplete
          fullWidth
          size="small"
          disableClearable
          value={selectedExportOption}
          options={validExportOptions}
          getOptionLabel={(option) => `${option.name} (.${option.format})`}
          onChange={(_e, value) => setSelectedExportOption(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Export type"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
        <Box display="flex" gap={2}>
          <Box flex={1}>
            <FormControl fullWidth>
              <InputLabel id="export-units-select-label">
                Export units
              </InputLabel>
              <Select
                size="small"
                labelId="export-units-select-label"
                label="Export units"
                value={exportUnits}
                onChange={(e) => setExportUnits(e.target.value as UnitSystem)}
              >
                <MenuItem value="metric">Metric</MenuItem>
                <MenuItem value="imperial">Imperial</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <PermissionButton
            permissionPath="dataTable.export"
            isCustom
            className={classes.button}
            disabled={isExporting}
            onClick={handleMultiExport}
          >
            Download
          </PermissionButton>
        </Box>
        <Typography variant="body2" color="textSecondary">
          {selectedExportOption?.description}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
