import {
  pickMappingItemsFromFields,
  type MappingItem,
} from '@pn/core/domain/data';
import { UnitSystem } from '@pn/core/domain/types';
import { convertSymbol } from '@pn/core/domain/units';
import { ConfigurationError } from '@pn/core/errors';
import type { IQueryFieldsMapper } from '@pn/core/mappers/query/ports';

export const csvQueryFieldsMapper = (
  mapping: MappingItem[],
  unitSystem: UnitSystem
): IQueryFieldsMapper<string[]> => {
  return {
    toDomainFields: () => {
      throw new ConfigurationError('Not implemented');
    },
    toTargetFields: (fields) => {
      return pickMappingItemsFromFields(mapping, fields).map((mappingItem) =>
        toCsvField(mappingItem, unitSystem)
      );
    },
  };
};

export function toCsvField(
  mappingItem: MappingItem,
  unitSystem: UnitSystem
): string {
  if (mappingItem.domainType === 'SIUnit') {
    return `${mappingItem.label} (${convertSymbol(mappingItem.domainTypeAttributes.symbol, unitSystem)})`;
  }
  return mappingItem.label;
}
