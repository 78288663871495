import { dependencies } from '@pn/core/dependencies';
import { useMapStorage } from '@pn/core/storage';
import { DrawMode } from '@pn/services/styles/annotation';
import { isNil, isString } from 'lodash-es';
import React from 'react';

export function useDelegateKeyboardEvents(params: {
  drawMode: DrawMode;
  isAnnotationInterfaceOpen: boolean;
}) {
  const { drawMode, isAnnotationInterfaceOpen } = params;

  const { map } = dependencies;
  const mapboxMap = map._native as mapboxgl.Map;

  const { isInitialized: isMapInitialized } = useMapStorage();

  /**
   * Delegate text-related keyboard events to the map where it will be handled
   * by MapboxDraw.AnnotationTextDrawMode. This is necessary to process key
   * events when the map is not focused.
   */
  React.useEffect(() => {
    if (
      !isMapInitialized ||
      !isAnnotationInterfaceOpen ||
      drawMode !== 'draw_annotation_text'
    )
      return;

    const keyListener = (event: KeyboardEvent) => {
      const targetElement = event.target ?? event.srcElement;

      if (
        !isNil(targetElement) &&
        'nodeName' in targetElement &&
        isString(targetElement.nodeName) &&
        targetElement.nodeName.toLowerCase() !== 'canvas' &&
        targetElement.nodeName.toLowerCase() !== 'input' // ignore events from input fields
      ) {
        mapboxMap
          .getContainer()
          .dispatchEvent(new KeyboardEvent(event.type, { key: event.key }));
      }
    };

    document.addEventListener('keydown', keyListener);
    document.addEventListener('keyup', keyListener);

    return () => {
      document.removeEventListener('keydown', keyListener);
      document.removeEventListener('keyup', keyListener);
    };
  }, [isMapInitialized, isAnnotationInterfaceOpen, drawMode, mapboxMap]);
}
