import { buildQuery } from '@pn/core/domain/query';
import {
  createAnnotationMapConfig,
  createInitInternalState,
  type IWorkspaceItemMapper,
} from '@pn/core/domain/workspace';
import { getCurrentUserId } from '@pn/core/storage/user/currentUserStorage';
import { apiProjectUserMapper } from '@pn/services/api/user/apiUserMapper';
import { isNil } from 'lodash-es';
import type { ApiAnnotation, ApiAnnotationPayload } from './types';
import assert from 'assert';
import { isAnnotationSource } from '@pn/core/domain/layer';

export const apiAnnotationMapper = (): IWorkspaceItemMapper<
  ApiAnnotation,
  ApiAnnotationPayload
> => {
  return {
    toWorkspaceItem: (apiAnnotation) => {
      return {
        folder: getFolder(apiAnnotation),
        isTemporary: false,
        id: apiAnnotation.id,
        dataType: apiAnnotation.id,
        name: apiAnnotation.name,
        numberOfElements: apiAnnotation.definition.features.length,
        itemType: 'annotation',
        origin: 'pn',
        createdAt: apiAnnotation.created_at,
        updatedAt: apiAnnotation.updated_at,
        createdBy: !isNil(apiAnnotation.user)
          ? apiProjectUserMapper.toDomainProjectUser(apiAnnotation.user)
          : undefined,
        isGlobal: false,
        map: createAnnotationMapConfig({
          id: apiAnnotation.id,
          features: apiAnnotation.definition.features,
        }),
        dataSource: {
          type: 'none',
        },
        detailsSource: 'local',
        query: buildQuery({
          id: apiAnnotation.id,
          dataType: apiAnnotation.id,
        }),
        metadata: {
          isQueryArea: apiAnnotation.is_area,
        },
        ...createInitInternalState({ isVisualized: false }),
      };
    },
    toOriginalItem: (item) => {
      assert(
        isAnnotationSource(item.map.layers[0].source),
        'Invalid annotation source'
      );

      return {
        name: item.name,
        definition: {
          type: 'FeatureCollection',
          features: item.map.layers[0].source.data.features,
        },
        is_area: item.metadata?.isQueryArea ?? false,
      };
    },
  };
};

function getFolder(apiAnnotation: ApiAnnotation): string {
  const userId = getCurrentUserId(); // HACK

  if (!isNil(userId) && apiAnnotation.user?.id === userId) {
    return 'Personal';
  } else {
    return 'Shared';
  }
}
