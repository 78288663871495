import { dependencies } from '@pn/core/dependencies';
import { useMapStorage, useWorkspaceStorage } from '@pn/core/storage';
import { draw } from '@pn/services/map/mapbox-gl-draw/draw';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';

export function useQuickSelection(params: {
  isAnnotationInterfaceOpen: boolean;
  convertToSelection: () => void;
  reset: () => void;
}) {
  const { isAnnotationInterfaceOpen, convertToSelection, reset } = params;

  const {
    map,
    notificationService: { notify },
  } = dependencies;
  const mapboxMap = map._native as mapboxgl.Map;

  const { isInitialized: isMapInitialized } = useMapStorage();
  const { workspaceItemSelected } = useWorkspaceStorage();

  /**
   * Process quick-selection tools.
   */
  React.useEffect(() => {
    if (!isMapInitialized) return;

    function onCreate() {
      if (!isAnnotationInterfaceOpen && !isEmpty(draw.getAll().features)) {
        if (isNil(workspaceItemSelected)) {
          notify('Select a layer to perform multi-selection', 'warning');
        } else {
          convertToSelection();
        }
        reset();
      }
    }

    mapboxMap.on('draw.create', onCreate);

    return () => {
      mapboxMap.off('draw.create', onCreate);
    };
  }, [
    convertToSelection,
    isAnnotationInterfaceOpen,
    isMapInitialized,
    mapboxMap,
    notify,
    reset,
    workspaceItemSelected,
  ]);
}
