import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
} from '@mui/material';
import { MapMode } from '@pn/core/domain/map';
import { mapActions, useMapStorage } from '@pn/core/storage';
import { usePrevious } from '@pn/core/utils/hooks';
import { findOrThrow } from '@pn/core/utils/logic';
import { builtInLayers } from '@pn/services/map/mapbox/style';
import { capitalize } from 'lodash-es';
import React from 'react';
import { map } from 'src/application/externalDependencies';
import { makeStyles } from 'tss-react/mui';

/**
 * Updated whenever the local state changes.
 */
let defaultCheckedState = builtInLayers.reduce<Record<string, boolean>>(
  (acc, layer) => ({ ...acc, [layer.id]: layer.defaultState }),
  {}
);

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  listItemButton: {
    height: 30,
    marginLeft: theme.spacing(-0.75), // lines up with the radio buttons
  },
  listItemIcon: {
    minWidth: 32,
  },
  icon: {
    position: 'relative',
    top: 3,
    width: 16,
    marginRight: theme.spacing(2),
    opacity: theme.palette.mode === 'light' ? 0.7 : 1,
  },
  semiBold: {
    fontWeight: 500,
  },
}));

/**
 * This component makes use of external state and actions.
 */
export const BackgroundDialogModule = () => {
  const { classes } = useStyles();

  const { mode } = useMapStorage();

  const handleChangeMode = (
    _event: React.ChangeEvent<HTMLInputElement>,
    newMode: string
  ) => {
    mapActions().updateMode(newMode as MapMode);
  };

  const [checked, setChecked] =
    React.useState<Record<string, boolean>>(defaultCheckedState);

  const previousChecked = usePrevious(checked, defaultCheckedState);

  React.useEffect(() => {
    defaultCheckedState = checked;
  }, [checked]);

  React.useEffect(() => {
    Object.keys(checked).forEach((id) => {
      const layer = findOrThrow(builtInLayers, (layer) => layer.id === id);
      if (checked[id] && !previousChecked[id]) {
        matchMapboxLayers(layer.sourceLayers).forEach((mapboxLayer) => {
          map.showLayer(mapboxLayer.id);
        });
      } else if (!checked[id] && previousChecked[id]) {
        matchMapboxLayers(layer.sourceLayers).forEach((mapboxLayer) => {
          map.hideLayer(mapboxLayer.id);
        });
      }
    });
  }, [checked, previousChecked]);

  const toggle = (id: string) => {
    setChecked((prev) => ({ ...prev, [id]: !prev[id] }));
  };
  const toggleAll = () => {
    setChecked((prev) => {
      const all = Object.values(prev).every(Boolean);
      return Object.fromEntries(
        Object.entries(prev).map(([key]) => [key, !all])
      );
    });
  };

  return (
    <Box className={classes.container}>
      <FormControl>
        <FormLabel id="background-options-label">Background</FormLabel>
        <Box ml={1}>
          <RadioGroup
            aria-labelledby="background-options-label"
            value={mode}
            onChange={handleChangeMode}
          >
            {Object.values(MapMode).map((mode) => (
              <FormControlLabel
                key={mode}
                value={mode}
                control={<Radio />}
                label={capitalize(mode)}
              />
            ))}
          </RadioGroup>
        </Box>
      </FormControl>
      <FormControl>
        <FormLabel id="background-layer-toggles-label">Base layers</FormLabel>
        <List disablePadding>
          <Box height={5} />
          <ListItem disablePadding dense>
            <ListItemButton
              dense
              className={classes.listItemButton}
              onClick={toggleAll}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Checkbox
                  edge="start"
                  size="small"
                  tabIndex={-1}
                  disableRipple
                  checked={Object.values(checked).every(Boolean)}
                  indeterminate={
                    Object.values(checked).some(Boolean) &&
                    !Object.values(checked).every(Boolean)
                  }
                  inputProps={{ 'aria-labelledby': 'toggle-all-base-layers' }}
                />
              </ListItemIcon>
              <ListItemText
                id="toggle-all-base-layers"
                primary="Toggle all"
                primaryTypographyProps={{
                  className: classes.semiBold,
                }}
              />
            </ListItemButton>
          </ListItem>
          {builtInLayers.map((layer) => (
            <ListItem key={layer.id} disablePadding dense>
              <ListItemButton
                dense
                className={classes.listItemButton}
                onClick={() => toggle(layer.id)}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <Checkbox
                    edge="start"
                    size="small"
                    tabIndex={-1}
                    disableRipple
                    checked={checked[layer.id]}
                    inputProps={{ 'aria-labelledby': layer.id }}
                  />
                </ListItemIcon>
                <ListItemText id={layer.id} primary={layer.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </FormControl>
    </Box>
  );
};

function matchMapboxLayers(sourceLayers: string[]) {
  const mapLayers = map._native.getStyle().layers ?? [];
  return mapLayers.filter((layer: any) =>
    sourceLayers.includes(layer['source-layer'])
  );
}
