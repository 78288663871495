import { Box, Typography } from '@mui/material';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { useAccess } from '@pn/core/permissions/access';
import { isEditable } from '@pn/core/permissions/editability';
import {
  useCurrentUserStorage,
  useWorkspaceStorage,
} from '@pn/core/storage';
import { isFreeCordova } from '@pn/core/utils/env';
import { isCloseMatch } from '@pn/core/utils/string';
import { useDebouncedValue } from '@pn/ui/hooks/useDebouncedValue';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { LibrarySearch } from '@pn/ui/workspace/LibrarySearch';
import { isEmpty, orderBy } from 'lodash-es';
import React from 'react';
import { DenseWorkspaceItem } from 'src/web-ui/workspace/DenseWorkspaceItem';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  quickBrowserContainer: {
    height: '100%',
  },
  itemsContainer: {
    height: 'calc(100% - 39px)',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    overflowY: 'scroll',
  },
}));

type Props = {
  squareTop?: boolean;
  disabled?: boolean;
  itemIdsToExclude: WorkspaceItem['id'][];
  onAddItem: (itemId: WorkspaceItem['id']) => void;
};

export const QuickBrowser = React.memo(_QuickBrowser);
function _QuickBrowser({
  squareTop = false,
  disabled = false,
  itemIdsToExclude,
  onAddItem,
}: Props) {
  const { smScreen } = useScreenSize();
  const { classes } = useStyles();

  const access = useAccess();
  const { user } = useCurrentUserStorage();
  const { allWorkspaceItems } = useWorkspaceStorage();

  const [searchText, setSearchText] = React.useState('');
  const [searchValue, skipSearchValueDebounce] = useDebouncedValue(
    searchText,
    400,
    {
      leading: false,
      skipOnValue: '',
    }
  );

  const showAllItems = smScreen || isFreeCordova(user);

  const quickBrowserItems = React.useMemo(
    () =>
      isEmpty(searchValue)
        ? (showAllItems
            ? sortAllItems(allWorkspaceItems)
            : allWorkspaceItems.filter(({ folder }) => folder === 'Petro Ninja')
          ).filter(
            (item) => !item.isTemporary && !itemIdsToExclude.includes(item.id)
          )
        : sortAllItems(allWorkspaceItems).filter(
            (item) =>
              !item.isTemporary &&
              !itemIdsToExclude.includes(item.id) &&
              isCloseMatch(item.name, searchValue)
          ),
    [allWorkspaceItems, itemIdsToExclude, searchValue, showAllItems]
  );

  return (
    <Box className={classes.quickBrowserContainer}>
      <LibrarySearch
        variant="filled"
        topBorder
        squareTop={squareTop}
        disabled={disabled}
        searchText={searchText}
        setSearchText={setSearchText}
        skipDebounce={skipSearchValueDebounce}
      />
      <Box className={classes.itemsContainer}>
        {isEmpty(quickBrowserItems) ? (
          <Box mt={1}>
            <Typography align="center" variant="body2" color="textSecondary">
              No results
            </Typography>
          </Box>
        ) : (
          quickBrowserItems
            .filter((item) =>
              isFreeCordova(user)
                ? ['wells', 'wells_usa', '_background', 'grids'].includes(
                    item.id
                  ) || isEditable(item, user)
                : true
            )
            .map((item) => (
              <DenseWorkspaceItem
                key={item.id}
                disabled={
                  disabled ||
                  (access('allData', item.id).denied() &&
                    !isEditable(item, user))
                }
                item={item}
                onAddItem={onAddItem}
              />
            ))
        )}
      </Box>
    </Box>
  );
}

const sortOrder: Record<string, number> = {
  'Petro Ninja': 1,
  StackDX: 2,
  'BOE Intel': 3,
  Personal: 4,
  Enterprise: 5,
};

function sortAllItems(items: WorkspaceItem[]): WorkspaceItem[] {
  return orderBy(items.slice(), 'updatedAt', 'desc').sort((a, b) => {
    const orderA = sortOrder[a.folder] || 5; // 5 is for the rest
    const orderB = sortOrder[b.folder] || 5;

    return orderA - orderB;
  });
}
