import { UnitSystem } from '@pn/core/domain/types';
import { hasKey, hasKeyWithType } from '@pn/core/utils/logic';
import { isNil, isObject, isString } from 'lodash-es';

type Unit = {
  value: number | undefined;
  symbol: string;
  conversionSymbol?: string;
};

export type SIUnit = Unit;
export type ImperialUnit = Unit;

export function toSIUnit(params: {
  value: number | undefined;
  symbol: string;
}): SIUnit {
  return params;
}

export function toImperialUnit(params: {
  value: number | undefined;
  symbol: string;
}): ImperialUnit {
  return params;
}

export function isSIUnit(arg: unknown): arg is SIUnit {
  return (
    !isNil(arg) &&
    isObject(arg) &&
    hasKey(arg, 'value') &&
    hasKeyWithType(arg, 'symbol', isString)
  );
}

/**
 * Converts Units between unit systems.
 * If SI Unit symbol is not recognized, the conversion is skipped entirely.
 */
export function convertUnit(siUnit: SIUnit, unitSystem: UnitSystem) {
  switch (unitSystem) {
    case UnitSystem.Metric:
      return siUnit;
    case UnitSystem.Imperial:
      switch (siUnit.symbol) {
        /* Volume */
        case 'm3':
          return toImperialUnit({
            value: convertValue(siUnit.value, (a) => a * 6.28981077),
            symbol: 'bbl',
          });
        case 'm3/d':
          return toImperialUnit({
            value: convertValue(siUnit.value, (a) => a * 6.28981077),
            symbol: 'bbl/d',
          });
        case 'e3m3':
          return toImperialUnit({
            value: convertValue(siUnit.value, (a) => a * 35.3144754),
            symbol: 'mcf',
          });
        case 'e3m3/d':
          return toImperialUnit({
            value: convertValue(siUnit.value, (a) => a * 35.3144754),
            symbol: 'mcf/d',
          });
        case 'm3/m3':
          return toImperialUnit({
            value: convertValue(
              siUnit.value,
              (a) => (a * 35.3144754) / 6.28981077
            ),
            symbol: 'scf/bbl',
          });
        case 'm3/e3m3':
          return toImperialUnit({
            value: convertValue(
              siUnit.value,
              (a) => (a * 6.28981077) / 35.3144754e-3
            ),
            symbol: 'bbl/mmscf',
          });
        /* Length */
        case 'm':
          return toImperialUnit({
            value: convertValue(siUnit.value, (a) => a * 3.28084),
            symbol: 'ft',
          });
        case 'mm':
          return toImperialUnit({
            value: convertValue(siUnit.value, (a) => a / 25.4),
            symbol: 'in',
          });
        /* Pressure */
        case 'kPa':
          return toImperialUnit({
            value: convertValue(siUnit.value, (a) => a * 0.145038),
            symbol: 'psi',
          });
        /* Temperature */
        case 'C':
          return toImperialUnit({
            value: convertValue(siUnit.value, (a) => (a * 9) / 5 + 32),
            symbol: 'F',
          });
        /* Density */
        case 'kg/m3':
          return toImperialUnit({
            value: convertValue(siUnit.value, (a) => a * 0.06242796),
            symbol: 'lb/ft3',
          });
        default:
          return siUnit;
      }
    default:
      throw new Error('Invalid unit system: ' + unitSystem);
  }
}

const currencySymbols = ['$'];

const liquidProductionSymbols = ['m3', 'm3/d', 'bbl', 'bbl/d'];
const gasProductionSymbols = ['e3m3', 'e3m3/d', 'mcf', 'mcf/d'];

export function isLiquidProductionUnit(unit: Unit) {
  return liquidProductionSymbols.includes(unit.symbol);
}

export function isGasProductionUnit(unit: Unit) {
  return gasProductionSymbols.includes(unit.symbol);
}

function convertValue(
  value: number | undefined,
  mathOperation: (a: number) => number
) {
  return !isNil(value) ? mathOperation(value) : undefined;
}

/**
 * Utility helper to convert unit symbols directly.
 */
export function convertSymbol(symbol: string, unitSystem: UnitSystem): string {
  switch (unitSystem) {
    case UnitSystem.Metric:
      return symbol;
    case UnitSystem.Imperial:
      return convertUnit(
        toSIUnit({
          value: 0,
          symbol,
        }),
        unitSystem
      ).symbol;
  }
}

/**
 * Formats unit value with a variable number of decimals.
 */
export const unitToString = (
  unit: Unit,
  options?: {
    displaySymbol?: boolean;
  }
): string => {
  const { displaySymbol = false } = options ?? {};

  if (isNil(unit.value)) return '-';

  const isCurrency = currencySymbols.includes(unit.symbol);
  const formattedValue =
    unit.value.toLocaleString('en-CA', {
      maximumFractionDigits: isCurrency
        ? 2
        : unit.value && unit.value < 1
          ? 3
          : unit.value && unit.value < 10
            ? 2
            : 1,
      minimumFractionDigits: isCurrency ? 2 : 0,
    }) ?? '';

  let symbolString = '';
  if (displaySymbol) {
    symbolString = prettyPrintUnitSymbol(unit.symbol);
    symbolString = isCurrency ? symbolString : ` ${symbolString}`;
  }

  return isCurrency
    ? `${symbolString}${formattedValue}`
    : `${formattedValue}${symbolString}`;
};

export function prettyPrintUnitSymbol(symbol: string) {
  switch (symbol) {
    case 'm3':
      return 'm³';
    case 'm3/d':
      return 'm³/d';
    case 'e3m3':
      return 'e³m³';
    case 'e3m3/d':
      return 'e³m³/d';
    case 'm3/m3':
      return 'm³/m³';
    case 'm3/e3m3':
      return 'm³/e³m³';
    case 'C':
      return '°C';
    case 'F':
      return '°F';
    case 'kg/m3':
      return 'kg/m³';
    case 'lb/ft3':
      return 'lb/ft³';
    default:
      return symbol;
  }
}
