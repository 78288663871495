import { LayerType } from '@pn/core/domain/layer';
import { buildQuery } from '@pn/core/domain/query';
import {
  createInitInternalState,
  getLayerId,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { isProduction } from '@pn/core/utils/env';
import { DEFAULT_COLOR } from '@pn/services/styles';
import { generateDataConfig } from '.';

export const dispositions_historical: WorkspaceItem = {
  folder: 'Petro Ninja',
  isTemporary: false,
  id: 'dispositions_historical',
  dataType: 'dispositions_historical',
  itemType: 'layer',
  numberOfElements: undefined,
  createdBy: undefined,
  name: 'Historical Dispositions',
  description: '145k dispositions in Canada',
  origin: 'pn',
  createdAt: '1970-01-01T00:00:00.000Z',
  updatedAt: '1970-01-01T00:00:00.000Z',
  isGlobal: true,
  map: {
    layers: [
      {
        id: getLayerId('dispositions_historical', 'dispositions_historical', 0),
        name: 'Historical Dispositions',
        type: LayerType.Polygon,
        style: {
          color: 'hsla(45, 75%, 40%, 0.25)',
        },
        source: {
          type: 'vector',
          url: isProduction()
            ? 'mapbox://sbilston.production-units_pools_disp'
            : 'mapbox://sbilston.staging-units_pools_disp',
        },
        sourceLayer: 'historical-disps',
        renderAsPoints: true,
      },
      {
        id: getLayerId('dispositions_historical', 'dispositions_historical', 1),
        name: 'Border 1',
        type: LayerType.Line,
        style: {
          color: DEFAULT_COLOR,
          width: 0.5,
        },
        source: {
          type: 'vector',
          url: isProduction()
            ? 'mapbox://sbilston.production-units_pools_disp'
            : 'mapbox://sbilston.staging-units_pools_disp',
        },
        sourceLayer: 'historical-disps',
        renderAsPoints: true,
      },
    ],
  },
  dataSource: generateDataConfig(['internal_id', 'geometry']),
  detailsSource: 'api',
  query: buildQuery({
    id: 'dispositions_historical',
    dataType: 'dispositions_historical',
  }),
  ...createInitInternalState({ isVisualized: false }),
};
