import DragHandleIcon from '@mui/icons-material/DragHandle';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { useInitializeMap } from '@pn/core/operations/mapInteractions';
import { useCurrentUserStorage, useMapStorage } from '@pn/core/storage';
import { isEmbedded } from '@pn/core/utils/embedded';
import { isFreeCordova } from '@pn/core/utils/env';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { PNMap } from '@pn/ui/map/PNMap';
import { usePNThemeContext } from '@pn/ui/theme/PetroNinjaThemeProvider';
import assert from 'assert';
import { clamp, debounce } from 'lodash-es';
import React from 'react';
import { map } from 'src/application/externalDependencies';
import { BOTTOM_TABLE_HEIGHT, MAIN_TRAY_WIDTH } from 'src/web-ui/Main';
import { MapButtons } from 'src/web-ui/map/addons/MapButtons';
import { MapOverlay } from 'src/web-ui/map/addons/MapOverlay';
import { MapTerms } from 'src/web-ui/map/addons/MapTerms';
import { MapEffects } from 'src/web-ui/map/MapEffects';
import { zIndex } from 'src/web-ui/zIndex';
import { makeStyles } from 'tss-react/mui';

const draggableHandleHeight = 16;
const draggableHandleWidth = 48;

const useStyles = makeStyles<{
  isMainTrayOpen: boolean;
  isQuickInfoOpen: boolean;
}>()((theme, { isMainTrayOpen, isQuickInfoOpen }) => ({
  map: {
    '& .mapboxgl-popup-content': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
    },
    '& .mapboxgl-popup-tip': {
      borderRightColor: theme.palette.primary.main,
    },
    '& .mapboxgl-ctrl-bottom-left': {
      left: isMainTrayOpen ? MAIN_TRAY_WIDTH : 0,
    },
    '& .mapboxgl-ctrl-bottom-right': {
      bottom: isQuickInfoOpen ? MAIN_TRAY_WIDTH + 53 : 0,
    },
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  draggableHandle: {
    position: 'absolute',
    left: `calc(50% - ${draggableHandleWidth / 2}px)`,
    bottom: 0,
    width: draggableHandleWidth,
    height: draggableHandleHeight,
    backgroundColor: theme.palette.background.paper,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    textAlign: 'center',
    cursor: 'ns-resize',
    userSelect: 'none',
    zIndex: zIndex(theme).map,
  },
}));

type Props = {
  isMainTrayOpen?: boolean;
  isQuickInfoOpen?: boolean;
};

export const Map = React.memo(_Map);
function _Map({ isMainTrayOpen = false, isQuickInfoOpen = false }: Props) {
  const { pnTheme } = usePNThemeContext();
  const { smScreen } = useScreenSize();
  const { classes } = useStyles({ isMainTrayOpen, isQuickInfoOpen });

  const { user } = useCurrentUserStorage();
  const { isInitialized } = useMapStorage();

  const { mapRef } = useInitializeMap({ pnTheme });

  const handleInteractionStart = (
    initialMouseY: number,
    initialHeight: number
  ) => {
    const resizeMap = debounce(() => map.resize(), 50);

    function handleMouseMove(e: MouseEvent | TouchEvent) {
      let clientY: number;

      if (e instanceof MouseEvent) {
        clientY = e.clientY;
      } else if (e instanceof TouchEvent) {
        clientY = e.touches[0].clientY;
      } else {
        return;
      }

      const deltaY = initialMouseY - clientY;
      const newHeight = clamp(
        initialHeight + deltaY,
        0,
        window.innerHeight - 64 - 18
      );

      const bottomTableContainerElem = document.getElementById(
        'bottom-table-container'
      );
      assert(bottomTableContainerElem, '#bottomTableContainerElem is null');
      bottomTableContainerElem.style.height = `${newHeight}px`;

      resizeMap();
    }

    function handleInteractionEnd() {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleInteractionEnd);
      document.removeEventListener('touchmove', handleMouseMove);
      document.removeEventListener('touchend', handleInteractionEnd);
    }

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleInteractionEnd);
    document.addEventListener('touchmove', handleMouseMove);
    document.addEventListener('touchend', handleInteractionEnd);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const initialMouseY = e.clientY;
    const bottomTableContainerElem = document.getElementById(
      'bottom-table-container'
    );
    assert(bottomTableContainerElem, '#bottomTableContainerElem is null');
    const initialHeight = bottomTableContainerElem.offsetHeight;

    handleInteractionStart(initialMouseY, initialHeight);
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const initialMouseY = e.touches[0].clientY;
    const bottomTableContainerElem = document.getElementById(
      'bottom-table-container'
    );
    assert(bottomTableContainerElem, '#bottomTableContainerElem is null');
    const initialHeight = bottomTableContainerElem.offsetHeight;

    handleInteractionStart(initialMouseY, initialHeight);
  };

  const handleDoubleClick = () => {
    const bottomTableContainerElem = document.getElementById(
      'bottom-table-container'
    );
    assert(bottomTableContainerElem, '#bottomTableContainerElem is null');
    bottomTableContainerElem.style.height = `${BOTTOM_TABLE_HEIGHT}px`;

    map.resize();
  };

  return (
    <>
      <PNMap mapRef={mapRef} extraClassName={classes.map} />

      {!isEmbedded() && !isFreeCordova(user) && <MapButtons />}
      {!isEmbedded() && <MapOverlay />}

      {!isInitialized ? (
        <Box className={classes.loader}>
          <CircularProgress color="primary" size={64} />
        </Box>
      ) : (
        <MapEffects />
      )}

      <MapTerms isMainTrayOpen={isMainTrayOpen} />

      {!smScreen && !isEmbedded() && !isFreeCordova(user) && (
        <Box
          className={classes.draggableHandle}
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          onDoubleClick={handleDoubleClick}
        >
          <DragHandleIcon fontSize="small" />
        </Box>
      )}
    </>
  );
}
