import { MapMode } from '@pn/core/domain/map';
import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
  isInitialized: boolean;
  mode: MapMode;
  isRenderingLayers: boolean;
};

const initialState: InitialState = {
  isInitialized: false,
  mode: MapMode.Standard,
  isRenderingLayers: false,
};

export const createMapSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      initialize: (state) => {
        state.isInitialized = true;
      },
      updateMode: (state, { payload }) => {
        state.mode = payload;
      },
      updateIsRenderingLayers: (state, { payload }) => {
        state.isRenderingLayers = payload;
      },
      reset: (state) => {
        state.isInitialized = false;
        state.mode = MapMode.Standard;
        state.isRenderingLayers = false;
      },
    },
  });

export const mapSlice = createMapSlice('map');
/* We can create more slices in future as needed */
