import type { DataItemId } from '@pn/core/domain/data';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { getMapDataItems } from '@pn/core/operations/workspace/mapData';
import { useAppStorage, useWorkspaceStorage } from '@pn/core/storage';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';

/**
 * Use `isSelectable` for visualizing selected data item.
 * This logic will be deprecated if we make `page_with_id` work.
 */
export function useDataSelection() {
  const { pageSize } = useAppStorage();
  const { workspaceItemSelected, dataItemRequested, dataItemSelected } =
    useWorkspaceStorage();

  const isSelected = !isNil(dataItemSelected);
  const isAboutToBeSelected = React.useMemo(
    () =>
      isRequestedIdAboutToBeSelected({
        pageSize,
        item: workspaceItemSelected,
        dataItemId: dataItemRequested.id,
      }),
    [dataItemRequested.id, workspaceItemSelected, pageSize]
  );

  return {
    isSelectable: isSelected || isAboutToBeSelected,
  };
}

function isRequestedIdAboutToBeSelected(params: {
  pageSize: number;
  item: WorkspaceItem | undefined;
  dataItemId: DataItemId | undefined;
}): boolean {
  const { pageSize, item, dataItemId } = params;

  if (isNil(item)) return false;

  const { requestedIds, page } = item.query;

  if (isNil(dataItemId)) return false;
  if (
    !isEmpty(requestedIds) &&
    requestedIds.length <= pageSize &&
    requestedIds.includes(dataItemId)
  )
    return true;

  const mapDataItems = getMapDataItems(item);
  if (isEmpty(mapDataItems)) return false;

  const pageWithId = Math.floor(
    mapDataItems.findIndex((d) => d._id === dataItemId) / pageSize
  );

  return pageWithId === page;
}