import { makeStyles } from 'tss-react/mui';

type Props = {
  enableRowClicks: boolean;
};

export const useStyles = makeStyles<Props>()((theme, { enableRowClicks }) => ({
  root: {
    borderRadius: 0,
    border: 'none',
    '& .MuiDataGrid-columnHeaderTitle': {
      whiteSpace: 'pre',
      textOverflow: 'initial',
      lineHeight: '1.4em',
      textAlign: 'center',
    },
    '& .MuiDataGrid-container--top [role=row]': {
      background: 'unset',
    },
    '& .MuiDataGrid-filler': {
      border: 'none',
    },
    '& .MuiDataGrid-scrollbarFiller': {
      border: 'none !important',
    },
    '& .MuiDataGrid-columnHeader ::-webkit-calendar-picker-indicator': {
      display: 'none', // hides "show date picker" icon-button
    },
    '& .MuiDataGrid-columnHeader .MuiInputBase-root': {
      marginTop: 0, // removes margin from Select input
    },
    '& .MuiDataGrid-columnHeader .MuiIconButton-root': {
      margin: 0,
    },
    '& .MuiDataGrid-row': {
      cursor: enableRowClicks ? 'pointer' : 'initial',
    },
    '& .MuiDataGrid-cell': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-footerContainer': {
      borderTop: 'none',
    },
  },
}));
