import { colors } from '@mui/material';
import { LayerType } from '@pn/core/domain/layer';
import { buildQuery } from '@pn/core/domain/query';
import {
  createInitInternalState,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { isProduction } from '@pn/core/utils/env';
import { DEFAULT_LINE_WIDTH } from '@pn/services/styles';
import { generateDataConfig, generateMapConfig } from '.';

export const pipelines: WorkspaceItem = {
  folder: 'Petro Ninja',
  isTemporary: false,
  id: 'pipelines',
  dataType: 'pipelines',
  itemType: 'layer',
  numberOfElements: undefined,
  createdBy: undefined,
  name: 'Pipelines',
  description: '445k pipelines in Canada',
  origin: 'pn',
  createdAt: '1970-01-01T00:00:00.000Z',
  updatedAt: '1970-01-01T00:00:00.000Z',
  isGlobal: true,
  map: generateMapConfig({
    itemId: 'pipelines',
    type: LayerType.Line,
    style: {
      width: DEFAULT_LINE_WIDTH,
      color: [
        'match',
        ['get', 'substance_mapping'],
        'Oil',
        'hsl(120, 100%, 50%)',
        'Gas',
        colors.pink['A400'],
        'Water',
        'hsl(220, 100%, 50%)',
        'Other',
        'hsl(270, 100%, 50%)',
        colors.grey[900],
      ],
    },
    source: {
      type: 'vector',
      url: isProduction()
        ? 'mapbox://sbilston.production-pipelines'
        : 'mapbox://sbilston.staging-pipelines-2',
    },
    sourceLayer: 'pipelines',
  }),
  dataSource: generateDataConfig([
    'internal_id',
    'geometry',
    'substance_mapping',
  ]),
  detailsSource: 'api',
  query: buildQuery({
    id: 'pipelines',
    dataType: 'pipelines',
  }),
  ...createInitInternalState({ isVisualized: false }),
};
