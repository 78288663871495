import type { DataItemId } from '@pn/core/domain/data';
import { UnitSystem } from '@pn/core/domain/types';
import { pnApiClient } from '@pn/services/api/pnApiClient';

export const getMultiExport = (params: {
  exportId: string;
  exportType: string;
  unitSystem: UnitSystem;
  dataItemIds: DataItemId[];
}): Promise<void> => {
  return pnApiClient.request({
    method: 'POST',
    url: 'v2/data/multi_export',
    payload: {
      export: params.exportType,
      export_id: params.exportId,
      metric: params.unitSystem === UnitSystem.Metric,
      object_ids: params.dataItemIds,
    },
  });
};

export const apiExportsProvider = {
  getMultiExport,
};
