import { MenuItem, TextField } from '@mui/material';
import { useStackCompanies } from '@pn/core/operations/stackdx/stackCompanies';
import { useLibrary } from '@pn/ui/workspace/LibraryStateProvider';
import { isEmpty, isNil } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  companySelector: {
    maxWidth: 240,
  },
}));

type Props = {
  disabled: boolean;
};

export const StackCompanySelector = ({ disabled }: Props) => {
  const { classes } = useStyles();
  const { setLibraryRouter } = useLibrary();
  const { companies, companyId, changeCompany } = useStackCompanies();

  const handleCompanyChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    changeCompany(event.target.value);
    setLibraryRouter(['StackDX']);
  };

  if (isEmpty(companies) || isNil(companyId)) return null;

  return (
    <TextField
      fullWidth
      select
      size="small"
      className={classes.companySelector}
      id="stackdx-company-selector"
      label="Company"
      disabled={disabled}
      value={companyId}
      onChange={handleCompanyChange}
    >
      {companies.map((company) => (
        <MenuItem key={company.stackCompanyId} value={company.stackCompanyId}>
          {company.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
