import { Box } from '@mui/material';
import { useCurrentUserStorage } from '@pn/core/storage';
import { isFreeCordova } from '@pn/core/utils/env';
import { PermissionButton } from '@pn/ui/custom-components/permission/PermissionButton';
import { isNil } from 'lodash-es';

type Props = {
  onCreateList: (() => void) | undefined;
  onUploadLayer: (() => void) | undefined;
};

export const LibraryActionButtons = ({
  onCreateList,
  onUploadLayer,
}: Props) => {
  const { user } = useCurrentUserStorage();

  if (isNil(onCreateList) && isNil(onUploadLayer)) return null;

  return (
    <Box display="flex" gap={2}>
      <PermissionButton
        permissionPath="lists"
        variant="contained"
        onClick={onCreateList}
      >
        Import list
      </PermissionButton>
      {!isFreeCordova(user) && (
        <PermissionButton
          permissionPath="layers"
          variant="contained"
          onClick={onUploadLayer}
        >
          Upload GIS file
        </PermissionButton>
      )}
    </Box>
  );
};
