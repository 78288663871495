import type { IChannelMapper } from '@pn/core/mappers/channel/ports';
import { isNil } from 'lodash-es';
import type { ApiChannel } from './types';

export const apiChannelMapper: IChannelMapper<ApiChannel> = {
  toDomainChannel: (apiChannel) => {
    return {
      id: apiChannel.id,
      createdAt: apiChannel.created_at,
      name: apiChannel.name,
      userIds: isNil(apiChannel.user_ids) ? [] : apiChannel.user_ids,
      isProtected: apiChannel.protected,
    };
  },
  toTargetChannel: (domainChannel) => {
    return {
      id: domainChannel.id,
      created_at: domainChannel.createdAt,
      name: domainChannel.name,
      user_ids: domainChannel.userIds,
      protected: domainChannel.isProtected,
    };
  },
};
