import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {
  FormControl,
  ListItemIcon,
  ListItemText,
  Menu,
  Select,
} from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import {
  formatDataType,
  isPNDataType,
  type DataItem,
  type DataItemId,
} from '@pn/core/domain/data';
import { DataSelectionReason } from '@pn/core/domain/query';
import {
  getDataItemSelected,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { selectDataItem } from '@pn/core/operations/dataSelection';
import { useRouter } from '@pn/core/operations/router';
import { useAccess } from '@pn/core/permissions/access';
import { useAppStorage, useWorkspaceStorage } from '@pn/core/storage';
import { isCordova } from '@pn/core/utils/env';
import { formatId } from '@pn/core/utils/format';
import { useCopyToClipboard } from '@pn/ui/hooks/useCopyToClipboard';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { ForwardSlashIcon, OpenPopoutWindowIcon } from '@pn/ui/icons';
import { isEmpty, isNil, isString } from 'lodash-es';
import React from 'react';
import { notify } from 'src/application/externalDependencies';
import { AddToListDialog } from 'src/web-ui/main-panel/components/AddToListDialog';
import {
  usePortals,
  type Portal,
} from 'src/web-ui/main-panel/portals/PortalsProvider';
import type { MainPanelRoute } from 'src/web-ui/main-panel/routes';
import { getMainPanelUri } from 'src/web-ui/main-panel/utils';

type Props = {
  item: WorkspaceItem;
  route: MainPanelRoute;
  portal?: Portal;
};

export const MainPanelHeader = ({ item, route, portal }: Props) => {
  const { location, pushUrl } = useRouter();
  const { xsScreen, mdScreen } = useScreenSize();

  const access = useAccess();
  const { pageSize } = useAppStorage();
  const { dataItemRequested } = useWorkspaceStorage();

  const isPortal = !isNil(portal);
  const { handleOpenNewPortal } = usePortals();

  const relatedIds = isPortal
    ? [dataItemRequested.id!]
    : getRelatedIds(getDataItemSelected(item), item.requestedDataItem.id);

  const { copyText } = useCopyToClipboard();

  const isDesktop = !mdScreen && !isCordova();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const [isAddToListOpen, setAddToListOpen] = React.useState(false);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(undefined);
  };

  const handleClose = () => {
    pushUrl({
      pathname: getMainPanelUri({
        dataType: item.dataType,
        dataItemId: dataItemRequested.id,
      }),
      search: location.search,
    });

    handleCloseMenu();
  };

  const handleOpenPortal = () => {
    if (access('portal').notify().denied()) return;

    handleOpenNewPortal({ item, route });
  };

  const handleAddToList = () => {
    if (access('lists').notify().denied()) return;

    setAddToListOpen(true);

    handleCloseMenu();
  };

  const handleShare = () => {
    copyText(window.location.href);
    notify('URL copied to clipboard');

    handleCloseMenu();
  };

  return (
    <Box px={2} pt={1} pb={0}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb={0.5}
      >
        <Box display="inline-flex" alignItems="center" minWidth={0}>
          <Typography variant="body1" noWrap flexShrink={0}>
            {isPNDataType(item.dataType)
              ? formatDataType(item.dataType, { case: 'sentence' })
              : item.name}
          </Typography>
          <ForwardSlashIcon fontSize="medium" color="disabled" />
          {!isNil(dataItemRequested.id) && !isEmpty(relatedIds) && (
            <>
              {relatedIds.length > 1 && (
                <FormControl>
                  <Select
                    value={dataItemRequested.id}
                    variant="standard"
                    onChange={(e) =>
                      selectDataItem({
                        id: e.target.value,
                        reason: DataSelectionReason.Related,
                        workspaceItem: item,
                        pageSize,
                      })
                    }
                  >
                    {relatedIds.map((id) => (
                      <MenuItem value={id} key={id}>
                        {formatId(id, item.dataType)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {relatedIds.length === 1 && (
                <Typography variant="body1" noWrap={xsScreen}>
                  {formatId(dataItemRequested.id, item.dataType)}
                </Typography>
              )}
            </>
          )}

          {!xsScreen && (
            <>
              <ForwardSlashIcon fontSize="medium" color="disabled" />
              <Typography variant="body1">
                {portal?.route.title ?? route.title}
              </Typography>
            </>
          )}
        </Box>

        <Box display="flex">
          {!isPortal && (
            <IconButton
              id="mph-more-button"
              aria-label="more"
              aria-controls={!isNil(anchorEl) ? 'mph-more-button' : undefined}
              aria-expanded={!isNil(anchorEl) ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleOpenMenu}
            >
              <MoreVertIcon />
            </IconButton>
          )}

          <Menu
            MenuListProps={{
              'aria-labelledby': 'mph-more-button',
            }}
            anchorEl={anchorEl}
            open={!isNil(anchorEl)}
            onClose={handleCloseMenu}
          >
            {isDesktop && (
              <MenuItem onClick={handleOpenPortal}>
                <ListItemIcon>
                  <OpenPopoutWindowIcon />
                </ListItemIcon>
                <ListItemText>Pop-out window</ListItemText>
              </MenuItem>
            )}
            <MenuItem onClick={handleAddToList}>
              <ListItemIcon>
                <PlaylistAddIcon />
              </ListItemIcon>
              <ListItemText>Add to a list</ListItemText>
            </MenuItem>
            {!isCordova() && (
              <MenuItem onClick={handleShare}>
                <ListItemIcon>
                  <ContentCopyIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Share</ListItemText>
              </MenuItem>
            )}
          </Menu>

          {!isPortal && (
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </Box>

      <Divider />

      <AddToListDialog
        open={isAddToListOpen}
        onClose={() => setAddToListOpen(false)}
      />
    </Box>
  );
};

function getRelatedIds(
  dataItemSelected: DataItem | undefined,
  dataItemId: DataItemId | undefined
): DataItemId[] {
  if (!isNil(dataItemSelected) && isString(dataItemSelected.related_ids)) {
    return dataItemSelected.related_ids.split(',').sort();
  }

  if (!isNil(dataItemId)) return [dataItemId];

  return [];
}
