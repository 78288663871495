import DeleteIcon from '@mui/icons-material/Delete';
import FontDownloadOutlinedIcon from '@mui/icons-material/FontDownloadOutlined';
import PentagonOutlinedIcon from '@mui/icons-material/PentagonOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import {
  Badge,
  Button,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { draw } from '@pn/services/map/mapbox-gl-draw/draw';
import type { AnnotationDrawMode } from '@pn/services/styles/annotation';
import { useAnnotations } from '@pn/ui/annotations/AnnotationProvider';
import { isEmpty, isNil } from 'lodash-es';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { ANNOTATION_PANEL_WIDTH } from 'src/web-ui/annotation/AnnotationPropertiesPanel';
import { WORKSPACE_DRAWER_WIDTH } from 'src/web-ui/Main';
import { zIndex } from 'src/web-ui/zIndex';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  panel: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, min-content)',
    gridGap: theme.spacing(1.5),
    position: 'absolute',
    top: `calc(64px + ${theme.spacing(2)})`,
    left: `calc(50% - ${WORKSPACE_DRAWER_WIDTH / 2}px + ${
      (ANNOTATION_PANEL_WIDTH + 16) / 2
    }px - ${(40 + 8) / 2}px)`,
    transform: 'translate(-50%, 0)',
    height: `calc(48px + ${theme.spacing(3)})`,
    padding: theme.spacing(1.5),
    zIndex: zIndex(theme).annotationControls, // above the bottom table
  },
  panelButton: {
    width: 48,
    minWidth: 48,
    height: 48,
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.secondary,
  },
}));

type DrawModeIconConfig = {
  icon: React.ReactNode;
  tooltip: string;
  shortcut: string;
};

const modes: Record<AnnotationDrawMode, DrawModeIconConfig> = {
  draw_annotation_line: {
    icon: <ShowChartOutlinedIcon />,
    tooltip: 'Line (distance)',
    shortcut: '1',
  },
  draw_annotation_polygon: {
    icon: <PentagonOutlinedIcon />,
    tooltip: 'Polygon (area)',
    shortcut: '2',
  },
  draw_annotation_circle: {
    icon: <RadioButtonUncheckedIcon />,
    tooltip: 'Circle (radius)',
    shortcut: '3',
  },
  draw_annotation_text: {
    icon: <FontDownloadOutlinedIcon />,
    tooltip: 'Text',
    shortcut: '4',
  },
  draw_annotation_area: {
    icon: <FontDownloadOutlinedIcon />,
    tooltip: 'Text',
    shortcut: '4',
  },
};

export const AnnotationToolbar = React.memo(_AnnotationToolbar);
function _AnnotationToolbar() {
  const { classes } = useStyles();

  const {
    drawMode,
    setDrawMode,
    areFeaturesSelected,
    setSelectedFeatures,
    numberOfDrawnFeatures,
    setNumberOfDrawnFeatures,
    convertToSelection,
  } = useAnnotations();

  const handleToggleMode = React.useCallback(
    (mode: AnnotationDrawMode) => {
      if (drawMode === 'draw_annotation_text') return;
      setDrawMode((prevMode) => (prevMode === mode ? 'simple_select' : mode));
    },
    [drawMode, setDrawMode]
  );

  const handleRemoveSelectedFeatures = React.useCallback(() => {
    const selectedFeatures = draw.getSelected().features;
    if (!isEmpty(selectedFeatures)) {
      draw.delete(selectedFeatures.map(({ id }: any) => id as string));
      /* Bad TS definition: deleteFeature should accept NonNullable<Feature['id']> rather than just a string */

      setSelectedFeatures([]);
      setNumberOfDrawnFeatures(draw.getAll().features.length);
    }
  }, [setNumberOfDrawnFeatures, setSelectedFeatures]);

  const handleUnselect = React.useCallback(() => {
    if (drawMode === 'simple_select' || drawMode === 'direct_select') return;
    setSelectedFeatures([]);
    setDrawMode('simple_select');
    draw.changeMode('simple_select');
  }, [drawMode, setDrawMode, setSelectedFeatures]);

  useHotkeys('1', () => handleToggleMode('draw_annotation_line'), [
    handleToggleMode,
  ]);
  useHotkeys('2', () => handleToggleMode('draw_annotation_polygon'), [
    handleToggleMode,
  ]);
  useHotkeys('3', () => handleToggleMode('draw_annotation_circle'), [
    handleToggleMode,
  ]);
  useHotkeys('4', () => handleToggleMode('draw_annotation_text'), [
    handleToggleMode,
  ]);
  useHotkeys(
    '0',
    () => {
      if (drawMode === 'draw_annotation_text') return;
      convertToSelection();
    },
    [drawMode, convertToSelection]
  );
  useHotkeys('delete', handleRemoveSelectedFeatures, [
    handleRemoveSelectedFeatures,
  ]);
  useHotkeys('esc', handleUnselect, [handleUnselect]);

  return (
    <Paper variant="outlined" className={classes.panel}>
      <Button
        color="inherit"
        variant="outlined"
        className={classes.panelButton}
        disabled={numberOfDrawnFeatures === 0}
        title="Convert to selection"
        onClick={convertToSelection}
      >
        <Badge
          badgeContent="0"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <SelectAllIcon />
        </Badge>
      </Button>

      <ToggleButtonGroup
        exclusive
        color="secondary"
        value={drawMode}
        onChange={(_e, mode) =>
          setDrawMode(!isNil(mode) ? mode : 'simple_select')
        }
      >
        {Object.entries(modes).map(([mode, config]) => {
          if (mode === 'draw_annotation_area') return null;
          return (
            <ToggleButton key={mode} value={mode} title={config.tooltip}>
              <Badge
                badgeContent={config.shortcut}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                {config.icon}
              </Badge>
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>

      <Button
        color="inherit"
        variant="outlined"
        className={classes.panelButton}
        disabled={!areFeaturesSelected}
        title="Delete"
        onClick={handleRemoveSelectedFeatures}
      >
        <DeleteIcon />
      </Button>
    </Paper>
  );
}
