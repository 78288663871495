import { UnitSystem } from '@pn/core/domain/types';
import { centerMapOnWorkspaceItem } from '@pn/core/operations/mapInteractions';
import {
  appActions,
  useAppStorage,
  useWorkspaceStorage,
} from '@pn/core/storage';
import { useHotkeys } from 'react-hotkeys-hook';

/**
 * A collection of all hotkeys that interact with pn-core features.
 */
export function useKeyboardHotkeys() {
  const { unitSystem } = useAppStorage();
  const { workspaceItemSelected } = useWorkspaceStorage();

  useHotkeys(
    'ctrl+alt+q',
    () => centerMapOnWorkspaceItem(workspaceItemSelected),
    [workspaceItemSelected]
  );

  useHotkeys(
    'ctrl+alt+a',
    () =>
      appActions().updateUnitSystem(
        unitSystem === UnitSystem.Metric
          ? UnitSystem.Imperial
          : UnitSystem.Metric
      ),
    [unitSystem]
  );
}
