import { dependencies } from '@pn/core/dependencies';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import {
  projectsActions,
  useCurrentUserStorage,
  useProjectsStorage,
} from '@pn/core/storage';
import { isNil } from 'lodash-es';
import React from 'react';

export function useQuickProjectActions() {
  const { notificationService } = dependencies;

  const { user } = useCurrentUserStorage();
  const { projects } = useProjectsStorage();

  const generalProject = React.useMemo(() => {
    return projects.find((project) => project.name === 'General');
  }, [projects]);

  const isItemShared = (item: WorkspaceItem): boolean => {
    if (isNil(generalProject)) {
      // notificationService.notify('General project not found', 'error');
      return false;
    }

    return generalProject.workspaceItemIds.some((id) => id === item.id);
  };

  const toggleShared = (item: WorkspaceItem, checked: boolean): void => {
    if (isNil(generalProject)) {
      notificationService.notify('General project not found', 'error');
      return;
    }

    if (checked) {
      projectsActions().addItem(generalProject.id, item);
    } else {
      projectsActions().removeItem(generalProject.id, item);
    }
  };

  return {
    showSharingToggle: !isNil(user?.enterprise),
    isItemShared,
    toggleShared,
  };
}
