import { useMapSelectionProcessor } from '@pn/core/operations/mapInteractions';
import { useAccess } from '@pn/core/permissions/access';
import { isEmbedded } from '@pn/core/utils/embedded';
import { shrinkBbox } from '@pn/core/utils/geospatial';
import { toMapboxBbox } from '@pn/services/map/mapbox/mapboxUtils';
import {
  isMapboxDataFeature,
  mapboxMapFeatureMapper,
} from '@pn/services/map/mapbox/mappers/mapboxMapFeatureMapper';
import { isAnnotationDrawMode } from '@pn/services/styles/annotation';
import { useAnnotations } from '@pn/ui/annotations/AnnotationProvider';
import { isEmpty } from 'lodash-es';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import { map } from 'src/application/externalDependencies';

let start: mapboxgl.Point; // starting xy coordinates when `mousedown` occured
let current: mapboxgl.Point; // current xy coordinates when `mousemove` or `mouseup` occurs
let box: HTMLElement | undefined; // draw box element

export function useMapBoxSelection() {
  const { processMixedMapSelection } = useMapSelectionProcessor();

  const access = useAccess();

  const { isAnnotationInterfaceOpen, drawMode } = useAnnotations();

  const handleMapMouseDown = React.useCallback(
    (e: MouseEvent) => {
      if (
        isEmbedded() ||
        !e.shiftKey ||
        isAnnotationInterfaceOpen ||
        isAnnotationDrawMode(drawMode)
      ) {
        return;
      }

      map._native.dragPan.disable();
      const canvas = map._native.getCanvasContainer();

      start = mousePos(e); // capture the first xy coordinates

      // Returns the xy coordinates of the mouse position
      function mousePos(e: MouseEvent) {
        const rect = canvas.getBoundingClientRect();

        const x = e.clientX - rect.left - canvas.clientLeft;
        const y = e.clientY - rect.top - canvas.clientTop;

        return new mapboxgl.Point(x, y);
      }

      function onMouseMove(e: MouseEvent) {
        // Capture the ongoing xy coordinates
        current = mousePos(e);

        // Append the box element if it doesnt exist
        if (!box) {
          box = document.createElement('div');
          box.classList.add('mapbox-boxdraw');
          canvas.appendChild(box);
        }

        const minX = Math.min(start.x, current.x);
        const maxX = Math.max(start.x, current.x);
        const minY = Math.min(start.y, current.y);
        const maxY = Math.max(start.y, current.y);

        // Adjust width and xy position of the box element ongoing
        const pos = 'translate(' + minX + 'px,' + minY + 'px)';
        box.style.transform = pos;
        box.style.width = maxX - minX + 'px';
        box.style.height = maxY - minY + 'px';
      }

      function onMouseUp(e: MouseEvent) {
        finish([start, mousePos(e)]);
      }

      function finish(bbox: [mapboxgl.Point, mapboxgl.Point]) {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);

        map._native.dragPan.enable();

        if (box) {
          box.parentNode!.removeChild(box);
          box = undefined;
        }

        if (access('multiSelection').notify().denied()) return;

        const bboxResized = shrinkBbox(bbox);

        const allFeatures = map._native.queryRenderedFeatures(
          toMapboxBbox(bboxResized)
        );

        const dataFeatures = allFeatures.filter(isMapboxDataFeature);

        if (isEmpty(dataFeatures)) return;

        processMixedMapSelection({
          features: dataFeatures.map(mapboxMapFeatureMapper.toDomainMapFeature),
          append: e.ctrlKey,
        });
      }

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    [isAnnotationInterfaceOpen, drawMode, access, processMixedMapSelection]
  );

  React.useEffect(() => {
    const canvas = map._native.getCanvasContainer();

    /**
     * Set `true` to dispatch the event before other functions call it.
     * This is necessary for disabling the default map dragging behaviour.
     */
    canvas.addEventListener('mousedown', handleMapMouseDown, true);

    return () => {
      canvas.removeEventListener('mousedown', handleMapMouseDown, true);
    };
  }, [handleMapMouseDown]);
}
