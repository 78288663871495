import { Box, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { formatDataType, type DataItemId } from '@pn/core/domain/data';
import { getPermissionLevel } from '@pn/core/domain/user';
import {
  useCurrentUserStorage,
  workspaceActions,
} from '@pn/core/storage';
import React from 'react';

type Props = {
  disabled: boolean;
  itemId: string;
  dataType: string;
  numberOfIds: number;
  requestedIds: DataItemId[];
};

export const RequestedIdsInput = React.memo(_RequestedIdsInput);
function _RequestedIdsInput({
  disabled,
  itemId,
  dataType,
  numberOfIds,
  requestedIds,
}: Props) {
  const { user } = useCurrentUserStorage();

  const placeholderText = React.useMemo(() => {
    let text = `Enter ${formatDataType(dataType, {
      form: 'singular',
    })} IDs separated by new lines:`;

    switch (dataType) {
      case 'wells':
        text += '\n100070201509W400';
        break;
      case 'wells_usa':
        text += '\n25015215720000';
        break;
      case 'pipelines':
        text += '\nAB-4331-55';
        break;
      case 'facilities':
        text += '\nABBT0087169 or 07-32-057-19W5';
        break;
      case 'mineral_rights':
        text += '\nAB-0010000123456-00';
        break;
      case 'units':
        text += '\nAB-0850000000002';
        break;
      case 'pools':
        text += '\nAB-1234-567890';
        break;
      case 'dispositions':
      case 'dispositions_historical':
        text += '\nAB_MSL771638';
        break;
      default:
        break;
    }

    return text;
  }, [dataType]);

  const limit = getPermissionLevel(user?.userPlan) < 2 ? 5 : 10e3;
  const maxIdsExceeded = numberOfIds > limit;
  const helperText = maxIdsExceeded
    ? getPermissionLevel(user?.userPlan) < 2
      ? `Your plan supports lists of up to ${limit.toLocaleString()} ${formatDataType(
          dataType
        )}`
      : `Lists cannot exceed ${limit.toLocaleString()} ${formatDataType(
          dataType
        )}`
    : undefined;
  const idsAsStringWithNewlines = requestedIds.join('\r\n');

  return (
    <Box position="relative">
      <TextField
        fullWidth
        variant="outlined"
        multiline
        rows={10}
        placeholder={placeholderText}
        value={idsAsStringWithNewlines}
        error={maxIdsExceeded}
        disabled={disabled}
        helperText={helperText}
        onChange={(e) =>
          workspaceActions().updateQueryProperty(itemId, {
            requestedIds: e.target.value.split('\n'),
          })
        }
      />
      <Box position="absolute" right={8} bottom={maxIdsExceeded ? 24 : 2}>
        <Typography variant="caption" color={disabled ? grey[500] : 'caption'}>
          {numberOfIds.toLocaleString()} total
        </Typography>
      </Box>
    </Box>
  );
}
