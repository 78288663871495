import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import BarChartIcon from '@mui/icons-material/BarChart';
import LockIcon from '@mui/icons-material/Lock';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SettingsIcon from '@mui/icons-material/Settings';
import WindowIcon from '@mui/icons-material/Window';
import { Box, ToggleButton, Tooltip } from '@mui/material';
import { isPNDataType, type PNDataType } from '@pn/core/domain/data';
import {
  convertProductionItem,
  type ProductionItem,
} from '@pn/core/domain/production';
import type { UnitSystem } from '@pn/core/domain/types';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { ConfigurationError } from '@pn/core/errors';
import { useAccess } from '@pn/core/permissions/access';
import { useAutoGetProduction } from '@pn/core/providers/production/getProduction';
import {
  appActions,
  useAppStorage,
  useCurrentUserStorage,
  useProductionStorage,
  useWorkspaceStorage,
} from '@pn/core/storage';
import { isCordova, isFreeCordova } from '@pn/core/utils/env';
import {
  getInitialChartControls,
  useStoreControlsToLocalStorage,
} from '@pn/services/charts/hooks/useChartLocalStorage';
import { useChartMode } from '@pn/services/charts/hooks/useChartMode';
import { useChartScale } from '@pn/services/charts/hooks/useChartScale';
import { useChartSeries } from '@pn/services/charts/hooks/useChartSeries';
import { useDisplayMode } from '@pn/services/charts/hooks/useDisplayMode';
import {
  ChartScale,
  ChartSeries,
  ChartSeriesStyle,
  ChartSeriesStyles,
  ProductionChartMode,
} from '@pn/services/charts/types';
import { downloadProductionItems } from '@pn/services/exports/production/downloadProductionItems';
import { ProductionSummary } from '@pn/ui/charts/ProductionSummary';
import { ProductionTable } from '@pn/ui/charts/ProductionTable';
import { SingleProductionChart } from '@pn/ui/charts/SingleProductionChart';
import { ChartEmptyState } from '@pn/ui/charts/components/ChartEmptyState';
import { ChartLoadingIndicator } from '@pn/ui/charts/components/ChartLoadingIndicator';
import { ChartModeSelector } from '@pn/ui/charts/controls/ChartModeSelector';
import { ChartScaleSelector } from '@pn/ui/charts/controls/ChartScaleSelector';
import { ChartSeriesSelector } from '@pn/ui/charts/controls/ChartSeriesSelector';
import { ChartUnitsSelector } from '@pn/ui/charts/controls/ChartUnitsSelector';
import { CustomButton } from '@pn/ui/custom-components/CustomButton';
import { EmptyStateWithIcon } from '@pn/ui/custom-components/EmptyStateWithIcon';
import { ExclusiveToggleButtonGroup } from '@pn/ui/custom-components/ExclusiveToggleButtonGroup';
import { UnauthorizedAlert } from '@pn/ui/custom-components/UnauthorizedAlert';
import { PermissionButton } from '@pn/ui/custom-components/permission/PermissionButton';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { isEmpty, isNil, startCase } from 'lodash-es';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuthenticationService } from 'src/application/externalDependencies';
import { AuthLink } from 'src/web-ui/components/AuthLink';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    height: '100%',
  },
  chart: {
    position: 'relative',
    height: '100%',
    maxHeight: 651,
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    // marginBottom: theme.spacing(-1),
    '@media (orientation: landscape)': {
      [theme.breakpoints.down('md')]: {
        minHeight: 325,
        maxHeight: 325,
      },
    },
    '@media (min-height: 800px)': {
      minHeight: 651,
    },
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    '@media (orientation: landscape)': {
      [theme.breakpoints.down('md')]: {
        minHeight: 'auto',
      },
    },
    '@media (min-height: 800px)': {
      minHeight: 651,
    },
  },
  summaryTable: {
    maxWidth: 336,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'initial',
    },
  },
  settingsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    // '@media (orientation: landscape)': {
    //   [theme.breakpoints.down('md')]: {
    //     minHeight: 'auto',
    //   },
    // },
    // '@media (min-height: 800px)': {
    //   minHeight: 651,
    // },
  },
  chartSelectorsContainer: {
    display: 'flex',
    // flexDirection: 'row-reverse',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
  toggleButton: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    textTransform: 'none',
  },
  toggleButtonIcon: {
    position: 'relative',
    top: -1,
    marginRight: theme.spacing(-0.5),
    marginLeft: theme.spacing(1),
    fontSize: 20,
  },
}));

type Props = {
  item: WorkspaceItem;
};

export const Production = ({ item }: Props) => {
  if (!isPNDataType(item.dataType)) {
    throw new ConfigurationError(
      `Cannot render Production panel for a non-PN data type [${item.dataType}]`
    );
  }

  const { classes } = useStyles();
  const { smScreen } = useScreenSize();

  const { isAuthenticated } = useAuthenticationService();
  const { user } = useCurrentUserStorage();
  const { unitSystem } = useAppStorage();
  const { dataItemRequested } = useWorkspaceStorage();
  const { isFetching, productionItems } = useProductionStorage(item.dataType);

  const convertedProductionItems = React.useMemo(
    () =>
      productionItems.map((item) => convertProductionItem(item, unitSystem)),
    [productionItems, unitSystem]
  );

  useAutoGetProduction({
    productionChartType: item.dataType,
    id: dataItemRequested.id,
  });

  const {
    initDisplayMode,
    initScale,
    initMode,
    initSeries,
    initSeriesStyles,
  } = React.useMemo(() => getInitialChartControls(), []); // re-runs on data item change

  const { displayMode, handleChangeDisplayMode } =
    useDisplayMode(initDisplayMode);
  const { scale, handleChangeScale } = useChartScale(initScale);
  const { mode, handleChangeMode } = useChartMode(initMode);
  const {
    series,
    seriesStyles,
    showSeriesAlert,
    handleChangeSeries,
    handleChangeAllSeries,
    handleUpsertSeriesStyle,
    handleRemoveSeriesStyle,
  } = useChartSeries(productionItems, {
    multiSelection: true,
    initSeries,
    initSeriesStyles,
  });

  useStoreControlsToLocalStorage({
    displayMode,
    scale,
    mode,
    series,
    seriesStyles,
  });

  const handleDownloadCsv = () => {
    downloadProductionItems({
      productionItems: convertedProductionItems,
      mode,
    }).csv({
      fileName: `${dataItemRequested.id} - ${startCase(mode)}.csv`,
    });
  };

  if (!isAuthenticated) {
    return (
      <EmptyStateWithIcon
        variant="filled"
        icon={LockIcon}
        text={
          <span>
            Please <AuthLink type="login">login</AuthLink> or{' '}
            <AuthLink type="signup">sign up</AuthLink> to view production data
          </span>
        }
      />
    );
  }

  if (isNil(dataItemRequested.id)) {
    return null;
  }

  return (
    <Box className={classes.container}>
      <ExclusiveToggleButtonGroup
        fullWidth
        color="primary"
        value={displayMode}
        onChange={(_event, newDisplayMode) =>
          handleChangeDisplayMode(newDisplayMode)
        }
        aria-label="chart/table toggle buttons"
      >
        <ToggleButton className={classes.toggleButton} value="chart">
          Chart
          <BarChartIcon className={classes.toggleButtonIcon} />
        </ToggleButton>
        <ToggleButton className={classes.toggleButton} value="table">
          Table
          <WindowIcon className={classes.toggleButtonIcon} />
        </ToggleButton>
        {!isFreeCordova(user) && (
          <ToggleButton className={classes.toggleButton} value="settings">
            Settings
            {showSeriesAlert && !isFetching ? (
              <Tooltip title="Some default series are inactive">
                <ReportProblemIcon
                  fontSize="small"
                  color="warning"
                  className={classes.toggleButtonIcon}
                />
              </Tooltip>
            ) : (
              <SettingsIcon className={classes.toggleButtonIcon} />
            )}
          </ToggleButton>
        )}
      </ExclusiveToggleButtonGroup>

      <DisplayArea
        isFetching={isFetching}
        convertedProductionItems={convertedProductionItems}
        dataType={item.dataType}
        displayMode={displayMode}
        unitSystem={unitSystem}
        scale={scale}
        mode={mode}
        series={series}
        seriesStyles={seriesStyles}
        onChangeScale={handleChangeScale}
        onChangeMode={handleChangeMode}
        onChangeSeries={handleChangeSeries}
        onChangeAllSeries={handleChangeAllSeries}
        onUpsertSeriesStyle={handleUpsertSeriesStyle}
        onRemoveSeriesStyle={handleRemoveSeriesStyle}
      />

      {!isCordova() && !smScreen && displayMode !== 'settings' && (
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" gap={2}>
            <PermissionButton
              permissionPath="production.export"
              isCustom
              onClick={handleDownloadCsv}
              startIcon={<ArrowDownwardIcon />}
            >
              Download CSV
            </PermissionButton>
            {item.dataType === 'wells' && (
              <PermissionButton
                permissionPath="production.forecast"
                isCustom
                component={Link}
                to={`/wells/${dataItemRequested.id}/decline_analysis`}
                target="_blank"
                startIcon={<NorthEastIcon />}
              >
                Forecast
              </PermissionButton>
            )}
          </Box>

          <Box>
            <CustomButton
              v3type="filled_tonal"
              variant="contained"
              color="primary"
              component={Link}
              to={`/production/${item.dataType}/${dataItemRequested.id}`}
              target="_blank"
              startIcon={<NorthEastIcon />}
            >
              Full View
            </CustomButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

type DisplayAreaProps = {
  isFetching: boolean;
  convertedProductionItems: ProductionItem[];
  dataType: PNDataType;
  displayMode: 'chart' | 'table' | 'settings';
  unitSystem: UnitSystem;
  scale: ChartScale;
  mode: ProductionChartMode;
  series: ChartSeries;
  seriesStyles: ChartSeriesStyles;
  onChangeScale: (scale: ChartScale) => void;
  onChangeMode: (mode: ProductionChartMode) => void;
  onChangeSeries: (key: string, checked: boolean) => void;
  onChangeAllSeries: (checked: boolean) => void;
  onUpsertSeriesStyle: (grouping: string, style: ChartSeriesStyle) => void;
  onRemoveSeriesStyle: (grouping: string) => void;
};

const DisplayArea = ({
  isFetching,
  convertedProductionItems,
  dataType,
  displayMode,
  unitSystem,
  scale,
  mode,
  series,
  seriesStyles,
  onChangeScale,
  onChangeMode,
  onChangeSeries,
  onChangeAllSeries,
  onUpsertSeriesStyle,
  onRemoveSeriesStyle,
}: DisplayAreaProps) => {
  const { classes } = useStyles();

  const access = useAccess();
  const areChartControlsDisabled = access('production.chartControls').denied();

  const handleChangeUnitSystem = React.useCallback(
    (unitSystem: UnitSystem) => appActions().updateUnitSystem(unitSystem),
    []
  );

  if (isFetching) {
    return (
      <Box className={classes.chart} px={2}>
        <ChartLoadingIndicator />
      </Box>
    );
  }

  if (isEmpty(convertedProductionItems)) {
    return (
      <Box className={classes.chart} px={2}>
        <ChartEmptyState text="No production data" />
      </Box>
    );
  }

  switch (displayMode) {
    case 'chart':
      return (
        <Box className={classes.chart}>
          <SingleProductionChart
            productionChartType={dataType}
            productionItems={convertedProductionItems}
            unitSystem={unitSystem}
            scale={scale}
            mode={mode}
            series={series}
            seriesStyles={seriesStyles}
            chartView="main-panel"
          />
        </Box>
      );
    case 'table':
      return (
        <Box className={classes.table}>
          <Box className={classes.summaryTable}>
            <ProductionSummary
              productionItems={convertedProductionItems}
              unitSystem={unitSystem}
            />
          </Box>
          <Box height={448}>
            <ProductionTable
              productionChartType={dataType}
              productionItems={convertedProductionItems}
              chartMode={mode}
            />
          </Box>
        </Box>
      );
    case 'settings':
      return (
        <Box className={classes.settingsContainer}>
          <UnauthorizedAlert path="production.chartControls" />
          <Box className={classes.chartSelectorsContainer}>
            <Box display="flex" flexDirection="column" gap={2} flex={1}>
              <ChartUnitsSelector
                fullWidth
                unitSystem={unitSystem}
                onChange={handleChangeUnitSystem}
              />
              <ChartScaleSelector
                fullWidth
                disabled={areChartControlsDisabled}
                scale={scale}
                onChange={onChangeScale}
              />
              <ChartModeSelector
                disabled={areChartControlsDisabled}
                mode={mode}
                onChange={onChangeMode}
              />
            </Box>
            <Box flex={1}>
              <ChartSeriesSelector
                disabled={areChartControlsDisabled}
                series={series}
                seriesStyles={seriesStyles}
                multiSelection
                onChange={onChangeSeries}
                onChangeAll={onChangeAllSeries}
                onUpsertSeriesStyle={onUpsertSeriesStyle}
                onRemoveSeriesStyle={onRemoveSeriesStyle}
              />
            </Box>
          </Box>
        </Box>
      );
  }
};
