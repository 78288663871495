/**
 * This implementation is strongly coupled with the react-console-emulator package.
 * We only use CLI for debugging, so it's OK to cut some corners here.
 * {@link https://github.com/linuswillner/react-console-emulator}
 */

import { dependencies } from '@pn/core/dependencies';
import type { DataItemId } from '@pn/core/domain/data';
import { DataMultiSelectionReason } from '@pn/core/domain/query';
import { createWorkspaceItem } from '@pn/core/domain/workspace';
import { getMapDataItems } from '@pn/core/operations/workspace/mapData';
import {
  useAppStorage,
  useCurrentUserStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { mineral_rights } from '@pn/core/storage/workspace/pnWorkspaceItems/mineral_rights';
import { pnApiClient } from '@pn/services/api/pnApiClient';
import { downloadDataItems } from '@pn/services/exports/data/downloadDataItems';
import download from 'downloadjs';
import { isEmpty, isNil } from 'lodash-es';
import { useTableFields } from './dataTableInteractions';

export function useCliCommands() {
  const { map, errorLogger } = dependencies;
  const mapboxMap = map._native as mapboxgl.Map;

  const { unitSystem } = useAppStorage();
  const { user } = useCurrentUserStorage();
  const { workspaceItemSelected } = useWorkspaceStorage();

  const { fields } = useTableFields(workspaceItemSelected?.dataType ?? '');

  return {
    'view-store': {
      description: 'Dump the store to the console.',
      fn: function () {
        console.log(dependencies.store.getState());
        return 'Store dumped to the console.';
      },
    },
    'view-map-style': {
      description: 'Dump the map style to the console.',
      fn: function () {
        const mapStyle = mapboxMap.getStyle();
        console.log(mapStyle);
        return `Map style dumped to the console.`;
      },
    },
    'view-zoom': {
      description: 'View the current zoom level.',
      fn: function () {
        const zoom = mapboxMap.getZoom();
        return `Zoom level: ${zoom}.`;
      },
    },
    'set-zoom': {
      description: 'Set the zoom level.',
      fn: function (zoom: number) {
        mapboxMap.setZoom(zoom);
        return `Zoom level set: ${zoom}.`;
      },
    },
    'export-geojson': {
      description: 'Export current layer/selection as GeoJSON.',
      fn: function () {
        if (isNil(workspaceItemSelected)) return 'No layer selected.';

        if (workspaceItemSelected.itemType === 'annotation') {
          const geojsonString = JSON.stringify(
            workspaceItemSelected.map.layers[0].source.data
          );
          download(geojsonString, 'annotation.geojson', 'application/json');
          return 'Annotation GeoJSON downloaded.';
        }

        const mapDataItems = getMapDataItems(workspaceItemSelected);
        if (isEmpty(mapDataItems)) return 'No data items to export.';

        downloadDataItems({
          data: getMapDataItems(workspaceItemSelected),
          fields,
          mapping: workspaceItemSelected.mapping,
          unitSystem,
        }).geojson({
          fileName: (workspaceItemSelected.name || 'my_export') + '.geojson',
        });

        return 'GeoJSON downloaded.';
      },
    },
    'visualize-formation': {
      description: 'Show all mineral rights for a given formation name.',
      fn: async function (formation: string) {
        const internal_ids = await pnApiClient.request<DataItemId[]>({
          method: 'POST',
          url: 'v2/mineral_rights',
          payload: {
            formations: [formation],
          },
        });

        const newItem = createWorkspaceItem(
          {
            source: 'item',
            sourceItem: mineral_rights,
            temporarySourceItemId: mineral_rights.id,
            name: `Mineral Rights: ${formation}`,
            queryOptions: {
              requestedIds: internal_ids,
              multiSelectionReason: DataMultiSelectionReason.List,
            },
          },
          user
        );

        workspaceActions().add(newItem);
        workspaceActions().addToWorkspace(newItem.id);
        workspaceActions().select(newItem.id);
      },
    },
    'test-error': {
      description: 'Trigger a test error.',
      fn: function () {
        errorLogger.logGenericError(
          new Error('Test error triggered'),
          user?.id,
          'TestError'
        );
        return 'Test error triggered and logged.';
      },
    },
    'god-mode': {
      description: 'Activate god mode.',
      fn: function () {
        window.location.href = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ';
      },
    },
  };
}
