import {
  formatDataType,
  type DataItem,
  type DataItemId,
  type MappingItem,
} from '@pn/core/domain/data';
import {
  isAnnotationSource,
  type FilterProperty,
  type Layer,
} from '@pn/core/domain/layer';
import type { DataSelectionReason, Query } from '@pn/core/domain/query';
import type { ISODateTimeString } from '@pn/core/domain/types';
import type { ProjectUser } from '@pn/core/domain/user';
import { getMapDataItems } from '@pn/core/operations/workspace/mapData';
import { getGeometriesAreaInKm2 } from '@pn/core/utils/geospatial';
import { hasKeyWithType } from '@pn/core/utils/logic';
import { isEmpty, isNil, isObject, isString } from 'lodash-es';

export type ApiDataSource = {
  type: 'api';
  source: 'elastic' | 'postgres' | 'parquet';
  url: string;
  requiredMapDataFields: string[];
};
export type GeoJsonDataSource = {
  type: 'geojson';
  url: string;
};
export type NoDataSource = {
  type: 'none';
};

type BaseWorkspaceItem = WorkspaceItemInternalState & {
  itemType: 'layer' | 'list' | 'annotation';
  folder: string;
  isTemporary: boolean;
  temporarySourceItemId?: WorkspaceItem['id']; // tracks the origin of a temporary item
  id: string;
  dataType: string;
  numberOfElements: number | undefined;
  colorIndicator?: string;
  name: string;
  description?: string;
  origin: 'pn' | 'stackdx' | 'boe_intel';
  originUrl?: string;
  createdAt: ISODateTimeString;
  updatedAt: ISODateTimeString;
  createdBy: ProjectUser | undefined;
  isGlobal: boolean;
  map: {
    layers: Layer[];
    filterProperty?: FilterProperty;
  };
  dataSource: ApiDataSource | GeoJsonDataSource | NoDataSource;
  detailsSource: 'api' | 'local';
  query: Query;
  module?: 'background' | 'grids';
  metadata?: {
    isQueryArea?: boolean;
    dataOriginDescription?: string;
  };
};

export type WorkspaceItemInternalState = {
  _unsavedChanges: boolean;

  isVisible: boolean;
  isProcessed: boolean;
  isRendered: boolean;

  isLoaded: boolean; // indicates if `query.requestedIds` has been fetched

  totalCount: number;
  streamedCount: number;

  mapping: MappingItem[];
  isMappingInitialized: boolean;

  tableDataItems: DataItem[];
  requestedDataItem: {
    id: DataItemId | undefined;
    reason: DataSelectionReason | undefined;
  };
};

export type WorkspaceItem = BaseWorkspaceItem & {
  sourceItem?: BaseWorkspaceItem;
};

export function isWorkspaceItem(arg: unknown): arg is WorkspaceItem {
  return isObject(arg) && hasKeyWithType(arg, 'itemType', isString);
}

export function getDataItemSelected(item: WorkspaceItem): DataItem | undefined {
  return item.tableDataItems.find(
    (dataItem) => dataItem._id === item.requestedDataItem.id
  );
}

export function isNonStreamableItem(item: WorkspaceItem): boolean {
  return isNil(item.sourceItem) && item.dataSource.type === 'api';
}
export function isStreamableItem(item: WorkspaceItem): boolean {
  return !isNil(item.sourceItem) && item.dataSource.type === 'api';
}

export function isPartialDataReceived(item: WorkspaceItem): boolean {
  return (
    item?.isRendered &&
    item.streamedCount > 0 &&
    item.totalCount > item.streamedCount
  );
}

export function isEmptyWorkspaceItem(item: WorkspaceItem): boolean {
  return (
    !isNil(item.sourceItem) &&
    isEmpty(item.query.requestedIds) &&
    isEmpty(item.query.filters)
  );
}

export function getNumberOfIds(item: WorkspaceItem): number {
  return isEmpty(item.query.filters)
    ? item.query.requestedIds.filter((id) => !isEmpty(id.toString())).length
    : getMapDataItems(item).length;
}

export function getFormattedItemName(item: WorkspaceItem | undefined): string {
  if (isNil(item)) return 'N/A';

  if (!isEmpty(item.name)) return item.name;

  const { requestedIds, filters } = item.query;

  if (!isEmpty(requestedIds) && isEmpty(filters)) {
    return `Selection of ${formatDataType(item.dataType)}`;
  } else if (isEmpty(requestedIds) && !isEmpty(filters)) {
    return `Filtered ${formatDataType(item.dataType)}`;
  } else if (!isEmpty(requestedIds) && !isEmpty(filters)) {
    return `Filtered selection of ${formatDataType(item.dataType)}`;
  } else {
    return 'New layer (unsaved)';
  }
}

export function getItemColor(item: WorkspaceItem | undefined): unknown {
  if (isNil(item)) return 'transparent';
  if (!isNil(item.colorIndicator)) return item.colorIndicator;

  return item.map.layers[0].style.color;
}

/**
 * Only works for annotations.
 */
export function getWorkspaceItemGeometries(
  item: WorkspaceItem
): GeoJSON.Geometry[] {
  if (!isAnnotationSource(item.map.layers[0].source)) return [];

  return item.map.layers[0].source.data.features.map(
    ({ geometry }) => geometry
  );
}

/**
 * Only works for annotations.
 * @returns total area in km^2
 */
export function getWorkspaceItemArea(item: WorkspaceItem): number {
  const geometries = getWorkspaceItemGeometries(item);
  return getGeometriesAreaInKm2(geometries);
}

/* PORTS */

export interface IWorkspaceItemMapper<T, V = T> {
  toWorkspaceItem: (item: T, sourceItem?: WorkspaceItem) => WorkspaceItem;
  toOriginalItem: (item: WorkspaceItem) => V;
}
