import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import { Box, Button } from '@mui/material';
import { isEmbedded } from '@pn/core/utils/embedded';
import { useFreshdesk } from '@pn/services/support/useFreshdesk';
import { DataTable } from '@pn/ui/data-table';
import React from 'react';
import { ShortcutsDialog } from 'src/web-ui/components/ShortcutsDialog';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  buttonsContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    position: 'absolute',
    left: theme.spacing(1),
    bottom: theme.spacing(1),
  },
  button: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textTransform: 'none',
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}));

export const BottomTable = React.memo(_BottomTable);
function _BottomTable() {
  const { classes } = useStyles();

  const { handleOpenHelp } = useFreshdesk('petro-ninja');

  const [isShortcutsDialogOpen, setShortcutsDialogOpen] = React.useState(false);

  return (
    <>
      <DataTable
        enableRowClicks
        toolbarProps={{
          show: !isEmbedded(),
          showColumnsSelector: true,
          showStyleButton: true,
        }}
      />

      {!isEmbedded() && (
        <Box className={classes.buttonsContainer}>
          <Button
            className={classes.button}
            color="inherit"
            variant="contained"
            onClick={handleOpenHelp}
            endIcon={<HelpOutlineIcon className={classes.icon} />}
          >
            Help
          </Button>
          <Button
            className={classes.button}
            color="inherit"
            variant="contained"
            onClick={() => setShortcutsDialogOpen(true)}
            endIcon={<KeyboardCommandKeyIcon className={classes.icon} />}
          >
            Shortcuts
          </Button>

          <ShortcutsDialog
            open={isShortcutsDialogOpen}
            onClose={() => setShortcutsDialogOpen(false)}
          />
        </Box>
      )}
    </>
  );
}
