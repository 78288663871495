import type { PostgresMappingItem } from '@pn/services/api/data/types';

export const wellsByLiquidProductionMapping: PostgresMappingItem[] = [
  {
    field: 'wellbore_uwi',
    label: 'UWI',
    isShownByDefault: true,
    width: 175,
    sourceType: 'int',
  },
  {
    field: 'licensee',
    label: 'Licensee',
    width: 175,
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'oil_and_condensate_bbl_per_day',
    label: 'Oil + Condensate',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'bbl/d',
    },
  },
  {
    field: 'oil_bbl_per_day',
    label: 'Oil',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'bbl/d',
    },
  },
  {
    field: 'condensate_bbl_per_day',
    label: 'Condensate',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'bbl/d',
    },
  },
  {
    field: 'gas_mcf_per_day',
    label: 'Gas',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'mcf/d',
    },
  },
  {
    field: 'formation',
    label: 'Formation',
    domainType: 'string',
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'field_name',
    label: 'Field',
    domainType: 'string',
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'production_hours',
    domainType: 'number',
    label: 'Producing Hours',
    isShownByDefault: true,
    width: 125,
    sourceType: 'date',
  },
  {
    field: 'type',
    domainType: 'string',
    label: 'Well Type',
    isShownByDefault: true,
    width: 200,
    sourceType: 'text',
  },
  {
    field: 'on_prod_date',
    domainType: 'DateString',
    label: 'On Prod',
    isShownByDefault: true,
    width: 100,
    sourceType: 'date',
  },
  {
    field: 'cum_oil_volume',
    label: 'Oil Cumulative',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'bbl',
    },
  },
];

export const wellsByGasProduction: PostgresMappingItem[] = [
  {
    field: 'wellbore_uwi',
    label: 'UWI',
    isShownByDefault: true,
    width: 175,
    sourceType: 'int',
  },
  {
    field: 'licensee',
    label: 'Licensee',
    width: 175,
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'gas_mcf_per_day',
    label: 'Gas',
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'mcf/d',
    },
    isShownByDefault: true,
    sourceType: 'float',
  },
  {
    field: 'oil_bbl_per_day',
    label: 'Oil',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'bbl/d',
    },
  },
  {
    field: 'condensate_bbl_per_day',
    label: 'Condensate',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: {
      symbol: 'bbl/d',
    },
  },
  {
    field: 'province',
    label: 'Province',
    isShownByDefault: true,
    sourceType: 'text',
    width: 60,
  },
  {
    field: 'formation',
    label: 'Formation',
    domainType: 'string',
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'field_name',
    label: 'Field',
    domainType: 'string',
    isShownByDefault: true,
    sourceType: 'text',
  },
  {
    field: 'production_hours',
    domainType: 'number',
    label: 'Producing Hours',
    isShownByDefault: true,
    width: 125,
    sourceType: 'date',
  },
  {
    field: 'type',
    domainType: 'string',
    label: 'Well Type',
    isShownByDefault: true,
    width: 200,
    sourceType: 'text',
  },
  {
    field: 'on_prod_date',
    domainType: 'DateString',
    label: 'On Prod',
    isShownByDefault: true,
    width: 100,
    sourceType: 'date',
  },
  {
    field: 'cum_gas_volume',
    label: 'Gas Cumulative',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    width: 100,
    domainTypeAttributes: {
      symbol: 'mcf',
    },
  },
];
