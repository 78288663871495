import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

export function PoolQuickInfo(props: any) {
  const { classes, dataItem, onClose } = props;

  return (
    <Box className={classes.container}>
      <Box width="100%">
        <Box display="flex">
          <Box width="100%">
            <Typography variant="body2" className={classes.fontWeight500}>
              {dataItem.field_code}-{dataItem.pool_code}
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Box width="50%">
            <Typography variant="body2" noWrap>
              {dataItem.pool_name}
            </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="body2" align="right">
              {dataItem.field_name}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box width="24px" ml={1} mr={-0.5}>
        <IconButton size="small" onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
