import type { ClassNameMap } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { CustomLink } from 'src/web-ui/components/CustomLink';

type Props = {
  title: string;
  classes: ClassNameMap;
};

export function LockedTable(props: Props) {
  const { title, classes } = props;

  return (
    <Box className={classes.container}>
      <Typography className={classes.tableTitle}>{title}</Typography>
      <Divider />

      <Box p={1}>
        <Typography variant="body2">
          Please{' '}
          <CustomLink to="https://about.petroninja.com/plans">
            sign up
          </CustomLink>{' '}
          for a Professional or Enterprise account to access this table.
        </Typography>
      </Box>
    </Box>
  );
}
