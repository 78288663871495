import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from '@mui/material';
import { getFormattedUserPlan, getFullName } from '@pn/core/domain/user';
import { useRouter } from '@pn/core/operations/router';
import { useAccess } from '@pn/core/permissions/access';
import { useCurrentUserStorage } from '@pn/core/storage';
import { BOE_INTEL_URL, BOE_REPORT_URL } from '@pn/core/urls';
import { isEmbedded } from '@pn/core/utils/embedded';
import { isCordova, isFreeCordova } from '@pn/core/utils/env';
import { NotificationTooltip } from '@pn/ui/custom-components/NotificationTooltip';
import { CustomListItemButton } from '@pn/ui/custom-components/list/CustomListItemButton';
import { useCopyToClipboard } from '@pn/ui/hooks/useCopyToClipboard';
import { BOEReportIcon } from '@pn/ui/icons/BOEReportIcon';
import { StackDXIcon } from '@pn/ui/icons/StackDXIcon';
import { isEmpty, isNil } from 'lodash-es';
import { Link } from 'react-router-dom';
import { useAuthenticationService } from 'src/application/externalDependencies';
import { appViews, getAppView } from 'src/web-ui/appViews';
import { AuthLink } from 'src/web-ui/components/AuthLink';
import { CustomLink } from 'src/web-ui/components/CustomLink';
import { ThemeSwitch } from 'src/web-ui/layout/ThemeSwitch';
import { UnitsSwitch } from 'src/web-ui/layout/UnitsSwitch';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ drawerWidth: number }>()(
  (theme, { drawerWidth }) => ({
    drawerPaper: {
      width: drawerWidth,
    },
    accountListItem: {
      height: 64,
    },
    avatar: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    userProfileListItemAvatar: {
      minWidth: `${40 + theme.spacing(1.5)}px`,
    },
    userProfilePrimary: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '0.925rem',
    },
    userProfileSecondary: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '0.8rem',
    },
    controlsContainer: {
      display: 'flex',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    icon: {
      minWidth: 40,
    },
    svgIcon: {
      marginLeft: 2,
      width: 20,
    },
    warningIcon: {
      position: 'relative',
      top: 5,
      marginRight: 2,
    },
    metadata: {
      marginTop: 'auto',
      textAlign: 'center',
      fontFamily: 'monospace',
      color: theme.palette.text.disabled,
    },
  })
);

type Props = {
  open: boolean;
  drawerWidth: number;
  onOpen: () => void;
  onClose: () => void;
};

export const MenuDrawer = ({ open, drawerWidth, onOpen, onClose }: Props) => {
  const { pathname } = useRouter();

  const access = useAccess();
  const { isAuthenticated, logout } = useAuthenticationService();
  const { user } = useCurrentUserStorage();

  const { classes } = useStyles({ drawerWidth });

  const { copyText } = useCopyToClipboard();

  if (isEmbedded()) return null;

  let primaryText: string | JSX.Element = '';
  let secondaryText = '';
  if (isAuthenticated && !isNil(user)) {
    const isNameEmpty =
      isEmpty(user.name.firstName) || isEmpty(user.name.lastName);
    primaryText = isNameEmpty ? 'My Account' : getFullName(user);
    if (isFreeCordova(user)) {
      secondaryText = '';
    } else if (!isNil(user.enterprise)) {
      secondaryText = user.enterprise.name;
    } else {
      secondaryText = getFormattedUserPlan(user?.userPlan);
    }
  } else {
    primaryText = (
      <span>
        <AuthLink type="login">Login</AuthLink> or{' '}
        <AuthLink type="signup">Sign up</AuthLink>
      </span>
    );
  }

  const handleFeedback = () => {
    try {
      if (user) {
        const emailSub = `Mobile App Feedback - ${getFullName(user)}`;
        const emailBody = `Username: ${user.email} Platform: iOS App: ${window.BuildInfo.versionCode}\n\n`;
        window.open(
          'mailto:support@petroninja.com' +
            '?subject=' +
            emailSub +
            '&body=' +
            emailBody,
          '_self'
        );
      } else {
        throw new Error('User not found');
      }
    } catch (err) {
      console.error(err);
      window.alert(
        'Unable to connect to your email interface. Please send us an email directly to support@petroninja.com'
      );
    }
  };

  return (
    <SwipeableDrawer
      disableDiscovery
      disableSwipeToOpen
      open={open}
      classes={{ paper: classes.drawerPaper }}
      onOpen={onOpen}
      onClose={onClose}
    >
      <List disablePadding>
        <CustomListItemButton
          isButton={isAuthenticated}
          className={classes.accountListItem}
          selected={pathname.startsWith('/account')}
          {...(isAuthenticated
            ? {
                component: Link as any,
                to: '/account',
              }
            : {})}
        >
          <ListItemAvatar className={classes.userProfileListItemAvatar}>
            <Avatar variant="rounded" className={classes.avatar}>
              <AccountCircleIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={primaryText}
            secondary={secondaryText}
            classes={{
              primary: classes.userProfilePrimary,
              secondary: classes.userProfileSecondary,
            }}
          />
        </CustomListItemButton>
        <Box className={classes.controlsContainer}>
          <ThemeSwitch disabled={!isAuthenticated} />
          <UnitsSwitch disabled={!isAuthenticated} />
        </Box>

        {appViews
          .filter(
            (v) =>
              !v.hidden &&
              (isFreeCordova(user) ? v.title !== 'Dashboards' : true)
          )
          .map((view) => (
            <ListItemButton
              key={view.title}
              component={Link}
              to={view.route}
              selected={view.title === getAppView(pathname)?.title}
            >
              <ListItemText primary={view.title} />
            </ListItemButton>
          ))}

        {access('enterprise.dashboard').granted() && (
          <ListItemButton
            component={Link}
            to="/enterprise"
            selected={pathname.startsWith('/enterprise')}
          >
            <ListItemText primary="Enterprise Dashboard" />
          </ListItemButton>
        )}

        <Box mt={1} />

        {!isFreeCordova(user) && (
          <ListItemButton
            component={CustomLink}
            to="https://about.petroninja.com"
            target="_blank"
          >
            <ListItemIcon className={classes.icon}>
              <InfoOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="About" />
          </ListItemButton>
        )}
        {!isCordova() && (
          <ListItemButton
            component={CustomLink}
            to="https://about.petroninja.com/plans"
            target="_blank"
          >
            <ListItemIcon className={classes.icon}>
              <CreditCardIcon />
            </ListItemIcon>
            <ListItemText primary="Plans" />
          </ListItemButton>
        )}
        {!isFreeCordova(user) && (
          <ListItemButton
            component={CustomLink}
            to="https://help.petroninja.com"
            target="_blank"
          >
            <ListItemIcon className={classes.icon}>
              <HelpOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Help Center" />
          </ListItemButton>
        )}

        {!isFreeCordova(user) && (
          <>
            <Box my={1} mx={2}>
              <Divider />
            </Box>
            <ListItemButton
              component={CustomLink}
              to="https://stackdx.com"
              target="_blank"
            >
              <ListItemIcon className={classes.icon}>
                <StackDXIcon className={classes.svgIcon} />
              </ListItemIcon>
              <ListItemText primary="StackDX" />
            </ListItemButton>
            <ListItemButton
              component={CustomLink}
              to={BOE_REPORT_URL}
              target="_blank"
            >
              <ListItemIcon className={classes.icon}>
                <BOEReportIcon className={classes.svgIcon} />
              </ListItemIcon>
              <ListItemText primary="BOE Report" />
            </ListItemButton>
            <ListItemButton
              component={CustomLink}
              to={BOE_INTEL_URL}
              target="_blank"
            >
              <ListItemIcon className={classes.icon}>
                <BOEReportIcon className={classes.svgIcon} />
              </ListItemIcon>
              <ListItemText primary="BOE Intel" />
            </ListItemButton>
          </>
        )}

        <Box mt={1} />

        {isCordova() && isAuthenticated && (
          <ListItemButton onClick={handleFeedback}>
            <ListItemText
              primary="Send feedback"
              primaryTypographyProps={{ color: 'secondary' }}
            />
          </ListItemButton>
        )}

        {isAuthenticated && (
          <ListItemButton onClick={() => logout()}>
            <ListItemText
              primary="Sign out"
              primaryTypographyProps={{ color: 'secondary' }}
            />
          </ListItemButton>
        )}
      </List>

      {!isNil(user) && (
        <ListItem className={classes.metadata}>
          <ListItemText
            primary={
              <span>
                {user.id.toString()}{' '}
                {!isCordova() && (
                  <NotificationTooltip
                    title="Copied your User ID to clipboard"
                    placement="left"
                  >
                    <IconButton
                      aria-label="copy User ID"
                      onClick={() => copyText(user.id.toString())}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </NotificationTooltip>
                )}
              </span>
            }
            disableTypography
          />
        </ListItem>
      )}
    </SwipeableDrawer>
  );
};
