import type { IDataInfoCountsMapper } from '@pn/core/mappers/data-info/ports';
import type { ApiDataCounts } from './types';

export const apiDataInfoCountsMapper: IDataInfoCountsMapper<ApiDataCounts> = {
  toDomainCounts: (apiDataCounts) => {
    return {
      documents: apiDataCounts.document_count,
    };
  },
};
