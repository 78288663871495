import {
  DataMultiSelectionReason,
  isSingleSelectionReason,
  type Filter,
  type LinkOperator,
} from '@pn/core/domain/query';
import {
  createWorkspaceItem,
  getItemColor,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { useVisualizeWorkspaceItem } from '@pn/core/operations/workspace';
import {
  useCurrentUserStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { log } from '@pn/core/utils/debug';
import { muiColorPalette } from '@pn/services/color/colorPalettes';
import { isEmpty, isEqual, isNil } from 'lodash-es';

export function useFilterWorkspaceItem(tableOnly: boolean) {
  const { user } = useCurrentUserStorage();
  const { allWorkspaceItems } = useWorkspaceStorage();

  const visualizeWorkspaceItem = useVisualizeWorkspaceItem(tableOnly);

  const applyFilters = async (
    filters: Filter[],
    filtersLinkOperator: LinkOperator,
    item: WorkspaceItem
  ) => {
    const areAllFiltersEmpty = filters.every(
      (f) => isNil(f.value) || f.value === ''
    );

    const sourceItem = item.sourceItem ?? item;

    const temporaryItem = (() => {
      const existingTemporaryItem = allWorkspaceItems.find(
        (i) => i.isTemporary && i.id === item.id
      );

      if (!isNil(existingTemporaryItem)) {
        return {
          ...existingTemporaryItem,
          query: {
            ...existingTemporaryItem.query,
            filters,
            filtersLinkOperator,
          },
          isProcessed: true,
          isRendered: true,
        };
      }

      const temporaryFilterItems = allWorkspaceItems.filter(
        (item) => item.isTemporary && !isEmpty(item.query.filters)
      );
      const newItem = createWorkspaceItem(
        {
          source: 'item',
          sourceItem,
          temporarySourceItemId: item.id,
          extraStyle: {
            color: muiColorPalette.getFilterColor(
              temporaryFilterItems.map((item) => getItemColor(item) as string)
            ),
          },
          queryOptions: {
            requestedIds: item.query.requestedIds,
            multiSelectionReason: item.query.multiSelectionReason,
            filters,
            filtersLinkOperator,
          },
          isVisualized: true,
        },
        user
      );

      return newItem;
    })();

    if (areAllFiltersEmpty) {
      if (item.id === temporaryItem.id) {
        if (
          isEmpty(temporaryItem.query.requestedIds) ||
          (isEmpty(temporaryItem.query.filters) &&
            temporaryItem.query.multiSelectionReason ===
              DataMultiSelectionReason.List)
        ) {
          workspaceActions().remove(temporaryItem);
          workspaceActions().select(
            temporaryItem.temporarySourceItemId ?? sourceItem.id
          );
        } else {
          workspaceActions().update({
            ...temporaryItem,
            query: {
              ...temporaryItem.query,
              filters,
              filtersLinkOperator,
            },
            numberOfElements: temporaryItem.query.requestedIds.length,
            isRendered: false,
            isProcessed: false,
            /**
             * Will only clear the current requested id if all filters were
             * cleared. Otherwise, the undefined filters will not affect the
             * selection.
             */
            requestedDataItem: isEmpty(temporaryItem.query.filters)
              ? {
                  id: undefined,
                  reason: undefined,
                }
              : temporaryItem.requestedDataItem,
          });
        }
      } else if (!isEqual(filters, item.query.filters)) {
        /* Optimization: avoid triggering a new visualization if filters are the same */
        workspaceActions().updateFiltering(
          item.id,
          filters,
          filtersLinkOperator
        );
      }
    } else {
      if (
        !isEmpty(temporaryItem.query.requestedIds) &&
        (isNil(temporaryItem.query.multiSelectionReason) ||
          isSingleSelectionReason(temporaryItem.query.multiSelectionReason))
      ) {
        /**
         * Reset single selection when applying non-empty filters.
         * Multi-selection (box selection, list, etc.) will not be affected.
         */
        temporaryItem.query.requestedIds = [];
        workspaceActions().updateRequestedIds(sourceItem.id, {
          ids: [],
          reason: undefined,
        });
        workspaceActions().updatePage(sourceItem.id, 0);
      }

      temporaryItem.query.page = 0;

      workspaceActions().add(temporaryItem);
      workspaceActions().addToWorkspace(temporaryItem.id);

      log.info('visualize temporary filtered workspace item', temporaryItem);
      await visualizeWorkspaceItem(temporaryItem);

      workspaceActions().select(temporaryItem.id);
    }
  };

  return { applyFilters };
}
