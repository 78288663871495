import { Autocomplete, Box, TextField } from '@mui/material';
import { LayerType, type Layer } from '@pn/core/domain/layer';
import {
  getColorComponents,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { useTableFields } from '@pn/core/operations/dataTableInteractions';
import { workspaceActions } from '@pn/core/storage';
import { generateSliderMarks } from '@pn/ui/utils';
import { isArray, isNil, isNumber, isString, noop, uniq } from 'lodash-es';
import React from 'react';
import { SliderForm } from 'src/web-ui/workspace/components/SliderForm';
import { CombinedColorPicker } from 'src/web-ui/workspace/styling/CombinedColorPicker';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  paper: {
    position: 'relative',
    borderColor: theme.borderColor,
  },
  label: {
    position: 'absolute',
    left: 10,
    top: -10,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    fontSize: 12,
    paddingLeft: 4,
    paddingRight: 4,
  },
  disabledText: {
    color: theme.palette.text.disabled,
  },
  slider: {
    '& .MuiSlider-markLabel': {
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
  },
  count: {
    color: theme.palette.text.secondary,
  },
  legend: {
    paddingTop: theme.spacing(1),
  },
  legendColor: {
    flexShrink: 0,
    width: 16,
    height: 16,
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.grey[500], // should always be overridden
  },
  progressIndicator: {
    position: 'relative',
    top: -4,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
}));

type Props = {
  disabled: boolean;
  item: WorkspaceItem;
  layer: Layer;
};

export const StyleControls = ({ disabled, item, layer }: Props) => {
  const { classes } = useStyles();

  const style = layer.style;

  const colorComponents = React.useMemo(
    () => getColorComponents(style.color),
    [style.color]
  );

  const { fields: tableFields } = useTableFields(item.dataType);
  const labelField = isArray(style.field) ? style.field[1] : undefined;
  const fields = React.useMemo(
    () =>
      uniq([...tableFields, colorComponents.field, labelField]).filter(
        (v): v is string => !isNil(v)
      ),
    [tableFields, colorComponents.field, labelField]
  ); // FIXME duplicated in StyleByAttributeSelector

  const optionLabels = React.useMemo(() => {
    return fields.reduce<Record<string, string>>((acc, field) => {
      const mappingItem = item.mapping.find((m) => m.field === field);
      return { ...acc, [field]: mappingItem?.label ?? field };
    }, {});
  }, [fields, item.mapping]); // FIXME duplicated in StyleByAttributeSelector

  const handleUpdateStyleProperty = (property: string, value: unknown) => {
    workspaceActions().updateLayerStyle(item.id, layer.id, {
      [property]: value,
    });
  };

  return (
    <Box className={classes.container}>
      <CombinedColorPicker
        item={item}
        layer={layer}
        disabled={disabled}
        updateAll={false}
        showAlphaSlider={layer.type === LayerType.Polygon}
      />
      {isNumber(style.opacity) && (
        <SliderForm
          debounced
          title="Opacity"
          aria-labelledby="opacity-slider"
          getAriaValueText={(value) => value.toString()}
          className={classes.slider}
          size="small"
          step={0.1}
          min={0.0}
          max={1.0}
          marks={generateSliderMarks({
            step: 0.1,
            min: 0,
            max: 1,
            labels: [
              {
                value: 0,
                label: '0%',
              },
              {
                value: 0.5,
                label: '50%',
              },
              {
                value: 1,
                label: '100%',
              },
            ],
          })}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `${value * 100}%`}
          disabled={disabled}
          value={style.opacity}
          onChange={(_e, opacity) =>
            isNumber(opacity)
              ? handleUpdateStyleProperty('opacity', opacity)
              : noop
          }
        />
      )}
      {isNumber(style.size) && layer.type === LayerType.Text && (
        <SliderForm
          debounced
          title="Size"
          aria-labelledby="size-slider"
          getAriaValueText={(value) => value.toString()}
          className={classes.slider}
          size="small"
          step={2}
          min={8}
          max={36}
          valueLabelDisplay="auto"
          marks={generateSliderMarks({
            step: 2,
            min: 8,
            max: 36,
            // default: 14,
          })}
          disabled={disabled}
          value={style.size}
          onChange={(_e, size) =>
            isNumber(size) ? handleUpdateStyleProperty('size', size) : noop
          }
        />
      )}
      {isNumber(style.size) && layer.type !== LayerType.Text && (
        <SliderForm
          debounced
          title="Size"
          aria-labelledby="size-slider"
          getAriaValueText={(value) => value.toString()}
          className={classes.slider}
          size="small"
          step={4}
          min={8}
          max={64}
          valueLabelDisplay="auto"
          marks={generateSliderMarks({
            step: 4,
            min: 8,
            max: 64,
            // default: 16,
          })}
          disabled={disabled}
          value={style.size}
          onChange={(_e, size) =>
            isNumber(size) ? handleUpdateStyleProperty('size', size) : noop
          }
        />
      )}
      {isNumber(style.width) && (
        <SliderForm
          debounced
          title="Width"
          aria-labelledby="width-slider"
          getAriaValueText={(value) => value.toString()}
          className={classes.slider}
          size="small"
          step={0.5}
          min={0.5}
          max={7.5}
          valueLabelDisplay="auto"
          marks={generateSliderMarks({
            step: 0.5,
            min: 0.5,
            max: 7.5,
          })}
          disabled={disabled}
          value={style.width}
          onChange={(_e, width) =>
            isNumber(width) ? handleUpdateStyleProperty('width', width) : noop
          }
        />
      )}
      {isNumber(style.radius) && (
        <SliderForm
          debounced
          title="Radius"
          aria-labelledby="radius-slider"
          getAriaValueText={(value) => value.toString()}
          className={classes.slider}
          size="small"
          marks
          step={2}
          min={2}
          max={24}
          valueLabelDisplay="auto"
          disabled={disabled}
          value={style.radius}
          onChange={(_e, radius) =>
            isNumber(radius)
              ? handleUpdateStyleProperty('radius', radius)
              : noop
          }
        />
      )}
      {!isNil(labelField) && (
        <Autocomplete
          disableClearable
          disabled={disabled}
          value={labelField ?? null}
          options={fields}
          renderInput={(params) => <TextField {...params} label="Label by" />}
          getOptionLabel={(field) => optionLabels[field]}
          onChange={(_e, field) =>
            isString(field)
              ? handleUpdateStyleProperty('field', ['get', field])
              : noop
          }
        />
      )}
    </Box>
  );
};
