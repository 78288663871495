import CancelIcon from '@mui/icons-material/Cancel';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import PrintIcon from '@mui/icons-material/Print';
import { Box, Fab, FabProps, Tooltip } from '@mui/material';
import { useAccess } from '@pn/core/permissions/access';
import { isCordova } from '@pn/core/utils/env';
import { useAnnotations } from '@pn/ui/annotations/AnnotationProvider';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { CircleSelectIcon, PolygonSelectIcon } from '@pn/ui/icons';
import { useHotkeys } from 'react-hotkeys-hook';
import { map as pnMap } from 'src/application/externalDependencies';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
}));

export const MapButtons = () => {
  const { classes } = useStyles();
  const { smScreen } = useScreenSize();

  const access = useAccess();
  const isAccessDenied = access('annotations').denied();

  const {
    isAnnotationInterfaceOpen,
    drawMode,
    setDrawMode,
    openAnnotationInterface,
    closeAnnotationInterface,
  } = useAnnotations();

  const isPolygonToolActive =
    !isAnnotationInterfaceOpen && drawMode === 'draw_annotation_polygon';
  const isCircleToolActive =
    !isAnnotationInterfaceOpen && drawMode === 'draw_annotation_circle';

  useHotkeys(
    'esc',
    () => {
      if (isPolygonToolActive || isCircleToolActive) {
        setDrawMode('simple_select');
      }
    },
    [isPolygonToolActive, isCircleToolActive]
  );

  const handleOpenMapPrintBuilder = () => {
    const img = pnMap._native.getCanvas().toDataURL();
    fetch(img)
      .then((res) => res.blob())
      .then((blob) => {
        // NOTE this blob doesn't persist for very long
        const objectURL = URL.createObjectURL(blob);
        window.open(
          '/map-print-builder?' + new URLSearchParams({ objectURL }),
          '_blank'
        );
      });
  };

  const actions = [
    {
      tooltip: isAnnotationInterfaceOpen ? 'Exit' : 'Annotation Editor',
      color: isAnnotationInterfaceOpen ? 'secondary' : 'primary',
      disabled: isAccessDenied,
      ariaLabel: 'annotation editor',
      onClick: isAnnotationInterfaceOpen
        ? closeAnnotationInterface
        : openAnnotationInterface,
      icon: isAnnotationInterfaceOpen ? <CancelIcon /> : <ColorLensIcon />,
    },
    {
      tooltip: isPolygonToolActive ? 'Cancel' : 'Select (Polygon)',
      color: isPolygonToolActive ? 'secondary' : 'default',
      disabled: isAccessDenied || isAnnotationInterfaceOpen,
      ariaLabel: 'polygon selection',
      onClick: () =>
        isPolygonToolActive
          ? setDrawMode('simple_select')
          : setDrawMode('draw_annotation_polygon'),
      icon: isPolygonToolActive ? <CancelIcon /> : <PolygonSelectIcon />,
    },
    {
      tooltip: isCircleToolActive ? 'Cancel' : 'Select (Circle)',
      color: isCircleToolActive ? 'secondary' : 'default',
      disabled: isAccessDenied || isAnnotationInterfaceOpen,
      ariaLabel: 'circle selection',
      onClick: () =>
        isCircleToolActive
          ? setDrawMode('simple_select')
          : setDrawMode('draw_annotation_circle'),
      icon: isCircleToolActive ? <CancelIcon /> : <CircleSelectIcon />,
    },
  ];

  if (!isCordova()) {
    actions.push({
      tooltip: 'Print Map',
      color: 'default',
      disabled: access('printing').denied(),
      ariaLabel: 'print map',
      onClick: handleOpenMapPrintBuilder,
      icon: <PrintIcon />,
    });
  }

  if (smScreen) return null;

  return (
    <Box className={classes.container}>
      {actions.map((action) => (
        <Box mb={1} key={action.ariaLabel}>
          <Tooltip title={action.tooltip} placement="left">
            <Box component="span">
              <Fab
                size="small"
                color={action.color as FabProps['color']}
                disabled={action.disabled}
                aria-label={action.ariaLabel}
                onClick={action.onClick}
              >
                {action.icon}
              </Fab>
            </Box>
          </Tooltip>
        </Box>
      ))}
    </Box>
  );
};
