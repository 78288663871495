import { toGeoPoint } from '@pn/core/domain/geography';
import type { Point } from '@pn/core/domain/types';
import { useMapClicksProcessor } from '@pn/core/operations/mapInteractions';
import { useAccess } from '@pn/core/permissions/access';
import { isEmbedded } from '@pn/core/utils/embedded';
import { isCordova } from '@pn/core/utils/env';
import { getBboxAroundPoint } from '@pn/core/utils/geospatial';
import {
  toMapboxBbox,
  toMapboxPoint,
} from '@pn/services/map/mapbox/mapboxUtils';
import {
  isMapboxDataFeature,
  mapboxMapFeatureMapper,
} from '@pn/services/map/mapbox/mappers/mapboxMapFeatureMapper';
import { isAnnotationDrawMode } from '@pn/services/styles/annotation';
import { useAnnotations } from '@pn/ui/annotations/AnnotationProvider';
import { useDebouncedValue } from '@pn/ui/hooks/useDebouncedValue';
import { isEmpty } from 'lodash-es';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import { map } from 'src/application/externalDependencies';

type MapClickEvent = mapboxgl.MapMouseEvent & mapboxgl.EventData;

export function useMapClicks() {
  const { isAnnotationInterfaceOpen, drawMode } = useAnnotations();
  const processMapClick = useMapClicksProcessor();

  const access = useAccess();

  const areMapClicksIgnored =
    isAnnotationInterfaceOpen || isAnnotationDrawMode(drawMode); // disables clicks right away
  const [debouncedAreMapClicksIgnored] = useDebouncedValue(areMapClicksIgnored); // leaves them disabled for a while after drawing
  const shouldIgnore = areMapClicksIgnored || debouncedAreMapClicksIgnored;

  /**
   * We identify clicked features in this handler.
   * Processing happens in @pn/core.
   * In future, all Mapbox-related logic should be moved to
   * @pn/services/map.
   */
  const handleMapClick = React.useCallback(
    (e: MapClickEvent) => {
      if (shouldIgnore || e.originalEvent.shiftKey) return;
      if (
        e.originalEvent.ctrlKey &&
        (isEmbedded() || access('multiSelection').notify().denied())
      ) {
        return;
      }

      const clickPoint: Point = {
        x: e.point.x,
        y: e.point.y,
      };
      const clickGeoPoint = toGeoPoint(e.lngLat.lat, e.lngLat.lng);

      const queryRegionGeometry = isCordova()
        ? toMapboxBbox(getBboxAroundPoint(clickPoint, 5))
        : toMapboxPoint(clickPoint);

      const allFeatures =
        map._native.queryRenderedFeatures(queryRegionGeometry);

      const dataFeatures = allFeatures.filter(isMapboxDataFeature);

      if (isEmpty(dataFeatures)) return;

      processMapClick({
        features: dataFeatures.map(mapboxMapFeatureMapper.toDomainMapFeature),
        clickGeoPoint,
        append: e.originalEvent.ctrlKey,
      });
    },
    [processMapClick, access, shouldIgnore]
  );
  React.useEffect(() => {
    // console.log('binding map clicks');
    map._native.on('click', handleMapClick);

    return () => {
      // console.log('unbinding map clicks');
      map._native.off('click', handleMapClick);
    };
  }, [handleMapClick]);
}
