import type { GridSortModel } from '@mui/x-data-grid-pro';
import type { IQuerySortingMapper } from '@pn/core/mappers/query/ports';

export type IDataGridQuerySortingMapper = IQuerySortingMapper<GridSortModel>;

export const dataGridQuerySortingMapper: IDataGridQuerySortingMapper = {
  toDomainSorting: (sortModel) => {
    return sortModel.map((sortModel) => ({
      field: sortModel.field,
      direction: sortModel.sort ?? 'asc',
    }));
  },
  toTargetSorting: (sorts) => {
    return sorts.map((sort) => ({
      field: sort.field,
      sort: sort.direction,
    }));
  },
};
