import { Alert, Box } from '@mui/material';
import { formatDataType } from '@pn/core/domain/data';
import { useWorkspaceStorage } from '@pn/core/storage';
import { isNil } from 'lodash-es';
import { MainPanelHeader } from 'src/web-ui/main-panel/MainPanelHeader';
import { PortalPopout } from 'src/web-ui/main-panel/portals/PortalPopout';
import { usePortals } from 'src/web-ui/main-panel/portals/PortalsProvider';

// NOTE makeStyles() won't work with portals when a production bundle is built

export const Portals = () => {
  const { workspaceItemSelected, dataItemRequested } = useWorkspaceStorage();
  const { portals } = usePortals();

  const dataItemId = dataItemRequested.id;

  return portals.map((portal) => (
    <PortalPopout key={portal.id} portal={portal}>
      {portal.item.dataType !== workspaceItemSelected?.dataType ? (
        <Box p={2}>
          <Alert severity="warning">
            This window only supports {formatDataType(portal.item.dataType)}.
          </Alert>
        </Box>
      ) : isNil(dataItemId) ? (
        <Box p={2}>
          <Alert severity="warning">
            No {formatDataType(portal.item.dataType)} selected.
          </Alert>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" height="100%">
          <MainPanelHeader
            portal={portal}
            item={portal.item}
            route={portal.route}
          />
          <Box minWidth="initial" height="100%" padding={2} overflow="auto">
            {portal.route.getComponent({ item: portal.item })}
          </Box>
        </Box>
      )}
    </PortalPopout>
  ));
};
