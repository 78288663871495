import { dependencies } from '@pn/core/dependencies';
import { getPermissionLevel, UserPlans } from '@pn/core/domain/user';
import { useCurrentUserStorage } from '@pn/core/storage';
import { isNil } from 'lodash-es';
import React from 'react';
import { getPermissionByPath } from './permissions';

type Access = {
  notify(silent?: boolean): Omit<Access, 'notify'>;
  denied(): boolean;
  granted(): boolean;
  message: string;
};

export function useAccess() {
  const {
    useAuthenticationService,
    notificationService: { notify: displayNotification },
  } = dependencies;

  const { user } = useCurrentUserStorage();
  const { isAuthenticating, isAuthenticated } = useAuthenticationService();

  const isLoading = isAuthenticating || isNil(user);
  const userPlan = user?.userPlan;

  return React.useCallback(
    (path: string, exception?: unknown): Access => {
      const permission = getPermissionByPath(path);

      /* Handle exceptions */
      if (
        !isNil(permission.testException) &&
        permission.testException(exception)
      ) {
        const notify: Access['notify'] = () => {
          // no alerts needed here
          return {
            denied: () => false,
            granted: () => true,
            message: '',
          };
        };

        return {
          notify,
          ...notify(),
        };
      }

      /* Handle not authenticated users */
      if (permission.minimumPlan !== 'guest' && !isAuthenticated) {
        const message =
          permission.message ?? 'Please login to access this feature';

        const notify: Access['notify'] = (silent = false) => {
          if (!silent) displayNotification(message, 'warning');
          return {
            denied: () => true,
            granted: () => false,
            message,
          };
        };

        return {
          notify,
          ...notify(true),
        };
      }

      /* Handle authenticating/loading users */
      if (permission.minimumPlan !== 'guest' && isLoading) {
        const notify: Access['notify'] = (silent = false) => {
          if (!silent)
            displayNotification(
              'Please wait for authentication process to finish',
              'warning'
            );
          return {
            denied: () => true,
            granted: () => false,
            message: '',
          };
        };

        return {
          notify,
          ...notify(true),
        };
      }

      /* Handle insufficient permissions */
      if (isInsufficientPlan(userPlan, permission.minimumPlan)) {
        const message =
          permission.message ?? 'Your plan does not support this feature';

        const notify: Access['notify'] = (silent = false) => {
          if (!silent) displayNotification(message, 'warning');
          return {
            denied: () => true,
            granted: () => false,
            message,
          };
        };

        return {
          notify,
          ...notify(true),
        };
      }

      const notify: Access['notify'] = () => {
        // no alerts needed here
        return {
          denied: () => false,
          granted: () => true,
          message: '',
        };
      };

      return {
        notify,
        ...notify(),
      };
    },
    [isAuthenticated, isLoading, userPlan, displayNotification]
  );
}

export function isInsufficientPlan(
  userPlan: UserPlans | undefined,
  minimumPlan: UserPlans
): boolean {
  return getPermissionLevel(userPlan) < getPermissionLevel(minimumPlan);
}
