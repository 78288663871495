import { STREAMING_LIMIT } from '@pn/core/constants';

type IsExportDisabledProps = {
  isToolbarDisabled: boolean;
  totalCount: number;
};

export function checkIsExportDisabled({
  isToolbarDisabled,
  totalCount,
}: IsExportDisabledProps) {
  return isToolbarDisabled || totalCount === 0 || totalCount > STREAMING_LIMIT;
}
