import LockIcon from '@mui/icons-material/Lock';
import {
  Alert,
  Box,
  Collapse,
  ListSubheader,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { formatDataType } from '@pn/core/domain/data';
import type { SearchResult } from '@pn/core/domain/search';
import { handleSearchResult } from '@pn/core/operations/search';
import { useAccess } from '@pn/core/permissions/access';
import {
  useCurrentUserStorage,
  useSearchStorage,
} from '@pn/core/storage';
import { isCordova, isFreeCordova } from '@pn/core/utils/env';
import { groupBy, isEmpty, isNil, sortBy } from 'lodash-es';
import React from 'react';
import { useAuthenticationService } from 'src/application/externalDependencies';
import {
  getSearchbarWidth,
  searchbarParentId,
} from 'src/web-ui/layout/Searchbar';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ maxWidth: number }>()((theme, { maxWidth }) => ({
  paper: {
    width: '100%',
    minWidth: 255,
    maxWidth,
    backgroundImage: 'initial', // undo the pseudo-elevation in dark mode
  },
  listSubheader: {
    paddingTop: theme.spacing(1.5),
    lineHeight: '32px',
  },
  province: {
    display: 'inline-block',
    minWidth: 24,
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  lockIcon: {
    position: 'relative',
    top: 2,
    marginLeft: theme.spacing(0.5),
    fontSize: 16,
    color: theme.palette.text.disabled,
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  link: {
    color: theme.palette.info.main,
    fontWeight: 500,
    textDecoration: 'underline',
    '&:hover, &:focus, &:active': {
      color: theme.palette.info.dark,
    },
  },
}));

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const SearchResults = ({ isOpen, setIsOpen }: Props) => {
  const { cx, classes } = useStyles({
    maxWidth: getSearchbarWidth(),
  });

  const access = useAccess();
  const { isAuthenticated } = useAuthenticationService();

  const { user } = useCurrentUserStorage();
  const { searchResults } = useSearchStorage();
  const groupedSearchResults = groupBy(searchResults, 'dataType');

  const showAccessAlert = Object.keys(groupedSearchResults)
    .filter((dataType) => dataType !== 'undefined')
    .some((dataType) => access('allData', dataType).denied());

  const anchorEl = window.document.getElementById(searchbarParentId);

  const handleClick = (searchResult: SearchResult) => {
    setIsOpen(false);
    handleSearchResult(searchResult);

    /* Hide keyboard on iOS */
    if (isCordova() && window.Keyboard) {
      setTimeout(
        () => window.document.getElementById('search-input')?.blur(),
        0
      );
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      classes={{
        paper: classes.paper,
      }}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      TransitionComponent={Collapse}
    >
      {isEmpty(groupedSearchResults) ? (
        <Box textAlign="center" px={2} py={1}>
          <Typography color="textSecondary">No results found</Typography>
        </Box>
      ) : (
        Object.keys(groupedSearchResults)
          .filter((dataType) =>
            isFreeCordova(user)
              ? ['undefined', 'wells'].includes(dataType)
              : true
          )
          .map((dataType) => {
            const disabled =
              dataType !== 'undefined'
                ? access('allData', dataType).denied()
                : false;

            return [
              <ListSubheader
                key={dataType}
                className={cx(classes.listSubheader, {
                  [classes.disabled]: disabled,
                })}
              >
                {dataType === 'undefined'
                  ? 'Locations'
                  : formatDataType(dataType, { case: 'sentence' })}
                {disabled && <LockIcon className={classes.lockIcon} />}
              </ListSubheader>,
              sortBy(groupedSearchResults[dataType], 'province').map(
                (searchResult, index) => {
                  const { province, label } =
                    getSearchResultLabel(searchResult);

                  return (
                    <MenuItem
                      key={dataType + index}
                      disabled={disabled}
                      onClick={() => handleClick(searchResult)}
                    >
                      {!isNil(province) && (
                        <span className={classes.province}>{province}</span>
                      )}
                      <span>{label}</span>
                    </MenuItem>
                  );
                }
              ),
            ];
          })
      )}
      {showAccessAlert && !isCordova() && (
        <Box px={2} py={1}>
          <Alert severity="warning">
            Please{' '}
            <a
              href="https://about.petroninja.com/plans"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              {isAuthenticated ? 'upgrade' : 'sign up'}
            </a>{' '}
            {isAuthenticated ? 'to' : 'for'} a Professional or Enterprise
            account for full access.
          </Alert>
        </Box>
      )}
    </Menu>
  );
};

function getSearchResultLabel(searchResult: SearchResult) {
  if (searchResult.resultType === 'location') {
    return { province: undefined, label: searchResult.label };
  } else {
    return { province: searchResult.province, label: searchResult.label };
  }
}
