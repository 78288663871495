import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  alpha,
  Box,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { formatDataType } from '@pn/core/domain/data';
import {
  getFormattedItemName,
  getItemColor,
  WorkspaceItem,
} from '@pn/core/domain/workspace';
import {
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { generateIconBackground } from '@pn/services/utils/color';
import { useAnnotations } from '@pn/ui/annotations/AnnotationProvider';
import { useScreenSize } from '@pn/ui/hooks/useScreenSize';
import { ListSecondaryTextBlock } from '@pn/ui/workspace/components/ListSecondaryTextBlock';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelStateProvider';
import { isNil } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{
  isVisible: boolean;
  isDragging: boolean;
  background: string;
}>()((theme, { isVisible, isDragging, background }) => ({
  listItem: {
    opacity: isVisible ? 1 : 0.5,
    '& .MuiListItemButton-root': {
      paddingRight: theme.spacing(6),
    },
    '& .MuiListItemSecondaryAction-root': {
      right: theme.spacing(1.5),
    },
    '&:hover': {
      '& .MuiListItemButton-root': {
        paddingRight: theme.spacing(10),
      },
      '& .secondaryActionIconButton': {
        display: 'inline-flex',
      },
      '& .colorIconContainer': {
        display: 'none',
      },
    },
  },
  listItemButton: {
    height: 60,
    paddingLeft: theme.spacing(1.5),
  },
  listItemIcon: {
    minWidth: parseInt(theme.spacing(1.5)) + 28,
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  secondaryActionIconButton: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'inline-flex',
    },
  },
  iconButtonLoading: {
    position: 'relative',
    left: 4,
  },
  colorIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 30, // lines up with the secondary action buttons
  },
  colorIndicator: {
    flexShrink: 0,
    height: 16,
    width: 16,
    background,
    borderRadius: theme.shape.borderRadius,
  },
  dragHandle: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 40,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: isDragging ? theme.palette.action.hover : undefined,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

type Props = {
  item: WorkspaceItem;
  isDragging?: boolean;
  isSorting?: boolean;
  style?: React.CSSProperties;
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
  activatorRef?: React.Ref<HTMLElement>;
};

export const WorkspaceItemComponent = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      item,
      style,
      attributes,
      listeners,
      activatorRef,
      isDragging = false,
      isSorting = false,
    },
    ref
  ) => {
    const { smScreen } = useScreenSize();

    const { idsInWorkspace, workspaceItemSelected } = useWorkspaceStorage();

    const { openWorkspaceItemPanel } = useWorkspaceItemPanel();
    const { openAnnotationInterface } = useAnnotations();

    const isLoading =
      idsInWorkspace.includes(item.id) && item.isVisible && !item.isRendered;
    const isSelected = item.id === workspaceItemSelected?.id;

    const { primaryText, secondaryText, tertiaryText } = getItemInfo(item);

    const color = getItemColor(item);
    const { classes, cx, theme } = useStyles({
      isVisible: item.isVisible,
      isDragging,
      background: generateIconBackground(color, item.isTemporary),
    });

    return (
      <div style={style} ref={ref}>
        <ListItem
          dense
          disablePadding
          className={classes.listItem}
          secondaryAction={
            isLoading ? (
              <IconButton disabled className={classes.iconButtonLoading}>
                <CircularProgress size={20} />
              </IconButton>
            ) : (
              <Box className={classes.actionsContainer}>
                {isNil(item.module) && !smScreen && (
                  <IconButton
                    size="small"
                    className={cx(
                      classes.secondaryActionIconButton,
                      'secondaryActionIconButton'
                    )}
                    onClick={() =>
                      workspaceActions().updateVisibility(
                        item.id,
                        !item.isVisible
                      )
                    }
                  >
                    {item.isVisible ? (
                      <VisibilityIcon fontSize="small" />
                    ) : (
                      <VisibilityOffIcon fontSize="small" />
                    )}
                  </IconButton>
                )}
                <IconButton
                  size="small"
                  className={
                    item.isTemporary
                      ? undefined
                      : cx(
                          classes.secondaryActionIconButton,
                          'secondaryActionIconButton'
                        )
                  }
                  onClick={() =>
                    openWorkspaceItemPanel({ item, openAnnotationInterface })
                  }
                >
                  {item.isTemporary ? (
                    <SaveIcon fontSize="small" />
                  ) : (
                    <SettingsIcon fontSize="small" />
                  )}
                </IconButton>
                {!item.isTemporary && !smScreen && !isSorting && (
                  <Box
                    className={cx(
                      classes.colorIconContainer,
                      'colorIconContainer'
                    )}
                  >
                    <Box className={classes.colorIndicator} />
                  </Box>
                )}
              </Box>
            )
          }
        >
          <ListItemButton
            disableTouchRipple={smScreen}
            className={classes.listItemButton}
            selected={!smScreen ? isSelected : undefined} // no selection on small screens
            onClick={() => workspaceActions().select(item.id)}
          >
            <ListItemIcon
              className={classes.listItemIcon}
              onMouseDown={(event) => event.stopPropagation()}
            >
              {isNil(item.module) && item.isVisible ? (
                <Box
                  {...attributes}
                  {...listeners}
                  ref={activatorRef}
                  className={classes.dragHandle}
                  style={{
                    cursor: isDragging ? 'grabbing' : 'grab',
                  }}
                >
                  <DragIndicatorIcon fontSize="small" color="action" />
                </Box>
              ) : (
                <Box
                  className={classes.dragHandle}
                  style={{ cursor: 'not-allowed', pointerEvents: 'none' }}
                >
                  <DragIndicatorIcon fontSize="small" color="disabled" />
                </Box>
              )}
            </ListItemIcon>

            <ListItemText
              primary={primaryText}
              secondary={
                <ListSecondaryTextBlock
                  secondaryText={secondaryText}
                  tertiaryText={tertiaryText}
                />
              }
              primaryTypographyProps={{
                color: item.isTemporary ? 'primary' : 'textPrimary',
                noWrap: true,
              }}
              secondaryTypographyProps={{
                color: item.isTemporary
                  ? alpha(theme.palette.primary.main, 0.65)
                  : 'textSecondary',
                noWrap: true,
              }}
            />
          </ListItemButton>
        </ListItem>
      </div>
    );
  }
);

WorkspaceItemComponent.displayName = 'WorkspaceItemComponent';

function getItemInfo(item: WorkspaceItem): {
  primaryText: string;
  secondaryText?: string;
  tertiaryText?: string;
} {
  const shared = {
    primaryText: getFormattedItemName(item),
  };

  switch (item.itemType) {
    case 'layer':
      return {
        ...shared,
        secondaryText: !isNil(item.numberOfElements)
          ? `${item.numberOfElements.toLocaleString()} ${formatDataType(
              item.dataType,
              { form: item.numberOfElements === 1 ? 'singular' : 'plural' }
            )}`
          : undefined,
        tertiaryText: !isNil(item.description) ? item.description : undefined,
      };
    case 'list':
      return {
        ...shared,
        secondaryText: `${item.numberOfElements?.toLocaleString()} ${formatDataType(
          item.dataType,
          { form: item.numberOfElements === 1 ? 'singular' : 'plural' }
        )}${item.isTemporary ? ' (unsaved)' : ''}`,
        tertiaryText: undefined,
      };
    case 'annotation':
      return {
        ...shared,
        secondaryText: undefined,
        tertiaryText: undefined,
      };
    default:
      throw new Error(
        `Unsupported workspace item type: [${(item as any)?.id}]`
      );
  }
}
