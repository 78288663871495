import { ClassNameMap, colors } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { LinkedIdsTable } from '@pn/core/domain/data-info';
import { getBoundingBox, toGeoPoint } from '@pn/core/domain/geography';
import { DataMultiSelectionReason } from '@pn/core/domain/query';
import { createWorkspaceItem } from '@pn/core/domain/workspace';
import {
  useCurrentUserStorage,
  workspaceActions,
} from '@pn/core/storage';
import { pnWorkspaceItems } from '@pn/core/storage/workspace/pnWorkspaceItems';
import { findOrThrow } from '@pn/core/utils/logic';
import assert from 'assert';
import { isEmpty } from 'lodash-es';
import { map } from 'src/application/externalDependencies';
import { makeStyles } from 'tss-react/mui';
import { TableComponent } from './MultiColumnTable';

const useStyles = makeStyles()(() => ({
  divider: {
    marginBottom: 2,
  },
}));

type Props = {
  classes: ClassNameMap;
  table: LinkedIdsTable;
};

export function LinkedIdsTableComponent({ classes, table }: Props) {
  const { title, linkedIds, linkedIdsType, linkedCoordinates } = table;
  const { classes: extraClasses } = useStyles();

  const { user } = useCurrentUserStorage();

  const isPaginationEnabled = linkedIds.length > 10;

  const handleViewOnMap = () => {
    if (linkedIdsType && linkedIds.length > 0) {
      const sourceItem = findOrThrow(
        pnWorkspaceItems,
        ({ id }) => id === linkedIdsType
      );

      assert(user, 'User must be defined when processing linked IDs');

      const newItem = createWorkspaceItem(
        {
          source: 'item',
          sourceItem,
          id: `linked-ids-${linkedIdsType}`,
          extraStyle: {
            color: colors.pink[500],
          },
          queryOptions: {
            requestedIds: linkedIds,
            multiSelectionReason: DataMultiSelectionReason.LinkedIds,
          },
        },
        user
      );

      workspaceActions().add(newItem);
      workspaceActions().addToWorkspace(newItem.id);
      workspaceActions().select(newItem.id);
      workspaceActions().unsetRequestedDataItemId(newItem.id);
    }

    if (!isEmpty(linkedCoordinates)) {
      const bbox = getBoundingBox(
        linkedCoordinates.map(([lon, lat]) => toGeoPoint(lat, lon))
      );
      // FIXME this stupid thing doesn't work for some reason
      map.fitToBoundingBox(bbox, {
        padding: '20%',
        maxZoom: 13,
      });
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.tableTitle}>{title}</Typography>
      </Box>
      <Divider className={extraClasses.divider} />
      <TableComponent classes={classes} table={table} />
      <Box mt={isPaginationEnabled ? -1 : 1} textAlign="center">
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleViewOnMap}
        >
          View
        </Button>
      </Box>
    </Box>
  );
}
