import { isEmpty, set } from 'lodash-es';
import type { MapboxIMap } from '..';

const isInDebugMode = import.meta.env.VITE_APP_HOST_ENV === 'development';

export const filterLayer: MapboxIMap['filterLayer'] = function (
  this: MapboxIMap,
  { layerId, key, filter }
) {
  if (!this.hasLayer(layerId)) return;

  set(this.filters, `${layerId}.${key}`, filter);

  const allLayerFilters = Object.values(this.filters[layerId]).filter(Boolean);

  if (isEmpty(allLayerFilters)) {
    this._native.setFilter(layerId, undefined);
  } else if (allLayerFilters.length === 1) {
    this._native.setFilter(layerId, allLayerFilters[0], {
      validate: isInDebugMode,
    });
  } else {
    this._native.setFilter(layerId, ['all', ...allLayerFilters], {
      validate: isInDebugMode,
    });
  }
};
