import { DragOverlay, useDndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { Box } from '@mui/material';
import { useWorkspaceStorage } from '@pn/core/storage';
import { findOrThrow } from '@pn/core/utils/logic';
import { isNil } from 'lodash-es';
import { SortableWorkspaceItem } from 'src/web-ui/workspace/SortableWorkspaceItem';
import { WorkspaceItemComponent } from 'src/web-ui/workspace/WorkspaceItem';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  content: {
    gridArea: 'workspace-content',
    overflowY: 'auto',
  },
}));

type Props = {
  internalIds: string[];
};

export const WorkspaceContent = ({ internalIds }: Props) => {
  const { classes } = useStyles();
  const { active } = useDndContext();

  const { workspaceItems } = useWorkspaceStorage();

  return (
    <Box className={classes.content}>
      <SortableContext items={internalIds}>
        {internalIds
          .map((id) => {
            const item = workspaceItems.find((item) => item.id === id);
            if (isNil(item)) {
              return undefined;
            } else {
              return <SortableWorkspaceItem key={id} id={id} item={item} />;
            }
          })
          .filter(Boolean)}
      </SortableContext>
      <DragOverlay dropAnimation={{ duration: 200, easing: 'ease' }}>
        {!isNil(active?.id) ? (
          <WorkspaceItemComponent
            item={findOrThrow(workspaceItems, (item) => item.id === active?.id)}
            isDragging
          />
        ) : null}
      </DragOverlay>
      {/* <AnnotationControls isDragging={!isNil(active?.id)} /> */}
    </Box>
  );
};
