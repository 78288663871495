import { useAnnotations } from '@pn/ui/annotations/AnnotationProvider';
import { AnnotationPropertiesPanel } from 'src/web-ui/annotation/AnnotationPropertiesPanel';
import { AnnotationToolbar } from 'src/web-ui/annotation/AnnotationToolbar';

export const AnnotationEditor = () => {
  const { isAnnotationInterfaceOpen } = useAnnotations();

  if (!isAnnotationInterfaceOpen) return null;

  return (
    <>
      <AnnotationToolbar />
      <AnnotationPropertiesPanel />
    </>
  );
};
