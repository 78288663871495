import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import LinkIcon from '@mui/icons-material/Link';
import LockIcon from '@mui/icons-material/Lock';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import SourceIcon from '@mui/icons-material/Source';
import {
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
} from '@mui/material';
import { getFullName } from '@pn/core/domain/user';
import { WorkspaceItem } from '@pn/core/domain/workspace';
import { useQuickProjectActions } from '@pn/core/operations/project';
import { useCurrentUserStorage } from '@pn/core/storage';
import { NotificationTooltip } from '@pn/ui/custom-components/NotificationTooltip';
import { useCopyToClipboard } from '@pn/ui/hooks/useCopyToClipboard';
import { useLibrary } from '@pn/ui/workspace/LibraryStateProvider';
import { format, parseISO } from 'date-fns';
import { isNil } from 'lodash-es';
import { ConvertToStackButton } from 'src/web-ui/workspace/components/ConvertToStackButton';
import { makeStyles } from 'tss-react/mui';

const syncableDataTypes = ['wells', 'pipelines', 'facilities'];

const useStyles = makeStyles()((theme) => ({
  listItem: {
    height: 52,
  },
  iconButton: {
    padding: theme.spacing(1.25),
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  sourceItemName: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  openOriginUrlButton: {
    position: 'relative',
    top: -1,
    marginLeft: theme.spacing(1),
  },
  link: {
    cursor: 'pointer',
  },
  textDisabled: {
    color: theme.palette.text.disabled,
  },
}));

type Props = {
  item: WorkspaceItem;
};

export const ItemInformation = ({ item }: Props) => {
  const { classes } = useStyles();

  const { user, isStackUser } = useCurrentUserStorage();
  const { showSharingToggle, isItemShared, toggleShared } =
    useQuickProjectActions();

  const { openLibrary, setLibraryRouter } = useLibrary();

  const isCreatedByCurrentUser =
    !isNil(item.createdBy) && !isNil(user) && item.createdBy.id === user.id;
  const isSyncable =
    isStackUser &&
    isCreatedByCurrentUser &&
    !item.isGlobal &&
    item.origin !== 'stackdx' &&
    (syncableDataTypes.includes(item.dataType) ||
      item.sourceItem?.origin === 'stackdx');
  const isStackList = item.origin === 'stackdx' && !isNil(item.sourceItem);
  const itemUrl = isNil(item.sourceItem)
    ? import.meta.env.VITE_APP_BASE_URL + '/' + encodeURIComponent(item.id)
    : import.meta.env.VITE_APP_BASE_URL +
      '/lists/' +
      encodeURIComponent(item.id);

  const { copyText } = useCopyToClipboard();

  const handleOpenPersonalTab = () => {
    setLibraryRouter(['Personal']);
    openLibrary();
  };

  if (item.isTemporary) {
    return (
      <List dense>
        <ListItem disableGutters disablePadding className={classes.listItem}>
          <ListItemIcon>
            <HistoryToggleOffIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary="Work in progress"
            secondary={
              <span>
                Once saved, this layer will be in the{' '}
                <Link className={classes.link} onClick={handleOpenPersonalTab}>
                  Personal
                </Link>{' '}
                tab
              </span>
            }
          />
        </ListItem>
      </List>
    );
  }

  return (
    <List dense>
      <ListItem disableGutters disablePadding className={classes.listItem}>
        <ListItemIcon>
          {item.isGlobal ? (
            <PublicIcon className={classes.icon} />
          ) : isCreatedByCurrentUser ? (
            <PersonIcon className={classes.icon} />
          ) : (
            <LockIcon className={classes.icon} />
          )}
        </ListItemIcon>
        <ListItemText
          primary={getInfoText(item, isCreatedByCurrentUser).primary}
          secondary={getInfoText(item, isCreatedByCurrentUser).secondary}
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
        />
      </ListItem>

      {!isNil(item.sourceItem) && (
        <ListItem disableGutters disablePadding className={classes.listItem}>
          <ListItemIcon>
            <LinkIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary={
              <span>
                Sourced from{' '}
                <span className={classes.sourceItemName}>
                  {item.sourceItem.name}
                </span>
              </span>
            }
            secondary="The data will always be in sync"
            primaryTypographyProps={{ noWrap: true }}
            secondaryTypographyProps={{ noWrap: true }}
          />
        </ListItem>
      )}

      {isStackList && item.metadata?.dataOriginDescription && (
        <ListItem disableGutters disablePadding className={classes.listItem}>
          <ListItemIcon>
            <SourceIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary={
              <span>
                Origin:{' '}
                <span className={classes.sourceItemName}>
                  {item.metadata.dataOriginDescription}
                </span>
                {!isNil(item.originUrl) && (
                  <IconButton
                    size="small"
                    color="info"
                    className={classes.openOriginUrlButton}
                    component="a"
                    href={item.originUrl}
                    target="_blank"
                  >
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                )}
              </span>
            }
            primaryTypographyProps={{ noWrap: true }}
          />
        </ListItem>
      )}

      {showSharingToggle &&
        isCreatedByCurrentUser &&
        !item.isGlobal &&
        item.origin !== 'stackdx' && (
          <ListItem disableGutters disablePadding className={classes.listItem}>
            <ListItemIcon>
              <Switch
                size="small"
                color="info"
                disabled={!isCreatedByCurrentUser}
                checked={isItemShared(item)}
                onChange={(event) => toggleShared(item, event.target.checked)}
              />
            </ListItemIcon>
            <ListItemText
              primary="Share with my company"
              primaryTypographyProps={
                isCreatedByCurrentUser
                  ? {}
                  : {
                      className: classes.textDisabled,
                    }
              }
            />
          </ListItem>
        )}

      {isNil(item.module) && !isStackList && (
        <ListItem disableGutters disablePadding className={classes.listItem}>
          <ListItemIcon>
            <NotificationTooltip
              title="Copied layer URL to clipboard"
              placement="left"
            >
              <IconButton
                aria-label="copy layer URL"
                color="info"
                className={classes.iconButton}
                onClick={() => copyText(itemUrl)}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </NotificationTooltip>
          </ListItemIcon>
          <ListItemText
            primary={itemUrl}
            primaryTypographyProps={{ noWrap: true }}
          />
        </ListItem>
      )}

      {isSyncable && (
        <ConvertToStackButton
          disabled={!isCreatedByCurrentUser}
          workspaceItem={item}
        />
      )}
    </List>
  );
};

function getInfoText(
  item: WorkspaceItem,
  isCreatedByCurrentUser: boolean
): {
  primary: string;
  secondary: string;
} {
  let primary = '';
  let secondary = '';

  if (item.origin === 'stackdx') {
    primary = `Last modified on ${format(
      parseISO(item.updatedAt),
      `MMM dd, yyyy 'at' h:mm aa`
    )}`;
    secondary = 'This layer can only be edited through StackDX';
  } else if (isCreatedByCurrentUser) {
    primary = `Last modified on ${format(
      parseISO(item.updatedAt),
      `MMM dd, yyyy 'at' h:mm aa`
    )}`;
    secondary = 'This layer can only be edited by you';
  } else if (!isNil(item.createdBy)) {
    primary = `Last modified by ${getFullName(item.createdBy)} on ${format(
      parseISO(item.updatedAt),
      `MMM dd, yyyy 'at' h:mm aa`
    )}`;
    secondary = 'This layer can only be edited by its author';
  } else {
    primary = 'Created by Petro Ninja';
    secondary = 'This layer cannot be modified';
  }

  return { primary, secondary };
}
